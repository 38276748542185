import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { throwError } from "rxjs";
import {
	DataArtifact,
	DataTable,
	ExceptionInfo,
	TableStats,
	WorkflowLogEntry,
	WorkflowLogEntryGui,
	WorkflowNodeGuiInfo,
	WorkflowResult,
	WorkflowStatusLogEntry,
} from "src/app/models/designer.models";
import { NodePlugInInfos } from "src/app/models/nodePlugIn.model";
import { IWorkflowNode } from "src/app/models/workflow.model";
import { DatasourcesService } from "src/app/services/datasources.service";
import {
	DesignerEvent,
	DesignerService,
	DesignProgresSpinnerEvent,
	WorkflowOperationType,
} from "src/app/services/designer.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";
import { ToolbarCommand } from "../designer-nav-toolbar/designer-nav-toolbar.component";
import {
	DataPreviewEventObject,
	DataViewPortChangeEvent,
	PreviewEvents,
} from "../node-data-preview/node-data-preview.component";
import {
	mxWorkflowNodeValue,
	mxWorkflowPortValue,
} from "../workflow-graph/mxWorkflowGraph";
import {
	WorkflowGraphEventType,
	IWorkflowGraphEventData,
	NodeCellClickedData,
	UpdateSettingsExecutedData,
	WorkflowExecutedData,
	PortCellClickedData,

} from "../workflow-graph/workflow-graph-events";

/**
 * Adapter Class to bridge the event data with the config view
 */
export class WorkflowNodeAdapter {
	readonly Id: string;
	readonly IWorkflowNode: IWorkflowNode;

	constructor(id: string, iWorkflowNode: IWorkflowNode) {
		this.Id = id;
		this.IWorkflowNode = iWorkflowNode;
	}
}

@Component({
	selector: "app-node-config",
	templateUrl: "./node-config.component.html",
	styleUrls: ["./node-config.component.scss"],
})
export class NodeConfigComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	displayAddEntry: boolean = false;
	displayConfig: boolean = true;
	savingInProgress: boolean = false;
	// currentWorkflowNode: IWorkflowNode;
	// currentWorkflowNodeID: string;
	currentEngineName?: string;
	currentWorkflowNodeInfo?: WorkflowNodeAdapter;

	workflowResult: WorkflowResult;

	workflowStatesLog: WorkflowStatusLogEntry[] = [];
	// --- Log Table
	workflowErrorLog: WorkflowLogEntryGui[] = [];

	errorLogResult: Array<[string, ExceptionInfo]>;

	selectedNodePort: mxWorkflowPortValue;
	currentTableStatsCols: any[] = [];
	currentTableStatsRecords: any[] = [];
	currentTableStatsCount: number = 0;

	currentColumnStatsCols: any[] = [];
	currentColumnStatsRecords: any[] = [];

	artifacts: DataArtifact[] = [];

	@Output() isDirtyFlag = new EventEmitter<boolean>();

	// Inputs
	readonly EXCEL_INPUT_NODE = "Bion.DataWarehouse.PsaInput";
	readonly DATASTORE_INPUT_NODE = "Bion.BE.DataStoreInput";

	// Outputs
	readonly OUTPUT_PREVIEW_NODE = "Bion.Pipeline.Preview.PlugIn";
	readonly OUTPUT_DATASTORE_NODE = "Bion.DataWarehouse.DataStoreOutput";
	readonly FILEDOWNLOADER_NODE = "Bion.BE.ExportFileOutput";
	readonly DESTINATION_NODE = "Bion.BE.DestinationOutput";

	// Transformations
	readonly SELECT_NODE = "Bion.Transformation.Select";
	readonly SELECT_NODE_SPARK = "Bion.Spark.Select";

	readonly TRANSPOSE_NODE = "Bion.Transformation.Transpose";
	readonly TRANSPOSE_NODE_SPARK = "Bion.Spark.Transpose";
	readonly PIVOT_NODE = "Bion.BE.Pivot";
	readonly FILTER_NODE = "Bion.Transformation.Filter";
	readonly FILTER_NODE_SPARK = "Bion.Spark.Filter";
	readonly SORTING_NODE = "Bion.Transformation.Sort";
	readonly SORTING_NODE_SPARK = "Bion.Spark.Sort";
	readonly FINDANDREPLACE_NODE = "Bion.Transformation.FindAndReplace";
	readonly CONSTANTVALUE_NODE = "Bion.BE.ConstantValue";
	readonly REGEX_NODE = "Bion.BE.RegEx";
	readonly ANONYMIZE_NODE = "Bion.BE.Anonymize";
	readonly AUTO_SELECT_NODE = "Bion.BE.AutoSelect";
	readonly AUTO_SELECT_NODE_SPARK = "Bion.Spark.AutoSelect";

	readonly CONSTANT_VALUE_SPARK = "Bion.Spark.ConstantValue";
	readonly FIND_AND_REPLACE_SPARK = "Bion.Spark.FindReplace";
	readonly SPLIT_COLUMN_SPARK = "Bion.Spark.SplitColumn";

	readonly ENCRYPT_NODE = "Bion.BE.Encryption";
	readonly CHANGEDATATYPE_NODE = "Bion.BE.ChangeDatatype";

	readonly REMOVE_DUPLICATES_NODE = "Bion.BE.RemoveDuplicates";
	readonly CONVERT_CASE_NODE = "Bion.BE.ConvertCase";
	readonly LIMIT_ROWS_NODE = "Bion.BE.LimitRows";


	// Calculations
	readonly CALCULATE_NODE = "Bion.BE.Calculate";
	readonly PRESENTVALUE_NODE = "Bion.BE.PresentValue";
	readonly FORMULA_NODE = "Bion.BE.Formula";

	// Enrichment
	readonly FXCONVERT_NODE = "Bion.BE.FxConversion";
	readonly UNITCONVERT_NODE = "Bion.BE.UnitConversion";
	readonly SAMPLING_NODE = "Bion.BE.Sampling";
	readonly COMPARE_NODE = "Bion.BE.Compare";

	// Aggregation & Splits
	readonly UNION_NODE = "Bion.Transformation.Union";
	readonly JOIN_NODE = "Bion.Transformation.Join";
	readonly JOIN_NODE_SPARK = "Bion.Spark.Join";
	readonly SUMMARIZE_NODE = "Bion.BE.Summarize";
	readonly UNIQUEVALUES_NODE = "Bion.BE.UniqueValueSplitter";
	readonly TABLESPLITTER_NODE = "Bion.BE.TableSplitter";
	readonly COLUMNSPLITTER_NODE = "Bion.BE.ColumnSplitter";
	readonly COLUMNAGGREGATOR_NODE = "Bion.BE.ColumnAggregator";

	// Validation
	readonly MISSINGVALUE_NODE = "Bion.BE.MissingValues";
	readonly DATEPARSER_NODE = "Bion.BE.DateTime";
	readonly EMAIL_VALIDATION_NODE = "Bion.BE.EmailValidation";
	readonly GENDERCHECK_NODE = "Bion.BE.GenderCheck";
	readonly TRIM_NODE = "Bion.BE.Trim";



	constructor(
		private designerService: DesignerService,
		private errorService: SystemMessageLogService,
		private workflowService: WorkflowsService,
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}


	// forceViewToSettings() {
		
	// }


	ngOnInit(): void {
		// this.currentColumnStatsCols = [
		// 	{ field: "Column Name", header: "Column", width: "50%" },
		// 	{ field: "count", header: "Count", width: "10%" },
		// 	{ field: "mean", header: "Mean", width: "10%" },
		// 	{ field: "median", header: "Median", width: "10%" },
		// 	{ field: "std", header: "Std", width: "10%" },
		// 	{ field: "min", header: "Min", width: "10%" },
		// 	{ field: "max", header: "Max", width: "10%" },
		// 	{ field: "skew", header: "Skew", width: "10%" },
		// ];
		// this.currentTableStatsCols = [
		// 	{ field: "Column Name", header: "Column", width: "40%" },
		// 	{ field: "Valid", header: "Valid values", width: "20%" },
		// 	{ field: "Missing", header: "Missing values", width: "20%" },
		// 	{ field: "Unique", header: "Unique values", width: "20%" },
		// ];
		this.subs.sink = this.designerService.displayConfigEmitter.subscribe(
			(res) => {
				this.displayConfig = res;
			}
		);
		this.subs.sink = this.designerService.previewEventsEmitter.subscribe(
			(res: DataPreviewEventObject) => {
				if (res.Type === PreviewEvents.PortSelected) {

					try {
						let resData = <DataViewPortChangeEvent>res.Data;
						let resNode = resData.Node;
						let nodePort = resData.Port;
						let nodeTableStats = resNode.Data.get(nodePort)[0].TableStats;

						//this.createTableStats(nodeTableStats);

					} catch (e) {
						throwError(e);
					}
				}
			}
		);
		// this.subs.sink =
		// 	this.designerService.designerProgressSpinnerEmitter.subscribe(
		// 		(res: boolean) => {
		// 			this.savingInProgress = res;
		// 		}
		// 	);
		this.subs.sink = this.designerService.workflowGraphEmitter.subscribe(
			(
				event: DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>
			) => {
				if (event.Type === WorkflowGraphEventType.NodeCellClicked) {
					const data = <NodeCellClickedData>event.Data;
					this.savingInProgress = true;


					this.currentWorkflowNodeInfo = new WorkflowNodeAdapter(
						data.Cell.id,
						data.Value
					);

					this.currentEngineName = data.Value.Engine.Name;
					this.displayConfig = true;

					this.selectNodeGuiInfo();

					// -- if at least one port exists, select the first one
					if (data.Value.PortInfos.length > 0) {
						this.selectedNodePort = data.Value.PortInfos[0];
					}
					if (!(data.Value instanceof mxWorkflowNodeValue)) return;

					let nodeClicked = <mxWorkflowNodeValue>data.Value;

					let nodeClickedData = nodeClicked.Data;

					if (nodeClickedData === undefined) return;

					let selectedPortData = nodeClickedData.get(
						this.selectedNodePort.Name
					);

					this.savingInProgress = false;

					if (selectedPortData === undefined) return;
					if (selectedPortData[0] === undefined) return;

					let selectedPortDataStats = selectedPortData[0].TableStats;

					console.log(this.currentWorkflowNodeInfo);
				}
				if (event.Type === WorkflowGraphEventType.UpdateSettingsExecuted) {
					this.savingInProgress = true;

					const data = <UpdateSettingsExecutedData>event.Data;

					if (this.currentWorkflowNodeInfo === undefined) return; // No node selected, e.g. edge drawn => Done!

					const nodeOutSettings = data.Result.OutNodeData.get(
						this.currentWorkflowNodeInfo.Id
					);

					if (nodeOutSettings === undefined) {
						//TODO: Implement error
						//this.errorService.errorEventEmitter()
						console.log(
							"No OutOutSettings available for node with ID:" +
							this.currentWorkflowNodeInfo.Id
						);

						return;
					}
					this.currentWorkflowNodeInfo.IWorkflowNode.Properties.Configuration =
						nodeOutSettings.Configuration;
					this.savingInProgress = false;

				}
				// if (event.Type === WorkflowGraphEventType.NodeDataChanged) {
				// 	console.log("NodeDataChanged");
				// 	const data = <NodeDataChangedData>event.Data;
				// 	this.currentWorkflowNodeInfo.IWorkflowNode.Properties.Configuration =
				// 		data.Result.Configuration;
				// }
				// if (event.Type === WorkflowGraphEventType.PortDataChanged) {
				// 	console.log("PortDataChanged");
				// 	const data = <PortDataChangedData>event.Data;
				// 	//this.currentWorkflowNode.Properties.Configuration = data.Result;
				// }
				if (event.Type === WorkflowGraphEventType.PortCellClicked) {
					this.savingInProgress = true;

					const data = <PortCellClickedData>event.Data;

					const port_clicked_event = event;

					let currentNode = data.Value;

					// -- new node is clicked
					// this.currentWorkflowNodeID = data.ParentCell.id;
					// this.currentWorkflowNodeInfo.Id = data.ParentCell.id;

					this.currentWorkflowNodeInfo = new WorkflowNodeAdapter(
						data.ParentCell.id,
						data.ParentCellValue
					);

					this.currentEngineName = data.ParentCellValue.Engine.Name;

					this.selectNodeGuiInfo();

					// -- update selected port
					let selectedPort = <mxWorkflowPortValue>currentNode;
					this.selectedNodePort = selectedPort;

					this.savingInProgress = false;

					let selectedPortData = selectedPort.Data;
					if (selectedPortData === undefined) return;

					//check selected port data cases
					if (selectedPortData.length == 0) return;

					let selectedPortDataStats = selectedPortData[0].TableStats;
					if (selectedPortDataStats === undefined) {
						this.resetTableStats();
						return;
					}


					//this.createTableStats(selectedPortDataStats);
				}
				// if (event.Type === WorkflowGraphEventType.ManualSelectionCellChanged) {

				// 		const data = <ManualSelectionCellChangedData>event.Data;
				// 		console.log("ManualSelectionCellChanged: ",data);

				// 		const nodeID = data.oldSelectedCell.getId();
				// 		const nodeCell = <mxWorkflowNodeValue>data.oldSelectedCell.getValue();
				// 		const portValue = <mxWorkflowPortValue> data.newSelectedCell.getValue();

				//         const portData = portValue.Data;
				//         const portTableStats = portData[0].TableStats

				// 		this.createTableStats(portTableStats);
				// }

				if (event.Type === WorkflowGraphEventType.WorkflowExecuted) {
					const data = <WorkflowExecutedData>event.Data;
					const result = data.Result;

					let nodeStates = result.NodeStates;
					const node_states_map = new Map<string, string>();
					nodeStates.forEach((state) => {
						node_states_map.set(state[0], state[1]);
					});
					let logResult = result.Log;
					let errorLogResult = result.Errors;
					let nodeDataResultMap = result.OutNodeData;
					this.errorLogResult = errorLogResult;

					// -- Create Log Data
					if (logResult.length > 0) {
						this.workflowErrorLog = this.createLogTable(logResult);
					}

					if (node_states_map.size > 0) {
						this.workflowStatesLog = this.createStatusLogTable(node_states_map);
					}

					// -- Create Table Stats Data
					if (nodeDataResultMap.size == 0) return;

					if (this.currentWorkflowNodeInfo === undefined) return;
					// -- Get necessary information (Selected Node, selected Port)
					let currentNodePort = this.selectedNodePort;
					let currentNode = this.currentWorkflowNodeInfo.Id;

					// this.currentWorkflowNodeInfo.IWorkflowNode.Engine.Name

					// -- Check for undefined node and port -> exit
					if (currentNode === undefined) return;
					if (currentNodePort === undefined) return;

					// -- Check if Results exists, if not exit
					if (!nodeDataResultMap.has(currentNode)) return;

					let currentNodeResult = nodeDataResultMap.get(currentNode);

					if (!currentNodeResult.PortResults.has(currentNodePort.Name)) return;

					let currentNodePortResults = currentNodeResult.PortResults.get(
						currentNodePort.Name
					);

					let artifacts = currentNodeResult.Artifacts;
					this.createArtifacts(artifacts);

					if (currentNodePortResults.Tables[0] === undefined) return;

					let stats = currentNodePortResults.Tables[0].Stats; // ---> TODO: multi-port to be included

					if (stats === undefined) return;
					this.savingInProgress = false;

					//this.createTableStats(stats);
				}
			}
		);
	}

	//createStatusLogTable(statusLog: Map<string, NodeState>) {
	createStatusLogTable(statusLog: Map<string, string>) {
		let newArray: WorkflowStatusLogEntry[] = [];

		statusLog.forEach((value: string, key: string) => {
			newArray.push(new WorkflowStatusLogEntry(key, value));
		});

		return newArray;
	}

	createLogTable(log): WorkflowLogEntryGui[] {
		// -- check for error entries & get respective Error Message
		let logResultextend: WorkflowLogEntryGui[] = [];

		log.forEach((entry: WorkflowLogEntry) => {
			let newEntry = new WorkflowLogEntryGui();
			newEntry.NodeID = entry.NodeID;
			newEntry.Message = entry.Message;
			newEntry.DateTime = entry.DateTime;
			newEntry.Language = entry.Language;
			newEntry.Level = entry.Level;

			logResultextend.push(newEntry);
		});

		return logResultextend;
	}

	createTableStats(result: TableStats) {

		if (result === undefined) {
			this.resetTableStats();
			return;
		}

		let tableStats: DataTable = result.TableStats;
		let columnStats: DataTable = result.ColumnStats;


		let tableStatsRecords = tableStats.Data;
		let tableStatsFieldsInfo = tableStats.MetaData.FieldsInfo;

		let columnStatsRecords = columnStats.Data;
		let columnStatsFieldsInfo = columnStats.MetaData;

		//-- Prepare TableStats Record
		const tableData = [];
		for (let i = 0; i < tableStatsRecords.length; i++) {
			let row = {};
			for (let j = 0; j < this.currentTableStatsCols.length; j++) {
				row[this.currentTableStatsCols[j]["field"]] = tableStatsRecords[i][j];
			}
			tableData.push(row);
		}

		this.currentTableStatsRecords = tableData;

		//-- Prepare ColumnStats Record
		const columnData = [];
		for (let i = 0; i < columnStatsRecords.length; i++) {
			let row = {};
			for (let j = 0; j < this.currentColumnStatsCols.length; j++) {
				row[this.currentColumnStatsCols[j]["field"]] = columnStatsRecords[i][j];
			}
			columnData.push(row);
		}
		this.currentColumnStatsRecords = columnData;
	}
	resetTableStats() {
		this.currentTableStatsRecords = [];
		this.currentColumnStatsRecords = [];
	}

	createArtifacts(artifacts: DataArtifact[]) {
		this.artifacts = artifacts;
	}

	onRunWorkflowPartially() {
		this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(true, WorkflowOperationType.RunWorkflowPartially)); //Check still needed
		//this.designerService.designerProgressSpinnerEmitter.emit(true);
		this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.runWorkflowPartially);

	}

	plugInInfos: WorkflowNodeGuiInfo[] = [];
	selectedNode?: WorkflowNodeGuiInfo;
	selectNodeGuiInfo() {
		this.subs.sink = this.workflowService.getNodePlugIns().subscribe((x) => {
			const rawGuiInfo = NodePlugInInfos.getNodeGuiInfo();
			const workflow_node_gui_infos = NodePlugInInfos.getWorkflowNodeGuiInfo(
				x,
				rawGuiInfo
			);
			this.plugInInfos = workflow_node_gui_infos;

			// Get current Node Infos
			if (this.currentEngineName) {
				this.selectedNode = this.plugInInfos.find(plugin => plugin.Engine.Name === this.currentEngineName);
			}
		});
	}

	onClickShowData() {
		this.designerService.displayDataPreview.emit([true,undefined])
	}

	/**
	 * https://stackoverflow.com/questions/46391662/focusout-and-blur-not-working-in-angular
	 * @param event
	 */
	onFocusLost(event: FocusEvent) {
		console.log("event", event);
		console.log("currentConfigView: ", this.currentWorkflowNodeInfo)
	}
}