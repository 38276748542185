import { HttpEventType } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { Observable, throwError } from "rxjs";
import { of } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { AppMainComponent } from "src/app/app.main.component";
import { Id } from "src/app/helper/id";
import { ExportFormatInfo } from "src/app/models/api/models/ExportModel";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { DataProviderIdentifier, PsaIdentifier, DataStoreIdentifier, DataSourceIdentifier } from "src/app/models/api/models/staging/DataProviderIdentifier";
import { DataProviderInfo } from "src/app/models/api/models/staging/DataProviderInfo";
import { DataSegment } from "src/app/models/api/models/staging/DataSegment";
import { ExportDataConfig } from "src/app/models/api/models/staging/ExportDataConfig";
import { DataSourceEndpointInfo } from "src/app/models/api/odata/DataSourceEndpointInfo";
import { DataSource } from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { EndpointInfo } from "src/app/models/odata.model";
import { ExportResult } from "src/app/models/staging.model";
import { LoadingAppComponent } from "src/app/pages/loading-app/loading-app.component";
// import { UserInfo } from "src/app/models/user.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { EmailService } from "src/app/services/email.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";
//import { ExportFormatConfig } from "../../designer/components/node-settings/file-downloader-node/file-downloader-node.component";

interface TabViewClickEvent {
	index: number;
	originalEvent: PointerEvent;
}

@Component({
	selector: "app-pipeline",
	templateUrl: "./pipeline.component.html",
	styleUrls: ["./pipeline.component.scss"],
	providers: [MessageService],
})
export class CubePipesComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	baseUrl: string = "";
	@Input() extensionUrl?: string;
	isDownloading: boolean = false;

	finalUrl?: string;


	includeUrl: boolean = true;
	includeFile: boolean = false;
	email: string = "";
	emailText: string = "";
	allUsers: UserInfo[] = [];
	filteredUsers: UserInfo[] = [];
	emails: UserInfo[] = [];

	exportFormatInfos: ExportFormatInfo<any>[] = [];
	dataProviders: DataProviderInfo<DataProviderIdentifier>[] = [];
	//dataProvider: DataProviderInfo<DataProviderIdentifier>;
	psaProvider?: DataProviderInfo<PsaIdentifier>;
	dataStoreProvider?: DataProviderInfo<DataStoreIdentifier>;
	dataSourceProvider?: DataProviderInfo<DataSourceIdentifier>;

	selectedExportFormatInfo?: ExportFormatInfo<any>;

	currentSelectedDataStore?: DataStore;
	currentSelectedDataSource?: DataSource;

	urlInfo: string[] = [];
	apiUrl: string = "/api";
	dataSourceODataUrl: string = "/Staging/DataSources/OData/";
	dataStoreODataUrl: string = "/DataWarehouse/DataStore/OData/";

	dataSourceODataUrlFull: string = "";
	oDataSupports = [{label:"Excel"},{label:"PowerBI"}];

	constructor(
		private datasourceService: DatasourcesService,
		private apiService: ApiBackendService,
		private cubeService: CubesService,
		private messageService: MessageService,
		private emailService: EmailService,
		private appMain: AppMainComponent,
		public loadApp: LoadingAppComponent,
		private userService: UserService,
		private errorService: SystemMessageLogService,
		private translate: TranslateService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.createApiUrl();
		this.subs.sink = this.userService.getUserCommon().subscribe((res: UserInfo[]) => {
		//this.subs.sink = this.userService.getUser().subscribe((res: UserInfo[]) => {
			this.allUsers = res;
		});
		this.subs.sink = this.datasourceService
			.getFormats()
			.subscribe((res: ExportFormatInfo<any>[]) => {
				this.exportFormatInfos = res;
				//this.selectedExportFormatInfo = res[0];
			});
		this.subs.sink = this.datasourceService
			.getDataProviders()
			.subscribe((res: DataProviderInfo<DataProviderIdentifier>[]) => {
				this.dataProviders = res;
				this.psaProvider = <DataProviderInfo<PsaIdentifier>>res.find(p => p.ID === 1);
				this.dataStoreProvider = <DataProviderInfo<DataStoreIdentifier>>res.find(p => p.ID === 2);
				this.dataSourceProvider = <DataProviderInfo<DataSourceIdentifier>>res.find(p => p.ID === 3);
			});
		this.subs.sink = this.cubeService.selectedDataStoreEmitter.subscribe(
			(ds: DataStore) => {

				if (ds === this.currentSelectedDataStore) {
					this.currentSelectedDataStore = undefined;
					this.createApiUrl();
					return;
				}

				this.currentSelectedDataStore = ds;
				this.createApiUrl(ds);
			}
		);
		this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe(
			(ds: DataSource) => {

				if (ds === this.currentSelectedDataSource) {
					this.currentSelectedDataSource = undefined;
					this.createApiUrl();
					return;
				}

				this.currentSelectedDataSource = ds;
				this.createApiUrl(ds);
			}
		);

		this.urlInfo = this.getUrlInfo();
		this.dataSourceODataUrlFull = this.getDataSourceEndpoint();
	}

	// selectExportFormat(evt) {
	// 	// Format Type Check with ID => 1 = excel, 2 = csv ....
	// 	this.selectedExportFormatInfo = <ExportFormatInfo<any>>(
	// 		evt.value
	// 	);
	// }

	onSelectFormat(evt: TabViewClickEvent) {
		console.log(evt);
		this.selectedExportFormatInfo = this.exportFormatInfos[evt.index];
	}

	prepareExportConfig(format_config: ExportFormatInfo<any>): ExportDataConfig<DataProviderIdentifier, any> {

		console.log("downloadFile", format_config);
		// const config = new ExportDataConfig();
		// config.ExportFormatConfig = format_config.InitialConfig;


		//const initial_config = this.exportFormatInfos[0].InitialConfig;
		const initial_config = format_config.InitialConfig;
		const data_segment = new DataSegment();
		let identifier_opt : DataStoreIdentifier | DataSourceIdentifier | undefined = undefined;


		const hrefUrl = window.location.hash;

		if (hrefUrl.includes("destination")) {
			// const identifier_example = Id.assertSet(this.dataStoreProvider?.IdentifierExample, new Error("Data Store Provider is not available"));
			// const identifier = <DataStoreIdentifier>{ ...identifier_example };
			// identifier.ID = Id.assertSet(this.currentSelectedDataStore?.id, new Error("Please select one single Data Store."));
			// config.DataProvider = identifier;
			identifier_opt = CubePipesComponent.extractDataStoreIdentifier(this.dataStoreProvider, this.currentSelectedDataStore);

		}

		if (hrefUrl.includes("datasource")) {
			// const identifier_example = Id.assertSet(this.dataSourceProvider?.IdentifierExample, new Error("Data Source Provider is not available"));
			// const identifier = <DataSourceIdentifier>{ ...identifier_example };
			// identifier.ID = Id.assertSet(this.currentSelectedDataSource?.id, new Error("Please select one single Data Source."))
			// config.DataProvider = identifier;
			identifier_opt = CubePipesComponent.extractDataSourceIdentifier(this.dataSourceProvider, this.currentSelectedDataSource);
		}

		// if (!config.DataProvider) {
		// 	throw new Error("No DataProvider given, please check hash Url: " + hrefUrl);
		// }

		// config.DataSegment = new DataSegment();

		const identifier = Id.assertSet(identifier_opt, new Error("The identifier is unknown!"));

		const config = new ExportDataConfig(identifier, data_segment, initial_config);

		return config;
	}

	prepareDownloadFile(export_info?: ExportFormatInfo<any>): Observable<[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>]> {
		try {
			const target_export_info = Id.assertSet(export_info, new Error("No format selected"));
			const config = this.prepareExportConfig(target_export_info);
			return of([target_export_info, config]);
		} catch (e) {
			const err = <Error>e;
			return throwError(err);
		}
	}

	onDownloadFile(export_info?: ExportFormatInfo<any>) {

		this.isDownloading = true;

		const prepObs = this.prepareDownloadFile(export_info);
		const finalObs = prepObs.pipe(concatMap(prep => this.datasourceService.exportDataProvider(prep[1]).pipe(map(ex_res => {
			const inter_res: [[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>], ExportResult] = [prep, ex_res];
			return inter_res;
		}))));

		// NEU
		const use_chunked_stream = false;
		const export_via_rest = prepObs.pipe(concatMap(config =>this.datasourceService.exportDataProvider(config[1]).pipe(map(res => {
			const final_res:[[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>],number[]] = [config,res.Bytes];
			return final_res;
		}))));
		const export_via_stream = prepObs.pipe(concatMap(config =>this.datasourceService.exportDataProviderStream(config[1]).pipe(map(num_res => {
			const final_res:[[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>],number[]] = [config,num_res];
			return final_res;
		}))));

		const exportDataProviderObs : Observable<[[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>],number[]]> = use_chunked_stream ? export_via_stream : export_via_rest;

		
		// NEW STREAMING EXPORT

		const export_via_chunked_stream = prepObs.pipe(concatMap(config =>this.datasourceService.exportDataProvChunkedStreamBody(config[1]).pipe(map(blob => {
			return blob
		}))));

		const export_via_chunked_stream_events = prepObs.pipe(concatMap(config =>this.datasourceService.exportDataProvChunkedStream(config[1]).pipe(map(blob => {
			return blob
		}))));

		// this.apiService.exportDataProvChunkedStream(config).subscribe((httpEvent) => {
			
		// })


		export_via_chunked_stream_events.subscribe((httpEvent) => {
			
			if (httpEvent.type === HttpEventType.DownloadProgress) {
				console.log("download progress", httpEvent);
			}
			if (httpEvent.type === HttpEventType.Response) {
				console.log("donwload completed", httpEvent);
				const data = window.URL.createObjectURL(httpEvent.body);

				const link = document.createElement("a");
				link.href = data;
				link.download = export_info.Name + "." + export_info.Extension;
	
				// this is necessary as link.click() does not work on the latest firefox
				link.dispatchEvent(
					new MouseEvent("click", {
						bubbles: true,
						cancelable: true,
						view: window,
					})
				);
	
			}
	


		}, (err: Error) => {
			this.errorService.handleError(err)
		}, () => {
			this.isDownloading = false;
		})

		return 
		export_via_chunked_stream.subscribe((blob) => {
			

			const data = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = data;
			link.download = export_info.Name + "." + export_info.Extension;

			// this is necessary as link.click() does not work on the latest firefox
			link.dispatchEvent(
				new MouseEvent("click", {
					bubbles: true,
					cancelable: true,
					view: window,
				})
			);


		}, (err: Error) => {
			this.errorService.handleError(err)
		}, () => {
			this.isDownloading = false;
		})

	

		return

		// END

		this.subs.sink = exportDataProviderObs.subscribe( all_res => {
		//this.subs.sink = finalObs.subscribe(all_res => {

			const result_bytes = all_res[1];
			const config = all_res[0][1];
			const target_export_info = all_res[0][0];

			// Prepare download for Blob
			//console.log("Download response from BE received: ", res);
			const byte_array = result_bytes;
			const u_int_array = new Uint8Array(byte_array);
			const byteBlob = new Blob([u_int_array]);

			const target_blob = byteBlob;

			const data = window.URL.createObjectURL(target_blob);

			const link = document.createElement("a");
			link.href = data;
			link.download = target_export_info.Name + "." + target_export_info.Extension;

			// this is necessary as link.click() does not work on the latest firefox
			link.dispatchEvent(
				new MouseEvent("click", {
					bubbles: true,
					cancelable: true,
					view: window,
				})
			);

			//this.selectedExportFormatInfo = undefined;

			this.messageService.add({
				severity: "success",
				summary: this.translate.instant("Message.ExportDataStoreSuccess.Title"),
				detail: this.translate.instant("Message.ExportDataStoreSuccess.Text1") + config.DataProvider._type +
				this.translate.instant("Message.ExportDataStoreSuccess.Text2")+
				target_export_info.Extension,
				// summary: "Datastore exported!",
				// detail:
				// 	"Datastore with id " +
				// 	config.DataProvider._type +
				// 	" was successfully exported as " +
				// 	target_export_info.Extension,
			});
		}, (err: Error) => {
			this.errorService.handleError(err)
		}, () => {
			this.isDownloading = false;
		});
	}

	createApiUrl(ds?: DataStore | DataSource) {
		if (this.extensionUrl === "dataSource/") {
			this.subs.sink = this.apiService.getDataSourceEndpointInfo().subscribe((endpoints: DataSourceEndpointInfo[]) => {
				this.baseUrl = this.getDataSourceEndpoint();

				if (ds) {
					const selected_endpoint = endpoints.find(endpoint => ds.id === endpoint.DataSource);

					if (selected_endpoint === undefined) {
						throw new Error("There is no endpoint for the selected data source with id " + ds.id);
					}

					this.finalUrl = this.getDataSourceEndpoint(selected_endpoint.EntityName);
				}
				else {
					this.finalUrl = this.getDataSourceEndpoint();
				}

			},
				(err: Error) => {
					this.errorService.handleError(err);
				});
		}
		if (this.extensionUrl === "dataStore/") {
			this.subs.sink = this.apiService.getDataStoreEndpointInfo().subscribe((endpoints: EndpointInfo[]) => {

				this.baseUrl = this.getDataStoreEndpoint();
				if (ds) {
					const selected = endpoints.find(endpoint => ds.id === endpoint.ID);

					if (selected === undefined) {
						throw new Error("There is not endpoint for the selected Data Store with id " + ds.id);
					}

					this.finalUrl = this.getDataStoreEndpoint(selected.EntityName);
				}
				else {
					this.finalUrl = this.getDataStoreEndpoint();
				}


			},
				(err: Error) => {
					this.errorService.handleError(err);
				});
		}
	}

	textToClipboard(url: string) {
		const dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = url;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);

		this.messageService.add({
			severity: "success",
			summary: this.translate.instant("Message.CopyURLSuccess.Title"),
			detail: this.translate.instant("Message.CopyURLSuccess.Text"),
			//summary: "Url copied!",
			//detail: "Url is copied to clipboard",
		});
	}

	// filterUser(event) {
	// 	//in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
	// 	let filtered: any[] = [];
	// 	let query = event.query;

	// 	for (let i = 0; i < this.allUsers.length; i++) {
	// 		let user = this.allUsers[i];
	// 		if (
	// 			user.Username.toLowerCase().indexOf(query.toLowerCase()) == 0 ||
	// 			user.EMail.toLowerCase().indexOf(query.toLowerCase()) == 0
	// 		) {
	// 			filtered.push(user);
	// 		}
	// 	}

	// 	this.filteredUsers = filtered;
	// }
	sendMail() {
		if (!this.emails) {
			this.messageService.add({
				severity: "warn",
				summary: this.translate.instant("Message.NoRecepientsFound.Title"),
				detail: this.translate.instant("Message.NoRecepientsFound.Text"),
				//summary: "No receptients found!",
				//detail: "Could not find receptient ",
			});
			return;
		}

		let fileAttachment: string | ArrayBuffer | undefined = undefined;
		let endpointUrl: string | undefined = undefined;

		if (this.includeFile) {
			fileAttachment = this.prepareFileAttachment();
		}
		if (this.includeUrl) {
			endpointUrl = this.finalUrl;
		}

		this.emails.map((user: UserInfo) => {
			let data = {
				sender: this.appMain.currentUserFull.UserInfo.Username,
				receiver: user.EMail,
				text: this.emailText,
				endpoint: endpointUrl,
				fileName: "Export.xls",
				file: fileAttachment,
			};

			this.emailService.sendDataViaEmail(data).subscribe(
				(res) => {
					console.log("Success");

					this.messageService.add({
						severity: "success",
						summary: this.translate.instant("Message.SendEmailSuccess.Title"),
						detail: this.translate.instant("Message.SendEmailSuccess.Text") + this.email,
						//summary: "Email sent!",
						//detail: "Email was successfully sent to " + this.email,
					});
				},
				(err) => {
					this.messageService.add({
						severity: "warn",
						summary: this.translate.instant("Message.SendEmailFail.Title"),
						detail: this.translate.instant("Message.SendEmailFail.Text") + this.email,
						//summary: "Failed to send!",
						//detail: "Email could not be send to " + this.email,
					});
				}
			);
		});
	}

	static extractDataStoreIdentifier(provider?:DataProviderInfo<DataStoreIdentifier>, selected?:DataStore) : DataStoreIdentifier {
		const identifier_example = Id.assertSet(provider?.IdentifierExample, new Error("Data Store Provider is not available"));
		const identifier = <DataStoreIdentifier>{ ...identifier_example };
		identifier.ID = Id.assertSet(selected?.id, new Error("Please select one single Data Store."));
		return identifier;
	}

	static extractDataSourceIdentifier(provider?:DataProviderInfo<DataSourceIdentifier>, selected?:DataSource): DataSourceIdentifier {
		const identifier_example = Id.assertSet(provider?.IdentifierExample, new Error("Data Source Provider is not available"));
		const identifier = <DataSourceIdentifier>{ ...identifier_example };
		identifier.ID = Id.assertSet(selected?.id, new Error("Please select one single Data Source."))
		return identifier;
	}

	prepareFileAttachment(): string | ArrayBuffer {

		let exportResult: string | ArrayBuffer | null = "";

		if (!this.exportFormatInfos) {
			throw new Error("No Export Formats available! Please select first");
		}
		//const config = new ExportDataConfig();
		//config.ExportFormatConfig = this.selectedExportFormatInfo.InitialConfig;
		//config.ExportFormatConfig = this.exportFormatInfos[0].InitialConfig;
		const initial_config = this.exportFormatInfos[0].InitialConfig;
		const data_segment = new DataSegment();
		let identifier_opt : DataStoreIdentifier | DataSourceIdentifier | undefined = undefined;

		if (this.extensionUrl === "dataStore/") {
			// const identifier_example = Id.assertSet(this.dataStoreProvider?.IdentifierExample, new Error("Data Store Provider is not available"));
			// const identifier = <DataStoreIdentifier>{ ...identifier_example };
			// identifier.ID = Id.assertSet(this.currentSelectedDataStore?.id, new Error("Please select one single Data Store."));
			// config.DataProvider = identifier;
			identifier_opt = CubePipesComponent.extractDataStoreIdentifier(this.dataStoreProvider, this.currentSelectedDataStore);
		}

		if (this.extensionUrl === "dataSource/") {
			// const identifier_example = Id.assertSet(this.dataSourceProvider?.IdentifierExample, new Error("Data Source Provider is not available"));
			// const identifier = <DataSourceIdentifier>{ ...identifier_example };
			// identifier.ID = Id.assertSet(this.currentSelectedDataSource?.id, new Error("Please select one single Data Source."))
			// config.DataProvider = identifier;
			identifier_opt = CubePipesComponent.extractDataSourceIdentifier(this.dataSourceProvider, this.currentSelectedDataSource);
		}

		//config.DataSegment = new DataSegment();
		
		const identifier = Id.assertSet(identifier_opt, new Error("The identifier is unknown!"));

		const config = new ExportDataConfig(identifier, data_segment, initial_config);

		this.subs.sink = this.datasourceService
			.exportDataProvider(config)
			.subscribe((res: ExportResult) => {

				//console.log("Data Export Result from API: ", res);

				const byte_array = res.Bytes;
				const u_int_array = new Uint8Array(byte_array);
				const byteBlob = new Blob([u_int_array]);

				const reader = new FileReader();
				reader.readAsDataURL(byteBlob);
				reader.onloadend = function () {
					const base64String = reader.result;
					exportResult = base64String;

					// Simply Print the Base64 Encoded String,
					// without additional data: Attributes.
				};
			},
				(err: Error) => {
					this.errorService.handleError(err);
				});

		return exportResult;
	}

	getUrlInfo(): string[] {
		const arr = new Array<string>();

		arr.push(window.location.href);
		arr.push(window.location.hostname);
		arr.push(window.location.host);
		arr.push(window.location.origin);
		arr.push(window.location.pathname);

		return arr;
	}

	getDataSourceEndpoint(entity?: string): string {
		return this.getEndpoint(this.dataSourceODataUrl, entity);
	}

	getDataStoreEndpoint(entity?: string): string {
		return this.getEndpoint(this.dataStoreODataUrl, entity);
	}

	getEndpoint(oDataUrl: string, entity?: string) {
		const url = window.location.origin + this.apiUrl + oDataUrl;

		const result: string = entity ? url + entity : url;

		return result;
	}
}
