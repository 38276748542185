// import { EngineInfo, MetaInfo, Workflow, WorkflowNode, WorkflowNodeSettings } from "./workflow.model";

import { EngineInfo } from "./api/com/bion/etl/EngineInfo";
import { MetaInfo } from "./api/com/bion/etl/NodeMetaData";
import { WorkflowNodeSettings, WorkflowNode, Workflow } from "./api/com/bion/etl/Workflow";

export class DescriptionLabel {
    Language:string;
    ShortText: string;
    LongText:string[];


    constructor(Language: string, ShortText: string, LongText: string[]) {
        this.Language = Language;
        this.LongText = LongText;
        this.ShortText = ShortText;
    }
}


export interface WorkflowPlugInInfo {
    Name:string;
    Ports:Array<WorkflowPortBase>;
}

/**
 * Plugin and Origin Information
 */
export interface WorkflowNodeBase extends WorkflowPlugInInfo{
    Repository:string;
    Engine:EngineInfo;
}

/**
 * Describes a workflow node plugin
 */
export class WorkflowNodeInfo implements WorkflowNodeBase {
    Repository:string;
    Engine:EngineInfo;
    Name:string;
    IsFavourite:boolean;
    Ports:WorkflowPortBase[];
    SettingsType:string;
}

export class NodeComplexity {
    Level: number;
    LevelDescription: string[];
}
export class NodeExample  {
    Title: string;
    ExampleText: string[];
    img: string;
}

export interface NodeGuiInfo {
    Name:string;
    NameLabel: string;
    Category: string;
    Settings: string[];
    ComplexityLvl: NodeComplexity;
    UseCases: string[];
    NodeDescription:DescriptionLabel;
    Examples?: NodeExample[];
    IsFavourite: boolean;
    ImageUrls?: any[];
    VideoUrls?: string[];
}

export class WorkflowNodeGuiInfo extends WorkflowNodeInfo implements NodeGuiInfo{
    NameLabel: string;
    Category: string;
    Settings: string[];
    ComplexityLvl: NodeComplexity;
    UseCases: string[];
    NodeDescription:DescriptionLabel;
    Examples?: NodeExample[];
    IsFavourite: boolean;
    ImageUrls?: any[];
    VideoUrls?: string[];
}

export interface WorkflowPortBase {
    Name:string;
    IsInput:boolean;
    Type:string;
    IsMulti:boolean;
}

export class PortInfo implements WorkflowPortBase{
    Name:string;
    IsInput:boolean;
    Type:string;
    IsMulti:boolean;

    constructor(name:string,isInput:boolean, isMulti:boolean=false,type:string=PortInfo.DefaultPort) {
        this.Name = name;
        this.IsInput = isInput;
        this.Type = type;
        this.IsMulti = isMulti;
    }

    static readonly DefaultPort:string = "Default";
}


// export class WorkflowStyleInfo {
//     static PortStyles() {
//       throw new Error('Method not implemented.');
//     }
//     constructor(name:string, style:string) {
//         this.Name = name;
//         this.Style = style;
//     }
//     Name:String;
//     Style:String;
// }

// -------------------------------------------


// case class UpdateNodeSettingsArg[C<:WorkflowNodeSettings,M](EngineInfo:EngineInfo, Configuration:Option[C], MetaData:Option[Map[String,M]])

// export class UpdateNodeSettingsArg {
//     EngineInfo: EngineInfo;
//     Configuration?: WorkflowNodeSettings;
//     MetaData?: Map<string, MetaInfo[]>;
// }



// /**
//  * Marker Trait for Data Statistics
//  */
//  trait DataStats

//  /**
//   * Single port data.
//   */
//  trait PortDataHolder {
//    def Data:Table
//    def Stats:Option[TableStats]
//  }

//  /**
//   * Result of one Port, single or multi. Single ports have only 1 entry, multi ports can have more.
//   */
//  trait PortResult {
//    def Tables:Seq[PortDataHolder]
//  }

//  /**
//   * Execute or Update Settings Result
//   * @tparam C Configuration
//   */
//  trait RunResultLike[C<:WorkflowNodeSettings] {
//    def Configuration:C
//    def PortResults:Map[String,PortResult]
//  }

//  // TODO: adjust interfaces here and in TypeScript

//  // -- Instances
//  case class TableStats(ColumnStats:Table, TableStats:Table) extends DataStats

//  case class UpdateSettingsTableResult(Data:Table, Stats:Option[TableStats]) extends PortDataHolder
//  case class UpdateSettingsPortResult(Tables:Seq[UpdateSettingsTableResult]) extends PortResult
//  case class UpdateSettingsResult[C<:WorkflowNodeSettings](Configuration:C, PortResults:Map[String,UpdateSettingsPortResult]) extends RunResultLike[C]

//  case class ExecuteTableResult(Data:Table, Stats:Option[TableStats]) extends PortDataHolder
//  case class ExecutePortResult(Tables:Seq[ExecuteTableResult]) extends PortResult
//  case class ExecutePlugInResult[C<:WorkflowNodeSettings](Configuration:C, PortResults:Map[String,ExecutePortResult]) extends RunResultLike[C]

//  trait TableConvertible {
//    def toTable : Table
//  }


// NEW API

export interface Table {
    // MetaData:FieldInfo[];
    MetaData:MetaInfo;
    Data:any[][];
}

export class DataTable implements Table {
    // MetaData: FieldInfo[];
    MetaData:MetaInfo;
    Data: any[][];

    constructor(metaData:MetaInfo, data:any[][]) {
        this.MetaData = metaData;
        this.Data = data;
    }
}

export class RunLogInfo {
    TimeStamp:any;
    Message:string;
    Severity:string;        // S = {Info, Warning, Failure}
}

export class TableStats {
    ColumnStats: Table;
    TableStats: Table;
}

/**
 * Marker Trait for Data Statistics
 */
export interface DataStats { }

export class CutInfo {
    TotalRecords: number;
    constructor(TotalRecords: number) {
        if(TotalRecords === undefined) throw new Error( "Class 'CutInfo': Required value 'TotalRecords' is undefined" );
        this.TotalRecords = TotalRecords;
    }
}

/**
 * Single port data.
 */
export interface PortDataHolder {
    Data: Table
    Stats?: TableStats;
    CutInfo?: CutInfo;
}

/**
 * Result of one Port, single or multi. Single ports have only 1 entry, multi ports can have more.
 */
export interface PortResult {
    Tables: Array<PortDataHolder>;
}


// Utility Functions
export class TableUtils {
    /**
     * Builds an empty table with columns from meta information
     * @param meta_info Meta Information
     * @returns An empty strong typed table
     */
    static buildTable(meta_info: MetaInfo): DataTable {
        return new DataTable(meta_info, []);
    }
}


/**
 * Execute or Update Settings Result
 * @tparam C Configuration
 */
export interface RunResultLike<C extends WorkflowNodeSettings> {
    Configuration: C;
    PortResults: Map<string, PortResult>;
}

export class UpdateSettingsTableResult implements PortDataHolder {
    Data: Table;
    Stats?: TableStats;
    CutInfo?: CutInfo;
    constructor(Data: Table,Stats?: TableStats,CutInfo?: CutInfo) {
        if(Data === undefined) throw new Error( "Class 'UpdateSettingsTableResult': Required value 'Data' is undefined" );
        this.Data = Data;
        this.Stats = Stats;
        this.CutInfo = CutInfo;
    }
}

export class UpdateSettingsPortResult implements PortResult {
    Tables: Array<UpdateSettingsTableResult>;
}
export class UpdateSettingsResult<C extends WorkflowNodeSettings> implements RunResultLike<C> {
    Configuration: C;
    PortResults: Map<string, UpdateSettingsPortResult>;
}

export interface DataArtifactLike {
    /**
     * Artifact User Defined Name
     */
     Name:string;
     /**
      * Base 64 Encoded Byte Array String
      */
     Bytes:number[];
     /**
      * Format Type ID
      */
     TypeID:number;

     MimeType: string;

     Extension: string;
}

export class DataArtifact implements DataArtifactLike {
    Name:string;
    Bytes:number[];
    TypeID:number;
    MimeType: string;
    Extension: string;
}

export class ExecuteTableResult implements PortDataHolder{
    Data: Table;
    Stats?: TableStats;
    CutInfo?: CutInfo;
    constructor(Data: Table,Stats?: TableStats,CutInfo?: CutInfo) {
        if(Data === undefined) throw new Error( "Class 'ExecuteTableResult': Required value 'Data' is undefined" );
        this.Data = Data;
        this.Stats = Stats;
        this.CutInfo = CutInfo;
    }
}
export class ExecutePortResult implements PortResult {
    Tables: Array<ExecuteTableResult>;
}
export class ExecutePlugInResult<C extends WorkflowNodeSettings> implements RunResultLike<C> {
    Configuration: C;
    PortResults: Map<string, ExecutePortResult>;
    Artifacts:Array<DataArtifactLike>;
}



// case class SettingsResult[C,M](Configuration:C, MetaInfo:Map[String,M])
export class SettingsResult<C, M> {

    Configuration: C;
    MetaInfo: Map<string, M[]>;

    constructor(configuration: C, metaInfo: Map<string, M[]>) {
        this.Configuration = configuration;
        this.MetaInfo = metaInfo;
    }
}

export class ExecutePlugInArg {
    EngineInfo: EngineInfo;
    Data: Map<string, Table[]>;
    Configuration: WorkflowNodeSettings;
    MetaData: Map<string, MetaInfo[]>;
}



export class NodeClickEvent {
    readonly WorkflowNode: WorkflowNode;
    readonly Ports: PortInfo[];

    constructor(node: WorkflowNode, ports: PortInfo[]) {
        this.WorkflowNode = node;
        this.Ports = ports;
    }
}

export class WorkflowLogEntry {
    Level: string;
    Message: string;
    DateTime?: string;
    Language?: string;
    NodeID?: string;
}

export class NodeState {
    _type: string;
}

export class ExceptionInfo {
    Message: string;
    Type: string;
    StackTrace: Array<string>;
}

/**
 * Base for Update Settings and Execute Workflow Result Data.
 *
 * Both methods return the same object. They only differ in the volume of return data.
 */
export interface WorkflowRunResult<C extends WorkflowNodeSettings, T extends RunResultLike<C>> {
    OutNodeData: Map<string, T>;
    NodeStates: Array<[string,string]>;
    Errors: Array<[string, ExceptionInfo]>;
    Log: WorkflowLogEntry[];
}

export class WorkflowResult implements WorkflowRunResult<WorkflowNodeSettings, ExecutePlugInResult<WorkflowNodeSettings>> {
    OutNodeData: Map<string, ExecutePlugInResult<WorkflowNodeSettings>>;
    NodeStates: Array<[string,string]>;
    Errors: Array<[string, ExceptionInfo]>;
    Log: WorkflowLogEntry[];
}

export class UpdateWorkflowSettingsResult implements WorkflowRunResult<WorkflowNodeSettings, UpdateSettingsResult<WorkflowNodeSettings>> {
    OutNodeData: Map<string, UpdateSettingsResult<WorkflowNodeSettings>>;
    NodeStates: Array<[string,string]>;
    Errors: Array<[string, ExceptionInfo]>;
    Log: WorkflowLogEntry[];
}



export class ExecuteUpdateSettingsArg {
    Workflow: Workflow;
    StartNodeID: string;
}




// -----------------------------------------

export class WorkflowLogEntryGui extends WorkflowLogEntry{
    Description?: string;
}

export class WorkflowStatusLogEntry {
    constructor(nodeID:string, message:string) {
        this.Message = message;
        this.NodeID = nodeID;
    }
    readonly NodeID: string;
    readonly Message: string;
}
