<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<p-dialog header="{{'Delete destination' | translate}}" [(visible)]="displayDeleteDataStore" modal="modal"
    showEffect="fade" [style]="{width: '600px'}">
    <div style="line-height: 1.5" *ngIf="selectedDataStore">
        {{'DeleteCube?' | translate}} {{selectedDataStore.id}} ?
    </div>
    <app-system-message-log></app-system-message-log>
    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDeleteDataStore=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="deleteDataStore(selectedDataStore)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
