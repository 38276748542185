import { Component, OnInit } from '@angular/core';
import { ConfigComponent } from '../../../create-ab-source/config.component';

// Google Sheets Settings, können wir später ein bischen aufräumen und in die 'api/...' Ordnerstruktur packen.

export namespace GoogleSheets {
  export class Credentials {
    auth_type: string;
    client_id: string;
    client_secret: string;
    refresh_token: string;
    constructor(auth_type: string,client_id: string,client_secret: string,refresh_token: string) {
      if(auth_type === undefined) throw new Error( "Class 'Credentials': Required value 'auth_type' is undefined" );
      if(client_id === undefined) throw new Error( "Class 'Credentials': Required value 'client_id' is undefined" );
      if(client_secret === undefined) throw new Error( "Class 'Credentials': Required value 'client_secret' is undefined" );
      if(refresh_token === undefined) throw new Error( "Class 'Credentials': Required value 'refresh_token' is undefined" );
      this.auth_type = auth_type;
      this.client_id = client_id;
      this.client_secret = client_secret;
      this.refresh_token = refresh_token;
    }
  }
  export class Config {
    credentials: Credentials;
    row_batch_size: number;
    spreadsheet_id: string;
    constructor(credentials: Credentials,row_batch_size: number,spreadsheet_id: string) {
      if(credentials === undefined) throw new Error( "Class 'Config': Required value 'credentials' is undefined" );
      if(row_batch_size === undefined) throw new Error( "Class 'Config': Required value 'row_batch_size' is undefined" );
      if(spreadsheet_id === undefined) throw new Error( "Class 'Config': Required value 'spreadsheet_id' is undefined" );
      this.credentials = credentials;
      this.row_batch_size = row_batch_size;
      this.spreadsheet_id = spreadsheet_id;
    }
  }
}

/**
 * Noch unvollständig, POC state.
 */
@Component({
  selector: 'app-google-sheets',
  templateUrl: './google-sheets.component.html',
  styleUrls: ['./google-sheets.component.scss']
})
export class GoogleSheetsComponent implements OnInit, ConfigComponent {

  data:GoogleSheets.Config;   // TODO: check if we need to initialize this here.

  constructor() { }

  ngOnInit(): void {
  }

}
