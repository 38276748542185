<app-create-schedule-dialog></app-create-schedule-dialog>
<app-edit-schedule-dialog></app-edit-schedule-dialog>
<app-delete-schedule-dialog></app-delete-schedule-dialog>
<app-create-action-dialog [selectedSchedule]="selectedSchedule" [schedules]="schedules" [baseActions]="baseActions" (click)="appMain.rightPanelClick = true">
</app-create-action-dialog>
<app-delete-action-dialog (click)="appMain.rightPanelClick = true">
</app-delete-action-dialog>
<app-create-frequency-dialog [schedules]="schedules" (click)="appMain.rightPanelClick = true"></app-create-frequency-dialog>
<app-delete-frequency-dialog (click)="appMain.rightPanelClick = true"></app-delete-frequency-dialog>
<app-run-schedule-dialog></app-run-schedule-dialog >

<app-add-permission-dialog view="SchedulerView" (click)="appMain.rightPanelClick = true"></app-add-permission-dialog>
<app-delete-permission-dialog [view]="'SchedulerView'" (click)="appMain.rightPanelClick = true"></app-delete-permission-dialog>
<app-change-permission-dialog [view]="'SchedulerView'" (click)="appMain.rightPanelClick = true"></app-change-permission-dialog>


<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>

<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'ChangeDatasourceInformation' | translate }}"
                (click)="displayEditWorkflowDialog(true)"></button>
            <button pButton pRipple type="button" icon="pi pi-trash"
                pTooltip="{{'DeleteDatasourcePermanently' | translate }}" (click)="displayDeleteWorkflowDialog(true)"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
        </div>

    </ng-template>
</p-overlayPanel>
<div class="spinner-container" *ngIf="loading" @fade>
    <app-loading-screen></app-loading-screen>
</div>
<div class="p-grid">
    <div style="height: 2px; width:100%">
        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
    </div>

    <span class="p-col" style="display: flex; align-items: center;">
        <app-general-search #scheduleObjectSearch [typeClass]="scheduleSearchTypeClass" [currentView]="'Schedules'">
        </app-general-search>

        <span style="color: white;margin:0px 10px">|</span>
        <span style="display: flex; align-items: center;">
            <app-generic-object-tasks #scheduleObjectTasks [currentView]="'Schedules'"></app-generic-object-tasks>

        </span>
    </span>
    <span class="p-col-12 p-md-6 p-lg-3">
        <span style="float:right">
            <app-generic-top-toolbar #scheduleObjectToolbar [typeClass]="scheduleToolbarTypeClass"
                [currentView]="'Schedules'" @fade></app-generic-top-toolbar>

        </span>
    </span>
</div>
<!-- <app-generic-top-toolbar #scheduleObjectToolbar [typeClass]="scheduleToolbarTypeClass" [currentView]="'Schedules'" @fade></app-generic-top-toolbar> -->

<div class="p-grid" id="container">
    <!-- <div class="p-col-2 p-md-2 p-lg-2" style="padding: 0;margin-top: 20px; margin-left: 20px; height: 100%"
        *ngIf="displaySearchPanel">
        <app-general-search #scheduleObjectSearch [typeClass]="scheduleSearchTypeClass" [currentView]="'Schedules'">
        </app-general-search>
    </div> -->
    <div class="p-col" style="padding: 0 20px;margin-top: 20px;">
        <div class="card">
            <app-general-object-view [objectList]="schedules" [userDetails]="userDetails" #scheduleObjectView
                [typeClass]="scheduleRecordTypeClass" [currentView]="'Schedules'"></app-general-object-view>
        </div>
    </div>
    <!-- <div class="p-col-12 p-md-6 p-lg-6" style="padding: 0;margin-right: 20px;" *ngIf="displayWidgets">
        <div class="card" style="margin-top: 20px">
            <p-tabView [scrollable]="false">
                <p-tabPanel header="{{'Activities' | translate }}">
                    <app-generic-latest-activities #scheduleLatestActivities [typeClass]="scheduleActivityTypeClass"
                        [currentView]="'Schedules'"></app-generic-latest-activities>
                </p-tabPanel>
                <p-tabPanel header="{{'Permissions' | translate }}">
                    <app-user-permissions-list view="SchedulerView"></app-user-permissions-list>
                </p-tabPanel>
                <p-tabPanel header="{{'Actions' | translate }}">
                    <app-schedule-action></app-schedule-action>
                </p-tabPanel>
                <p-tabPanel header="{{'Frequencies' | translate }}">
                    <app-schedule-frequency></app-schedule-frequency>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div> -->
</div>

<div class="layout-rightpanel" (click)="appMain.rightPanelClick = true">
    <div class="rightpanel-wrapper">
        <p-tabView [scrollable]="false">
            <p-tabPanel header="{{'Activities' | translate }}">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.png"
                        alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.gif"
                        alt="freya-layout" />
                </ng-template>
                <app-generic-latest-activities #scheduleLatestActivities [typeClass]="scheduleActivityTypeClass"
                    [currentView]="'Schedules'"></app-generic-latest-activities>
            </p-tabPanel>
            <p-tabPanel header="{{'Permissions' | translate }}"
                *ngIf="appMain.currentUserFull.UserInfo.IsAdmin === true">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.png" alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.gif" alt="freya-layout" />
                </ng-template>

                <app-user-permissions-list view="SchedulerView"></app-user-permissions-list>
            </p-tabPanel>
            <p-tabPanel header="{{'Actions' | translate }}">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Actions' | translate }}" tooltipPosition="top"
                        src="assets/layout/images/animatedIcons/weg.png" alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Actions' | translate }}" tooltipPosition="top"
                        src="assets/layout/images/animatedIcons/weg.gif" alt="freya-layout" />
                </ng-template>
                <app-schedule-action></app-schedule-action>
            </p-tabPanel>
            <p-tabPanel header="{{'Frequencies' | translate }}">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Frequencies' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/veranstaltung.png"
                        alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Frequencies' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/veranstaltung.gif"
                        alt="freya-layout" />
                </ng-template>
                <app-schedule-frequency></app-schedule-frequency>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
