<div class="spinner-container" *ngIf="loading" @fade>
    <app-loading-screen></app-loading-screen>
</div>

<div class="layout-dashboard" *ngIf="appMain.currentUserFull">
    <!-- <div @fade [hidden]="MessageHidden" style="text-align: center;position:absolute; top:60px;left:0; width: calc(100% - 63px);margin-left: 63px;background: linear-gradient(79deg, rgba(9,160,199,0.3) 0%, rgba(43,95,147,0.3) 51%);">
        <div style="margin: 5px 5px">
            <i style="margin-right: 5px;display: inline-block " class="pi pi-info-circle"></i>

            <span style="margin-bottom:7px;display: inline-block ">{{'Doyoulikeourfreeservices?Supportusbybecominganearlyadopterandsave50%onyourfirstannualsubscriptionafterourofficiallaunch(~Dec2022)!Dontforgettousethecode'
            | translate}}
            </span>
            <span style="margin-left: 5px">
                <button pButton label="{{'Upgrade' | translate }}" class="p-button-success" (click)="onClickUpdateButton()"></button>
            </span>
            <span style="margin-left: 5px">
                <button pButton label="{{'GiveFeedback' | translate }}"  (click)="onClickGiveFeedbackButton()"></button>
            </span>
            <div style="margin-left: 1rem; float: right">
                <button pButton pRipple type="button" icon="pi pi-times" (click)="clearMessage()"
                    class="p-button-rounded p-button-secondary p-button-text"></button>
            </div>
        </div>
    </div> -->

    <div class="p-grid" @fade>
        <!-- <div class="p-col-12 p-md-6 p-xl-1">
            <div class="card operations DashboardItem" [ngClass]="{'selected': selectedView === 'Home'}" id="step-one" (click)="selectView('Home')">
                <div class="card-header" style="padding-bottom: 0;text-align: center;">
                    <i class="pi pi-home" style="font-size: 24px"></i>
                </div>
            </div>
        </div> -->
        <!-- <div class="p-col-12 p-md-6 p-xl-2">
            <div class="card DashboardAction" [ngClass]="{'selected': selectedView === 'Home'}" id="step-one" (click)="selectView('Home')" pTooltip="{{'Create automation from scratch' | translate}}" tooltipPosition="top">
                <div class="p-grid">
                    <div class="p-col-4">
                        <i class="pi pi-folder" style="font-size: 24px"></i>
                    </div>
                    <div class="p-col-8">
                        <h6>{{'New automation' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6 p-xl-2">
            <div class="card DashboardAction" [ngClass]="{'selected': selectedView === 'Home'}" id="step-one" (click)="selectView('Home')" pTooltip="{{'Create automation from scratch' | translate}}" tooltipPosition="top">
                <div class="p-grid" >
                    <div class="p-col-4">
                        <i class="pi pi-folder" style="font-size: 24px"></i>
                    </div>
                    <div class="p-col-8" style="top:50%">
                        <h6>{{'Marketplace' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="p-col-12 p-md-6 p-xl-2">
            <div class="card operations DashboardItem" [ngClass]="{'selected': selectedView === 'DataSource'}" id="step-one" (click)="selectView('Home')">
                <div class="card-header" style="padding-bottom: 0;">
                    <div class="card-title">
                        <p *ngIf="datasources">{{datasources.length}} {{'Datasources' | translate}}</p>

                        <!-- <p class="subtitle" *ngIf="datasources.length > 0">
                            <span *ngFor="let tag of DatasourceCategoryTags" style="margin-right: 3px;">
                                <p-tag severity="help">{{tag.value}}: {{tag.counter}}</p-tag>
                            </span>
                        </p> -->
                    </div>
                    <div>
                        <i class="pi pi-table" style="font-size: 24px"></i>
                        <!-- <button pButton type="button" label="{{'Manage' | translate}}"
                            class="p-button-rounded p-button-plain p-button-text" routerLink="/datasources"></button> -->

                        <!-- <button pButton pRipple type="button" icon="pi pi-folder" style="color:white"
                            class="p-button-rounded p-button-text" pTooltip="{{'Manage' | translate}}"
                            tooltipPosition="top" routerLink="/datasources"></button> -->
                    </div>
                </div>
                <p-progressBar [style]="{ height: '3px' }" [showValue]="false" pTooltip="{{currentLimits[0].percentage}}% {{'left' | translate}}" tooltipPosition="bottom" *ngIf="currentLimits.length > 0" [value]="currentLimits[0].percentage"></p-progressBar>


                <!-- <div class="p-grid" *ngIf="datasources">
                    <div class="device-status p-col-12 p-md-12 p-xl-12">
                            <app-datasource-charts *ngIf="datasources.length > 0" [fieldInfos]="fieldInfos">
                            </app-datasource-charts>
                            <app-no-data-available *ngIf="datasources.length === 0" [view]="'datasource'"
                                [url]="'/datasources'"></app-no-data-available>
                    </div> 
                </div> -->
            </div>

        </div>

        <div class="p-col-12 p-md-6 p-xl-2">
            <div class="card operations DashboardItem" id="step-two" [ngClass]="{'selected': selectedView === 'Workflow'}" (click)="selectView('Home')">
            <div class="card-header"  style="padding-bottom: 0;">
                    <div class="card-title">
                        <p *ngIf="workflows">{{workflows.length}} {{'Workflows' | translate}}</p>
                        <!-- <p class="subtitle" *ngIf="workflows.length > 0">

                            <span *ngFor="let tag of workflowCategoryTags" style="margin-right: 3px;">
                                <p-tag severity="danger">{{tag.value}}: {{tag.counter}}</p-tag>
                            </span>
                        </p> -->
                    </div>
                    <div>
                        <i class="pi pi-share-alt" style="font-size: 24px"></i>

                        <!-- <button pButton pRipple type="button" icon="pi pi-folder" style="color:white"
                            class="p-button-rounded p-button-text" pTooltip="{{'Manage' | translate}}"
                            tooltipPosition="top" routerLink="/workflows"></button> -->
                    </div>
                </div>
                <p-progressBar [style]="{ height: '3px' }" [showValue]="false" pTooltip="{{currentLimits[1].percentage}}% {{'left' | translate}}" tooltipPosition="bottom" *ngIf="currentLimits.length > 0" [value]="currentLimits[1].percentage"></p-progressBar>

                <!-- <p-divider></p-divider> -->
                <!-- <div class="p-grid">
                    <div class="device-status p-col-12 p-md-12 p-xl-12">
                            <app-workflow-charts [workflows]="workflows" *ngIf="workflows.length > 0"
                                [plugInInfos]="plugInInfos"></app-workflow-charts>
                            <app-no-data-available *ngIf="workflows.length === 0" [view]="'workflow'"
                                [url]="'/workflows'"></app-no-data-available>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="p-col-12 p-md-6 p-xl-2">
            <div class="card operations DashboardItem" id="step-three" [ngClass]="{'selected': selectedView === 'Schedule'}" (click)="selectView('Home')">

                <div class="card-header"  style="padding-bottom: 0;" *ngIf="!showDataSourceChart">
                    <div class="card-title">
                        <p *ngIf="schedules">{{schedules.length}} {{'Schedules' | translate}}</p>
                        <!-- <p class="subtitle" *ngIf="schedules.length > 0">
                            <span *ngFor="let tag of scheduleCategoryTags" style="margin-right: 3px;">
                                <p-tag severity="warning">{{tag.name}}: {{tag.counter}}</p-tag>
                            </span>
                        </p> -->
                    </div>
                    <div>
                        <i class="pi pi-calendar" style="font-size: 24px"></i>

                        <!-- <button pButton pRipple type="button" icon="pi pi-folder" style="color:white"
                            class="p-button-rounded p-button-text" label="{{'Open' | translate}}" pTooltip="{{'Manage' | translate}}"
                            tooltipPosition="top" routerLink="/schedules"></button> -->
                    </div>
                </div>
                <p-progressBar [style]="{ height: '3px' }"  pTooltip="{{currentLimits[2].percentage}}% {{'left' | translate}}" tooltipPosition="bottom" [showValue]="false" *ngIf="currentLimits.length > 0" [value]="currentLimits[2].percentage"></p-progressBar>

                <!-- <div class="p-grid" *ngIf="showDataSourceChart">
                    <div class="p-col-12 p-md-12 p-lg-12 ">
                        <app-schedule-line-chart *ngIf="schedules.length > 0">
                        </app-schedule-line-chart>
                        <app-no-data-available *ngIf="schedules.length === 0" [view]="'schedule'"
                            [url]="'/schedules'"></app-no-data-available>
                    </div>
                </div> -->
                <!-- <p-divider></p-divider> -->

            </div>
        </div>

        <div class="p-col-12 p-md-6 p-xl-2">
            <div class="card operations DashboardItem" id="step-four" [ngClass]="{'selected': selectedView === 'Destination'}" (click)="selectView('Home')">
            <div class="card-header" style="padding-bottom: 0;">
                    <div class="card-title">
                        <p *ngIf="datastores">{{datastores.length}} {{'Destinations' | translate}}</p>
                        <!-- <p class="subtitle" *ngIf="datastores.length > 0">
                            <span *ngFor="let tag of cubeCategoryTags" style="margin-right: 3px;">
                                <p-tag severity="success">{{tag.name}}: {{tag.counter}}</p-tag>
                            </span>
                        </p> -->
                    </div>
                    <div>
                        <i class="pi pi-microsoft" style="font-size: 24px"></i>
                        <!-- <button pButton pRipple type="button" icon="pi pi-folder" style="color:white"
                            class="p-button-rounded p-button-text" pTooltip="{{'Manage' | translate}}"
                            tooltipPosition="top" routerLink="/destinations"></button> -->
                    </div>
                </div>
                <p-progressBar  [style]="{ height: '3px' }" pTooltip="{{currentLimits[3].percentage}}% {{'left' | translate}}" tooltipPosition="bottom" [showValue]="false" *ngIf="currentLimits.length > 0" [value]="currentLimits[3].percentage"></p-progressBar>

                <!-- <p-divider></p-divider> -->
                <!-- <div class="p-grid">
                    <div class="device-status p-col-12 p-md-12 p-lg-12">
                            <app-datasource-charts *ngIf="datastores.length > 0" [fieldInfos]="dataStoreFields">
                            </app-datasource-charts>
                            <app-no-data-available *ngIf="datastores.length === 0" [view]="'cube'" [url]="'/cubes'">
                            </app-no-data-available>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- <div class="p-col-6">
            <app-schedule-charts [schedules]="schedules" *ngIf="schedules.length > 0">
            </app-schedule-charts>
        </div> -->



        <!-- <div class="p-col-12 p-md-12 p-lg-12">
            <app-schedule-charts [schedules]="schedules" *ngIf="schedules.length > 0">
            </app-schedule-charts>
        </div> -->

        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="selectedView === 'DataSource'">
            <app-datasources-view></app-datasources-view>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="selectedView === 'Workflow'">
            <app-workflows-view></app-workflows-view>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="selectedView === 'Schedule'">
            <app-schedules-view></app-schedules-view>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12" *ngIf="selectedView === 'Destination'">
            <app-destinations-view></app-destinations-view>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12"  *ngIf="selectedView === 'Home'">
            <div class="card operations" id="step-five">
                <p-tabView>
                    <p-tabPanel header="{{'Overview' | translate}}">
                        <div class="container p-grid">
                            <div class="device-status p-col-12 p-md-6 p-lg-9">
                                <h5 style="width:100%; margin-bottom: 15px">{{'Scheduled automation over time' | translate}}<app-info-tooltip text="{{'Scheduled automation over time_tooltip' | translate}}"></app-info-tooltip></h5>
                                <app-no-data-available *ngIf="schedules.length == 0" [view]="'datasource'"
                                [url]="'/datasources'"></app-no-data-available> 
                                <app-schedule-charts [schedules]="schedules" *ngIf="schedules.length > 0">
                                </app-schedule-charts>
                            </div>
                            <div class="p-col-12 p-md-6 p-lg-3" >
                                <h5 style="width:100%; margin-bottom: 15px">{{'UpcomingActivities' | translate}}<app-info-tooltip text="{{'UpcomingActivities_tooltip' | translate}}"></app-info-tooltip></h5>
                                <app-no-data-available *ngIf="schedules.length == 0" [view]="'datasource'"
                                [url]="'/datasources'"></app-no-data-available> 
                                <app-upcoming-appointments-timeline *ngIf="schedules.length > 0">
                                </app-upcoming-appointments-timeline>
                            </div>
                            <!-- <div class="p-col-6 p-md-6 p-lg-6">
                                <h4>General Information</h4>
                                <p>432.000 Einträge aus Datenquellen werden verwaltet</p>
                                <p>80% der Einträge werden in Workflows berücksichtigt</p>
                                <p>10 Datenquellen und 332.000 Einträge werden durch 130 Knoten aufbereitet</p>
                            </div> -->
                            <div class="p-col-12"></div>
                            <h5 style="width:100%; margin-bottom: 15px">{{'Indicators' | translate}}<app-info-tooltip text="{{'Indicators_tooltip' | translate}}"></app-info-tooltip></h5>
                            <div *ngIf="datasources" class="device-status p-col-12 p-md-6 p-xl-3"
                                style="text-align: center;">
                                <!-- <app-datasource-charts *ngIf="datasources.length > 0" [fieldInfos]="fieldInfos">
                                </app-datasource-charts> -->
                                <app-kpi-indicator [percentage]="ds_metric"></app-kpi-indicator>
                                <h5>{{ds_metric}}% {{'of datasources connected' | translate }}</h5>
                                <p>{{ds_metric_details}} {{'Fields' | translate}}</p>
                                <!-- <app-no-data-available *ngIf="datasources.length === 0" [view]="'datasource'"
                                    [url]="'/datasources'"></app-no-data-available> -->
                            </div>
                            <div *ngIf="datasources" class="device-status p-col-12 p-md-6 p-xl-3"
                                style="text-align: center;">
                                <!-- <app-datasource-charts *ngIf="datasources.length > 0" [fieldInfos]="fieldInfos">
                                </app-datasource-charts> -->
                                <app-kpi-indicator [percentage]="wf_metric"></app-kpi-indicator>

                                <h5>{{wf_metric}}% {{'of workflows automated' | translate }}</h5>
                                <p>{{wf_metric_details}} {{'steps' | translate}}</p>
<!-- 
                                <app-no-data-available *ngIf="datasources.length === 0" [view]="'datasource'"
                                    [url]="'/datasources'"></app-no-data-available> -->
                            </div>
                            <div *ngIf="datasources" class="device-status p-col-12 p-md-6 p-xl-3"
                                style="text-align: center;">
                                <!-- <app-datasource-charts *ngIf="datasources.length > 0" [fieldInfos]="fieldInfos">
                                </app-datasource-charts> -->
                                <app-kpi-indicator [percentage]="dest_metric"></app-kpi-indicator>

                                <h5>{{dest_metric}}% {{'of destinations automated' | translate }}</h5>
                                <p>{{dest_metric_details}} {{'Fields' | translate}}</p>
                                <!-- <app-no-data-available *ngIf="datasources.length === 0" [view]="'datasource'"
                                    [url]="'/datasources'"></app-no-data-available> -->
                            </div>
                            <div *ngIf="datasources" class="card device-status p-col-12 p-md-6 p-xl-3"
                                style="text-align: center;background: linear-gradient(79deg, #34c7c2 0%, #0494c5 0%); border-radius: 5px;color: white">
                                <!-- <app-datasource-charts *ngIf="datasources.length > 0" [fieldInfos]="fieldInfos">
                                </app-datasource-charts> -->
                                                    <app-kpi-indicator [percentage]="final_metric"></app-kpi-indicator>
                                <h5>{{final_metric}}% {{'time saved (estimate)' | translate}}</h5>
                                <p>{{final_metric_details}} {{'hours (estimate)' | translate}}</p>

                                <!-- <app-no-data-available *ngIf="datasources.length === 0" [view]="'datasource'"
                                    [url]="'/datasources'"></app-no-data-available> -->
                            </div>

                            <!-- <div *ngIf="datasources" class="device-status p-col-3 p-md-3 p-xl-3">
                                    <app-datasource-charts *ngIf="datasources.length > 0" [fieldInfos]="fieldInfos">
                                    </app-datasource-charts>
                                    <app-no-data-available *ngIf="datasources.length === 0" [view]="'datasource'"
                                        [url]="'/datasources'"></app-no-data-available>
                            </div>
                            <div *ngIf="workflows" class="device-status p-col-3 p-md-3 p-xl-3">
                                <app-workflow-charts [workflows]="workflows" *ngIf="workflows.length > 0"
                                    [plugInInfos]="plugInInfos"></app-workflow-charts>
                                <app-no-data-available *ngIf="workflows.length === 0" [view]="'workflow'"
                                    [url]="'/workflows'"></app-no-data-available>
                            </div> 
                            <div *ngIf="schedules" class="p-col-3 p-md-3 p-lg-3 ">
                                <app-schedule-line-chart  *ngIf="schedules.length > 0">
                                </app-schedule-line-chart>
                                <app-no-data-available *ngIf="schedules.length === 0" [view]="'schedule'"
                                    [url]="'/schedules'"></app-no-data-available>
                            </div>
                            <div *ngIf="datastores" class="device-status p-col-3 p-md-3 p-lg-3">
                                <app-datasource-charts *ngIf="datastores.length > 0" [fieldInfos]="dataStoreFields">
                                </app-datasource-charts>
                                <app-no-data-available *ngIf="datastores.length === 0" [view]="'cube'" [url]="'/cubes'">
                                </app-no-data-available>
                            </div> -->
                        </div>
                    </p-tabPanel>
                    <!-- <p-tabPanel header="{{'UpcomingActivities' | translate}}">
                        <div class="container p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <app-upcoming-appointments-timeline *ngIf="schedules.length > 0">
                                </app-upcoming-appointments-timeline>
                            </div>
                        </div>
                    </p-tabPanel> -->
                    <p-tabPanel header="{{'History' | translate}}">
                        <div class="container p-grid">
                            <div class="device-status p-col-12 p-md-12 p-lg-12">
                                <app-generic-latest-activities #dashboardLatestActivities
                                    [typeClass]="dashboardActivityTypeClass"
                                    [currentView]="'Datasources'"></app-generic-latest-activities>
                            </div>
                        </div>
                    </p-tabPanel>
                    <!-- <p-tabPanel header="Header III">
                        <p>
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
                            qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                        </p>
                    </p-tabPanel> -->
                </p-tabView>
            </div>
        </div>

        <!-- 
        <div class="p-col-6 p-md-6 p-xl-6" >
            <div class="card operations" id="step-five"  style="height:50vh">
                <div class="card-header">
                    <div class="card-title">
                        <h4>{{'UpcomingActivities' | translate}}</h4>

                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-8 p-md-8 p-lg-8">
                        <app-schedule-charts [schedules]="schedules" *ngIf="schedules.length > 0">
                        </app-schedule-charts>
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-4">
                        <app-upcoming-appointments-timeline *ngIf="schedules.length > 0">
                        </app-upcoming-appointments-timeline>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-6 p-md-6 p-xl-6">
            <div class="card operations" id="step-six"  style="height:50vh">
                <div class="card-header">
                    <div class="card-title">
                        <h4>{{'History' | translate}}</h4>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="device-status p-col-12 p-md-12 p-lg-12">
                        <app-generic-latest-activities #dashboardLatestActivities [typeClass]="dashboardActivityTypeClass" [currentView]="'Datasources'"></app-generic-latest-activities>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</div>