<div class="spinner-container" *ngIf="loading">
    <app-loading-screen></app-loading-screen>
</div>


<p-dialog [showHeader]="true" [maximizable]="true" [position]="'center'" [blockScroll]="false" [draggable]="false"
    [modal]="false" [dismissableMask]="true" [closable]="true" [(visible)]="displayDataPreview"
    [style]="{width: 'calc(80%)', height:'calc(100%)', 'z-index': '10500'}" [resizable]="true">

    <p-table #dt [columns]="cols" [value]="data" styleClass="p-datatable-sm" [showLoader]="true"
        [loading]="columnsLoading" (sortFunction)="customSort($event)" scrollDirection="both" [scrollable]="true"
        [reorderableColumns]="false" [resizableColumns]="true" columnResizeMode="expand" scrollHeight="calc(70vh)"
        autoLayout="true" [customSort]="true">

        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <span class="p-input-icon-left">
                    <div>
                        <p-selectButton [options]="nodePorts" [(ngModel)]="selectedNodePort"
                            (onOptionClick)="onPortChanged($event)" tooltipPosition="top" optionLabel="Name">
                        </p-selectButton>
                        <span *ngIf="multiPortOptions.length > 1">
                            <p-selectButton [options]="multiPortOptions" [(ngModel)]="selectedMultiPortOption"
                                (onOptionClick)="onMultiPortChanged($event)" optionLabel="Name">
                            </p-selectButton>
                        </span>
                    </div>
                </span>
                <span *ngIf="displayedRecords"> {{displayedRecords}} {{'of' | translate }} {{totalRecords}} rows | {{cols.length}} columns <span
                        *ngIf="columnsCollapsed">(Preview limited to {{columnPreviewSize}} cols)</span></span>
                <span class="p-input-icon-right">

                    <div style="display:inline-block; margin-right: 10px;" pTooltip="{{'Show all columns' | translate}}"
                        tooltipPosition="top">
                        <p-inputSwitch [(ngModel)]="columnsCollapsed"
                            (onChange)="onColumnsCollapsed($event)"></p-inputSwitch>
                    </div>
                    <div style="display:inline-block"><p-selectButton [options]="previewModes"
                            [(ngModel)]="selectedPreviewMode" tooltipPosition="top" optionLabel="name"
                            (onChange)="onViewModeChanged($event)">
                        </p-selectButton>
                    </div>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
                    [style]="{'width':'250px', 'font-size':'12px'}" *ngFor="let col of columns">
                    {{col.header}}
                    <span style="margin-left: 5px;font-size:8px"
                        [class]="'customer-badge status-' + col.dataType">{{col.dataType}}</span>

                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <p-columnFilter type="text" [field]="col.field" display="menu" class="p-ml-auto">
                    </p-columnFilter>

                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td [style]="{'width':'250px','font-size':'12px'}" *ngFor="let col of columns">
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>



<!-- <p-paginator [rows]="rowsPerPage" [totalRecords]="totalRecords" pageLinkSize="3"
currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
(onPageChange)="onTablePageChanged($event)"></p-paginator> -->


<!-- <div style="width: 100%;">
    <p-tabView >
        <p-tabPanel header="{{'Data' | translate}}">
            <p-table #dt [columns]="cols" [value]="data" styleClass="p-datatable-sm" [showLoader]="true" [loading]="columnsLoading" (sortFunction)="customSort($event)"
                scrollDirection="both" [scrollable]="true" [reorderableColumns]="false" [resizableColumns]="true"
                columnResizeMode="expand" scrollHeight="calc(27vh)" autoLayout="true" [customSort]="true">

                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <span class="p-input-icon-left">
                            <div>
                                <p-selectButton [options]="nodePorts" [(ngModel)]="selectedNodePort"
                                    (onOptionClick)="onPortChanged($event)" tooltipPosition="top" optionLabel="Name">
                                </p-selectButton>
                                <span *ngIf="multiPortOptions.length > 1">
                                    <p-selectButton [options]="multiPortOptions" [(ngModel)]="selectedMultiPortOption"
                                        (onOptionClick)="onMultiPortChanged($event)" optionLabel="Name">
                                    </p-selectButton>
                                </span>
                            </div>
                        </span>

                        <span *ngIf="totalRecords"> {{totalRecords}} rows | {{cols.length}} columns <span *ngIf="columnsCollapsed">(Preview limited to {{columnPreviewSize}} cols)</span></span>


                        <span class="p-input-icon-right">

                                <div style="display:inline-block; margin-right: 10px;" pTooltip="{{'Show all columns' | translate}}" tooltipPosition="top">

                                    <p-inputSwitch [(ngModel)]="columnsCollapsed" (onChange)="onColumnsCollapsed($event)"></p-inputSwitch>

                                </div>
                                <div style="display:inline-block"><p-selectButton [options]="previewModes" [(ngModel)]="selectedPreviewMode"
                                    tooltipPosition="top" optionLabel="name" (onChange)="onViewModeChanged($event)">
                                </p-selectButton>
                            </div>

                        </span>


                    </div>

                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
                            [style]="{'width':'250px', 'font-size':'12px'}" *ngFor="let col of columns">
                            {{col.header}}
                            <span style="margin-left: 5px;font-size:8px"
                                [class]="'customer-badge status-' + col.dataType">{{col.dataType}}</span>

                            <p-sortIcon [field]="col.field"></p-sortIcon>
                            <p-columnFilter type="text" [field]="col.field" display="menu" class="p-ml-auto">
                            </p-columnFilter>

                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td [style]="{'width':'250px','font-size':'12px'}" *ngFor="let col of columns">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="rowsPerPage" [totalRecords]="totalRecords" pageLinkSize="3"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                (onPageChange)="onTablePageChanged($event)"></p-paginator>
        </p-tabPanel>
        <p-tabPanel header="{{'Statistics' | translate}}">
            <app-node-stats [tableStats]="tableStats" [wfPortResult]="wfPortResult" [toggleView]="false">
            </app-node-stats>
        </p-tabPanel>
    </p-tabView>
</div> -->
