<!-- <p-overlayPanel #op1 [showCloseIcon]="true" [style]="{width: '750px'}">
    <ng-template pTemplate>
        <app-node-info [selectedNode]="selectedNode"></app-node-info>
    </ng-template>
</p-overlayPanel> -->

<p-dialog header="Details" [(visible)]="displayNodeInfo" showEffect="fade"
    [style]="{width: '50vw', 'max-height': '30vw'}" (onHide)="resetCurrentForm()">
    
    <ng-template pTemplate="header">
        <div style="  display: flex;
        align-items: center;">
            <img width="35px" [src]="'assets/layout/images/nodes/' + selectedNode.NameLabel + '.png'"
            [alt]="selectedNode.NameLabel" /><span><h5 style="margin-bottom:0; margin-left: 10px">{{selectedNode.NameLabel | translate}}</h5></span>
        </div>

    </ng-template>
    <app-node-info [selectedNode]="selectedNode"></app-node-info>
</p-dialog>




    <!-- <div class="card flex justify-content-center">
        <p-panelMenu [model]="plugInInfos" [style]="{'width':'300px'}" [multiple]="true"></p-panelMenu>
    </div> -->


    <!-- <div style="margin-top: 7px">
        <span class="p-input-icon-left" style="width:100%">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" autoWidth="false"
                [style]="{'width':'100%'}" placeholder="{{'Nodes.SearchForNode' | translate}}" />
        </span>

    </div> -->


    <p-scrollPanel [style]="{height: 'calc(60vh)'}">
        <div class="pContainer p-grid" #dt>
            <!-- <div #plugin *ngFor="let plugIn of plugInInfos; let i = index" class="pItem p-col-4"  (click)="onRightClick($event, plugIn)"  (contextmenu)="onRightClick($event, plugIn)" (onDragStart)="dragStart($event,plugIn)" pDraggable="plugInInfos" #plugInItem> -->

            <div #plugin *ngFor="let plugIn of plugInInfos; let i = index" class="pItem p-col-4"  (click)="onRightClick($event, plugIn)"  (contextmenu)="onRightClick($event, plugIn)" #plugInItem>
                <span class="pCategory">                
                    <img [alt]="plugIn.NameLabel" [src]="'assets/layout/images/nodes/' + plugIn.NameLabel  + '.png'"
                    width="40" />
                </span>
                <span class="pName"><p>{{plugIn.NameLabel | translate }}</p></span>
                <p-contextMenu appendTo="body" [target]="plugin" [model]="items"></p-contextMenu>

            </div>

        </div>
        <!-- <div class="ctaContainer p-grid card">
            {{'Missing something?' | translate}} <br><a (click)="onRequestNode()">{{'Send node request here!' | translate}} </a>
        </div> -->
        <div class="p-grid">
            <div class="card selected">
                <div class="p-col-12">
                    <img style="width: 25px" src="assets/layout/images/misc/plaudern.png" alt="freya-layout" />
                    <h5>{{'Missing a node?' | translate}} </h5>
                    <a class="Link" (click)="onRequestNode()">{{'Send node request here!' | translate}} </a>
                </div>
                <!-- <div class="p-col-12">

                </div> -->
            </div>
        </div>


        <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
        </p-scrollTop>
    </p-scrollPanel>
    <!-- <p-contextMenu appendTo="body" [target]="plugInItem" [model]="items"></p-contextMenu> -->

<!-- 
    <p-scrollPanel [style]="{height: 'calc(60vh)'}">
        <p-table #dt [value]="plugInInfos" [(selection)]="selectedNode"  rowGroupMode="subheader" groupRowsBy="connector.Connector" selectionMode="none" sortField="representative.name"
            sortMode="single" (onSort)="onSort()"
            [globalFilterFields]="['Name','NameLabel','NodeDescription.ShortText','status']" [scrollable]="false"
            [showPageLinks]="false" styleClass="p-datatable-customers p-datatable-sm">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem">
                    </th>
                    <th pSortableColumn="NameLabel" style="width: 80%">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{'Name' | translate}}
                            <p-sortIcon field="NameLabel"></p-sortIcon>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-node let-rowIndex="rowIndex">
                <tr [pSelectableRow]="node" (onDragStart)="dragStart($event,node)" pDraggable="plugInInfos"
                    (onDragEnd)="dragEnd($event)">
                    <td><span class="p-column-title"></span>
                        <img [alt]="node.NameLabel" [src]="'assets/layout/images/nodes/' + node.NameLabel  + '.png'"
                            width="40" style="vertical-align: left" />
                    </td>
                    <td>
                        {{node.NameLabel}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">{{'NoEntryFound' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>

        <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
        </p-scrollTop>
    </p-scrollPanel> -->
