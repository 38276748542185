<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'Permission.ChangePermission' | translate}}"
                (click)="displayEditPermissionDialog()"></button>
            <button pButton pRipple type="button" icon="pi pi-trash"
                pTooltip="{{'Permission.DeletePermission' | translate}}" (click)="displayDeletePermissionDialog()"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2 "></button>
        </div>
    </ng-template>
</p-overlayPanel>

<div style="margin:20px 0">
    <h5>
        {{'Permissions' | translate }} <app-info-tooltip></app-info-tooltip>
        <span style="float: right">
            <button pButton pRipple type="button" icon="pi pi-user-plus"
            pTooltip="{{'Permission.CreateNewRole' | translate}}" (click)="displayNewUserPermissionDialog()"
            class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
        </span>
    </h5>
</div>



<div class="card">

    <!-- <span style="float: right;">
        <button pButton pRipple type="button" icon="pi pi-user-plus" label="{{'Permit' | translate}}"
            pTooltip="{{'Permission.CreateNewRole' | translate}}" (click)="displayNewUserPermissionDialog()"
            class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
    </span> -->
<p-table #dtc [value]="genericPermissionView" selectionMode="single" styleClass="p-datatable-customers p-datatable-sm"
    [rows]="9" paginator=true [filterDelay]="0"
    [globalFilterFields]="['object.name','permission.Accessible.ID','permission.IsOwner','roleName','permission.Rights']">
    <!-- <ng-template pTemplate="caption">
        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{'Permission.SearchForPermission' | translate}}" />
            </span>
            <span class="p-input-icon-right">
                <button pButton pRipple type="button" icon="pi pi-user-plus" label="{{'Permit' | translate}}"
                    pTooltip="{{'Permission.CreateNewRole' | translate}}" (click)="displayNewUserPermissionDialog()"
                    class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
            </span>
        </div>
    </ng-template> -->
    <ng-template pTemplate="header" let-role>
            <tr>
                <th >
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Role' | translate}}
                        <p-columnFilter type="text" field="roleName" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th  style="width: 20%;">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Owner' | translate}}
                        <p-columnFilter type="text" field="object.isOwner" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th style="width: 50%;">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Rights' | translate}}
                        <p-columnFilter field="permission.Rights" matchMode="contains" [showMatchModes]="false" [showOperator]="false" display="menu">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="rights" (onChange)="filter($event.value)" placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span [class]="'customer-badge status-' + option">{{option}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th style="width: 4rem">        
                    <!-- <button pButton pRipple type="button" icon="pi pi-user-plus"
                    pTooltip="{{'Permission.CreateNewRole' | translate}}" (click)="displayNewUserPermissionDialog()"
                    class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button> -->
                </th>
            </tr>
        </ng-template>
    <ng-template pTemplate="body" let-role>
        <tr>
            <td>
                <span class="p-column-title">{{'Role' | translate}}</span>
                <p-chip *ngIf="role.roleType === 'GroupRole'" label="{{role.roleName | translate}}" icon="pi pi-users">
                </p-chip>
                <!-- <p-chip *ngIf="role.roleType === 'UserRole'" label="{{role.roleName | translate}}" icon="pi pi-user"> -->
                <p-chip *ngIf="role.roleType === 'UserRole' && role.image === undefined" label="{{role.roleName}}"
                    icon="pi pi-user">
                </p-chip>
                <p-chip *ngIf="role.roleType === 'UserRole' && role.image  !== undefined" label="{{role.roleName}}"
                    [image]="role.image"></p-chip>
                    <!-- <img *ngIf="role.permission.IsOwner === true" src="assets/layout/images/animatedIcons/team-management.png" style="width: 30px" pTooltop="{{ 'isOwnerOf' | translate }}" />  -->
                    <!-- <span *ngIf="role.permission.IsOwner === false" style="font-size: 12px; font-style:italic"> - {{ 'isMemberOf' | translate }}</span> -->

                <!-- <p-chip *ngIf="role.permission.IsOwner === true" pTooltip="{{'isOwnerOf' | translate}}" styleClass="mr-2 custom-chip-owner" icon="pi pi-key"
                ></p-chip> -->
                <!-- <p-avatar *ngIf="role.permission.IsOwner === true" pTooltip="{{'isOwnerOf' | translate}}" icon="pi pi-key" styleClass="mr-2"  [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" shape="circle"></p-avatar> -->
                <!-- <p-avatar *ngIf="role.permission.IsOwner === false" pTooltip="{{'isMemberOf' | translate}}" icon="pi pi-user" styleClass="mr-2"  [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" shape="circle"></p-avatar> -->

                <!-- <p-chip *ngIf="role.permission.IsOwner === false" pTooltip="{{'isMemberOf' | translate}}" styleClass="mr-2 custom-chip-member"
                ></p-chip> -->
            </td>
            <td style="width: 20%;">
                <span class="p-column-title">{{'Owner' | translate}}</span>
                <span *ngIf="role.permission.IsOwner === true">{{ 'isOwnerOf' | translate }}</span>
                <span *ngIf="role.permission.IsOwner === false">{{ 'isMemberOf' | translate }}</span>

            </td>
            <!-- <td style="text-align: center;">
                    <span class="p-column-title">{{'Role Type' | translate}}</span>
                    <i class="pi pi-user"  pTooltip="{{'User' | translate}}"  *ngIf="role.roleType === 'UserRole'" ></i>
                    <i class="pi pi-users"  pTooltip="{{'Group' | translate}}"  *ngIf="role.roleType === 'GroupRole'" ></i>
                </td> -->

            <td style="width: 50%;">
                <span class="p-column-title">{{'Rights' | translate}}</span>
                <span *ngFor="let right of role.permission.Rights" pTooltip="{{right}}"
                    [class]="'customer-badge status-' + right">
                    <span *ngIf="right === 'Write'">W</span>
                    <span *ngIf="right === 'Delete'">D</span>
                    <span *ngIf="right === 'Update'">U</span>
                    <span *ngIf="right === 'Admin'">A</span>
                    <span *ngIf="right === 'Create'">C</span>
                    <span *ngIf="right === 'Read'">R</span>
                </span>
            </td>

            <td style="width: 4rem;">
                <div class="ui-table-buttongroup-right">
                    <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                        class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                        pTooltip="{{'Permission.ChangePermission' | translate}}" (click)="onMenuClicked(role)"
                        (click)="op1.toggle($event)"></button>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="4">{{'NoEntryFound' | translate}}</td>
        </tr>
    </ng-template>
</p-table>
</div>

<!-- <div>
    <div class="p-grid" style="display: flex; align-items: center;">
        <div class="p-col-1">
            <i class="pi pi-key" style="font-size: 24px"></i>
        </div>
        <div class="p-col-3">
            <span>Owner</span>
        </div>
        <div class="p-col">
            <span  style="float:right">
                <p-avatarGroup styleClass="mb-3">
                    <p-avatar image="assets/demo/images/avatar/amyelsner.png" size="large" shape="circle"></p-avatar>
                    <p-avatar image="assets/demo/images/avatar/asiyajavayant.png" size="large" shape="circle"></p-avatar>
                    <p-avatar image="assets/demo/images/avatar/onyamalimba.png" size="large" shape="circle"></p-avatar>
                    <p-avatar image="assets/demo/images/avatar/ionibowcher.png" size="large" shape="circle"></p-avatar>
                    <p-avatar image="assets/demo/images/avatar/xuxuefeng.png" size="large"shape="circle"></p-avatar>
                    <p-avatar label="+2" shape="circle" size="large" [style]="{'background-color':'#9c27b0', 'color': '#ffffff'}"></p-avatar>
                </p-avatarGroup>
            </span>
        </div>
    </div>
    <div class="p-grid" style="display: flex; align-items: center;">
        <div class="p-col-1">
            <i class="pi pi-user" style="font-size: 24px"></i>
        </div>
        <div class="p-col-3">
            <span>Team Member</span>
        </div>
        <div class="p-col">
            <span  style="float:right">
                    <p-avatarGroup styleClass="mb-3">
                        <p-avatar image="assets/demo/images/avatar/amyelsner.png" size="large" shape="circle"></p-avatar>
                        <p-avatar image="assets/demo/images/avatar/asiyajavayant.png" size="large" shape="circle"></p-avatar>
                        <p-avatar image="assets/demo/images/avatar/onyamalimba.png" size="large" shape="circle"></p-avatar>
                        <p-avatar image="assets/demo/images/avatar/xuxuefeng.png" size="large"shape="circle"></p-avatar>
                        <p-avatar label="+6" shape="circle" size="large" [style]="{'background-color':'#9c27b0', 'color': '#ffffff'}"></p-avatar>
                    </p-avatarGroup>
            </span>
        </div>
    </div>
</div>
<p-divider></p-divider> -->

<!-- <div class="p-grid p-fluid" style="margin: 0 0.2rem;">
    <div class="p-col-12">
        <div class="device-status">
            <div >
                <div class="card-header">
                    <div class="card-title">
                        <h6>{{'Permissions' | translate}}
                        </h6>
                    </div>
                    <p class="subtitle">
                        <button *ngIf="selectedObject" pButton pRipple type="button" icon="pi pi-user-plus" label="{{'Create' | translate}}" pTooltip="{{'Permission.CreateNewRole' | translate}}"
                        (click)="displayNewUserPermissionDialog()"
                        class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
                </p>
                </div>

                <p-table #dtc [value]="genericPermissionView" [(selection)]="selectedPermission" (onRowSelect)="onSelectPermission($event)"
                (onRowUnselect)="onUnselectPermission($event)"  selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-sm" [rows]="15" paginator=true [filterDelay]="0"
                    [globalFilterFields]="['role.permission.Accessible.ID','name','role.permission.IsOwner','role.roleName','role.permission.Rights','Accessible.Value']">
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{'Permission.SearchForPermission' | translate}}" />
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-role>
                        <tr>
                            <th pSortableColumn="role.permission.Accessible.ID">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Accessible' | translate}}
                                    <p-columnFilter type="text" field="role.permission.Accessible.ID" display="menu" class="p-ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th pSortableColumn="role.roleName">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Role' | translate}}
                                    <p-columnFilter type="text" field="role.roleName" display="menu" class="p-ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th pSortableColumn="role.roleType">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Role Type' | translate}}
                                    <p-sortIcon field="role.roleType"></p-sortIcon>

                                </div>
                            </th>
                            <th pSortableColumn="role.permission.Rights">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Rights' | translate}}
                                    <p-columnFilter type="text" field="role.permission.Rights" display="menu" class="p-ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th style="width: 4rem"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-role>
                        <tr [pSelectableRow]="role">
                            <td>
                                <span class="p-column-title">{{'Accessible' | translate}}</span>
                                {{role.permission.Accessible.ID}}
                            </td>
                            <td >
                                <span class="p-column-title">{{'Role' | translate}}</span>
                                {{role.roleName}}
                                <i class="pi pi-key"  pTooltip="{{'Owner' | translate}}" *ngIf="role.permission.IsOwner === true"></i>
                            </td>
                            <td style="text-align: center;">
                                <span class="p-column-title">{{'Role Type' | translate}}</span>
                                <i class="pi pi-user"  pTooltip="{{'User' | translate}}"  *ngIf="role.roleType === 'UserRole'" ></i>
                                <i class="pi pi-users"  pTooltip="{{'Group' | translate}}"  *ngIf="role.roleType === 'GroupRole'" ></i>
                            </td>

                            <td>
                                <span class="p-column-title">{{'Rights' | translate}}</span>
                                <span *ngFor="let right of role.permission.Rights"   pTooltip="{{right}}"   [class]="'customer-badge status-' + right" >
                                    <span *ngIf="right === 'Write'" >W</span>
                                    <span *ngIf="right === 'Delete'" >D</span>
                                    <span *ngIf="right === 'Update'" >U</span>
                                    <span *ngIf="right === 'Admin'" >A</span>
                                    <span *ngIf="right === 'Create'" >C</span>
                                    <span *ngIf="right === 'Read'" >R</span>
                                </span>
                            </td>

                            <td>
                                <div class="ui-table-buttongroup-right">
                                    <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                                        class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="{{'Permission.ChangePermission' | translate}}"
                                        (click)="onMenuClicked(role)" (click)="op1.toggle($event)"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div> -->
