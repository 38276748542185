import { Observable, of } from "rxjs";
import { Arrays } from "src/app/helper/arrays";
import { DataStoreProtocolEntry } from "src/app/models/api/models/staging/DataStoreProtocolEntry";
import { RichDataStoreCake } from "src/app/models/api/models/staging/RichDataStoreCake";
import { DataStore } from "src/app/models/datastore.model";
import { UserDetailsRow } from "src/app/models/user.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CubesService } from "src/app/services/cubes.service";
import { UserService } from "src/app/services/user.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { ActivityEntry, LatestActivityProvider } from "./latest-activity-provider";
import { ActivityUtils } from "./util-functions";

export class DestinationActivity implements LatestActivityProvider<DataStore, DataStoreProtocolEntry[]> {
  constructor(private bionApi: ApiBackendService, private dataStoresSevice: CubesService, private users: UserDetailsRow[], private utilService: UtilFunctionsService) { }

  getData(): Observable<DataStoreProtocolEntry[]> {
    return this.bionApi.getDataStoreProtocolEntry();
  }
  buildGuI(data: DataStoreProtocolEntry[], select: DataStore): ActivityEntry[] {
    let objProtocols: DataStoreProtocolEntry[] = data;

    if (select) {
      objProtocols = objProtocols.filter((protocol) => protocol.dataStore === select.id)
    }

    let sortedProtocols = objProtocols.sort(
      (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
    );

    let latestProtocols = sortedProtocols;

    const latest_protocols_with_img:ActivityEntry[] = latestProtocols.map((protocol) => {
      return ActivityUtils.extractImage(this.users,protocol, this.utilService)
    });

    return latest_protocols_with_img;

    //return latestProtocol
  }
  subscribeToEmitter(): Observable<DataStore> {
    let selectedObs = this.dataStoresSevice.selectedDataStoreEmitter;
    return selectedObs
  }
}

export class DestinationRichActivity implements LatestActivityProvider<DataStore, RichDataStoreCake.DataStoreView[]> {
  constructor(private dataStoresSevice: CubesService, private data: RichDataStoreCake.DataStoreView[],private users: UserDetailsRow[],private utilService: UtilFunctionsService) { }

  getData(): Observable<RichDataStoreCake.DataStoreView[]> {
    return of(this.data);
  }
  buildGuI(data: RichDataStoreCake.DataStoreView[], select: DataStore): ActivityEntry[] {

    const target_data = select ? data.filter(d => d.DataStore.id == select.id) : data;

    const protocols: DataStoreProtocolEntry[] = Arrays.flatMap(target_data, arg => arg.Protocols.map(p => p.Entry));

    const aggregated_protocols: Array<DataStoreProtocolEntry> = [...protocols];

    const sorted_protocols = aggregated_protocols.sort(
      (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
    );

    const latest_protocols = sorted_protocols;

    const latest_protocols_with_img:ActivityEntry[] = latest_protocols.map((protocol) => {
      return ActivityUtils.extractImage(this.users,protocol, this.utilService)
    });

    return latest_protocols_with_img;


    return latest_protocols;

    // let objProtocols: DataStoreProtocolEntry[] = data;

    // if(select) {
    //   objProtocols = objProtocols.filter((protocol) => protocol.dataStore === select.id)
    // }

    // let sortedProtocols = objProtocols.sort(
    //   (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
    // );

    // let latestProtocol = sortedProtocols;

    // return latestProtocol
  }
  subscribeToEmitter(): Observable<DataStore> {
    let selectedObs = this.dataStoresSevice.selectedDataStoreEmitter;
    return selectedObs
  }
}