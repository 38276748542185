import { Observable, of } from "rxjs";
import { ActionPlanProtocolEntry } from "src/app/models/api/models/scheduler/ActionPlanProtocolEntry";
import { RichSchedulerCake } from "src/app/models/api/models/scheduler/RichSchedulerCake";
import { ScheduleActionPlan } from "src/app/models/schedule.model";
import { UserDetailsRow } from "src/app/models/user.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { SchedulesService } from "src/app/services/schedules.service";
import { UserService } from "src/app/services/user.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { ActivityEntry, LatestActivityProvider } from "./latest-activity-provider";
import { ActivityUtils } from "./util-functions";

export class ScheduleActivity implements LatestActivityProvider<ScheduleActionPlan, ActionPlanProtocolEntry[]> {
    constructor(private bionApi: ApiBackendService, private schedulesService: SchedulesService, private userService: UserService) {}
  
    getData(): Observable<ActionPlanProtocolEntry[]> {
      return this.bionApi.getActionPlanProtocolEntry();
    }
    buildGuI(data: ActionPlanProtocolEntry[], select: ScheduleActionPlan): ActivityEntry[] {
      let objProtocols: ActionPlanProtocolEntry[] = data;

      console.log("objProtocols",objProtocols);

      if(select) {
        objProtocols = objProtocols.filter((protocol) => {
          return protocol.actionPlan === select.id
        })
      }
  
      let sortedProtocols = objProtocols.sort(
        (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
      );
  
      let latestProtocol = sortedProtocols;
  
      return latestProtocol
  
    }
    subscribeToEmitter(): Observable<ScheduleActionPlan> {
      let selectedObs = this.schedulesService.selectedSchedulePlanEmitter;
      return selectedObs
    }
  }

  export class ScheduleViewActivity implements LatestActivityProvider<ScheduleActionPlan, RichSchedulerCake.ActionPlanView[]> {
    constructor(private data:RichSchedulerCake.ActionPlanView[], private schedulesService: SchedulesService, private users: UserDetailsRow[], private utilService: UtilFunctionsService) {}
  
    getData(): Observable<RichSchedulerCake.ActionPlanView[]> {
      return of(this.data);
    }
    buildGuI(data: RichSchedulerCake.ActionPlanView[], select: ScheduleActionPlan): ActivityEntry[] {

      const target_data = select ? data.filter(d => d.Plan.id === select.id) : data;
      const objProtocols:ActionPlanProtocolEntry[] = target_data.flatMap(v => v.Protocols.map(p => p.Entry));
  
      const sortedProtocols = objProtocols.sort(
        (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
      );
  
      const latestProtocol = sortedProtocols;

      const latest_protocols_with_img:ActivityEntry[] = latestProtocol.map((protocol) => {
        return ActivityUtils.extractImage(this.users,protocol, this.utilService)
      });
  
      return latest_protocols_with_img;
  
      //return latestProtocol
  
    }
    subscribeToEmitter(): Observable<ScheduleActionPlan> {
      let selectedObs = this.schedulesService.selectedSchedulePlanEmitter;
      return selectedObs
    }
  }