import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { forkJoin, Observable } from "rxjs";
import { CheckBoxEvents, TableEvents } from "src/app/helper/events";
import * as dss from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { DestinationActionEvent, DestinationDialogActionType } from "src/app/models/dialog-actions.model";
import { DataTablePreview } from "src/app/models/staging.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-psa-table",
	templateUrl: "./psa-table.component.html",
	styleUrls: ["./psa-table.component.scss"],
})
export class PsaTableComponent implements OnInit, OnDestroy {
	displayPsa: boolean = true;
	subs = new SubSink();
	selectedDatasource?: dss.DataSource;
	selectedDataStore?: DataStore;
	psaInfos: dss.PersistentStagingArea[] = [];
	@ViewChild("dt") table!: Table;

	totalRecords: number = 0;
	rowsPerPage: number = 100;
	loading: boolean = false;

	cols: dss.FieldInfo[] = [];
	psaData: any[] = [];
	psaDataFull: any[] = [];

	writeMode: string = "";
	showMetaFields: boolean = false;
	showLatestPackage: boolean = false;

	rowCount: number = 0;
	columnCount: number = 0;
	currentRowCount: number = 0;

	dataPackages: any[];
	selectedDataPackage: any;

	constructor(
		private bionApi: ApiBackendService,
		private datasourceService: DatasourcesService,
		private cubeService: CubesService,
		private errorService: SystemMessageLogService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.psaInfos) {
			if (changes.psaInfos.currentValue) {
				const psa_opt = changes.psaInfos.currentValue[0];
				if (psa_opt)
					this.writeMode = <string>psa_opt.writeMode;
			}
		}
	}

	ngOnInit() {

		// this.subs.sink = this.datasourceService.displayPsaDialog.subscribe(display => {
		// 	console.log("RECEVIED EVENT", display);
		// 	this.displayPsa = display;

		// 	if (this.selectedDatasource && this.selectedDataStore) {
		// 		console.log("WARNING: Data Source AND Data Store selected. Using Data Source.");
		// 	}

		// 	if (this.selectedDatasource) {
		// 		this.loading = true;
		// 		this.subs.sink = this.queryDataSource(this.selectedDatasource).subscribe(data_and_count => {
		// 			this.init_data(data_and_count);
		// 		},
		// 			(err: Error) => {
		// 				this.errorService.handleError(err);
		// 			}, () => {
		// 				this.loading = false;
		// 			}
		// 		)
		// 	}

		// 	if (this.selectedDataStore) {
		// 		this.loading = true;
		// 		this.subs.sink = this.queryDataStore(this.selectedDataStore).subscribe(data_and_count => {
		// 			this.init_data(data_and_count);
		// 			this.writeMode = this.selectedDataStore.writeMode;
		// 			this.loading = false;
		// 		},
		// 			(err: Error) => {
		// 				this.cols = [];
		// 				this.psaData = [];
		// 				console.log("No Psa Found")
		// 				this.errorService.handleError(err);
		// 			}, () => {
		// 				this.loading = false
		// 			})
		// 	}
		// });
		this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe(
			(datasource: dss.DataSource) => {
				if (datasource === undefined || datasource === this.selectedDatasource) {
					this.table.value = [];
					this.table.columns = [];
					this.writeMode = "";
					this.selectedDatasource = undefined;
					return;
				}
				this.selectedDatasource = datasource;
                if(this.displayPsa === true) {
                    this.loading = true;
                    this.subs.sink = this.queryDataSource(datasource).subscribe(data_and_count => {
                        this.init_data(data_and_count);
                        //this.loading = false;
                    },(err) => {
                        this.errorService.handleError(err)
                    },() => { this.loading = false})
                }

			}, (err: Error) => {
				this.errorService.handleError(err)
			}
		);

		// SUBSCRIBE TO EVENTS
		this.subs.sink = this.cubeService.selectedDataStoreEmitter.subscribe(
			(datastore: DataStore) => {

				if (datastore === this.selectedDataStore || datastore === undefined) {
					this.table.value = [];
					this.table.columns = [];
					this.selectedDataStore = undefined;
					this.writeMode = "";
					return;
				}
				this.selectedDataStore = datastore;
                if(this.displayPsa === true) {
                    this.loading = true;
                    this.subs.sink = this.queryDataStore(datastore).subscribe(data_and_count => {
                        this.init_data(data_and_count);
						this.writeMode = this.selectedDataStore.writeMode;
                        //this.loading = false;
                    },(err) => {
						this.psaData = [];
						this.cols = [];
						console.log("No PSA Found");
						this.loading = false;
                        // this.errorService.handleError(err);
                    },() => { this.loading = false})
                }

			}, (err: Error) => {
				this.errorService.handleError(err)
			}
		);
	}

	selectDataPackage(event) {}
	
	queryDataSource(ds: dss.DataSource): Observable<[DataTablePreview, number]> {
		const query_obs = this.bionApi.psaApiQuery(ds.id, this.rowsPerPage, 0,false,this.showLatestPackage, this.showMetaFields);
		const count_obs = this.bionApi.psaApiCount(ds.id);
		this.bionApi.getPsaOfDataSource(ds.id).subscribe(psa => {
			this.psaInfos = [psa];
			this.writeMode = this.psaInfos[0].writeMode
		}
		);

		const psa_info_obs = forkJoin(query_obs, count_obs);

		return psa_info_obs;
	}

	init_data(info_result: [dss.DataTypePreview, number]) {
		const res = info_result[0];
		const row_count = info_result[1];

		this.rowCount = row_count;
		this.columnCount = res.Columns.length;

		this.table.columns = res.Columns;
		this.psaDataFull = this.createPsaDataModel(res);
		this.table.value = this.psaDataFull;
		this.totalRecords = row_count;

		//TODO: Wenn showLatest
		this.currentRowCount = res.Rows.length
	}

	queryDataStore(ds: DataStore) {
		const query_obs = this.cubeService.datastoreApiQuery(ds.id, this.rowsPerPage, 0, false, this.showMetaFields, this.showLatestPackage);
		const count_obs = this.cubeService.datastoreApiCount(ds.id);
		const psa_info_obs = forkJoin(query_obs, count_obs);

		return psa_info_obs;
	}

	createPsaDataModel(res: dss.DataTypePreview) {
		let data = [];
		for (let i = 0; i < res.Rows.length; i++) {
			let row = [{}];
			for (let j = 0; j < res.Columns.length; j++) {
				row[res.Columns[j]["Name"]] = res.Rows[i][j];
			}
			data.push(row);
		}

		return data;
	}

	onTablePageChanged(event: TableEvents.OnPageChanged<any>) {

		console.log("HALT!");
		console.log(event);

		const first: number = event.first;  			// offset
		const page: number = event.page;				// current page 0-index
		const pageCount: number = event.pageCount;	// all pages
		const rows: number = event.rows;				// rows per page

		// first & rows

		let query_obs: Observable<DataTablePreview> = undefined;
		if (this.selectedDatasource) {
			query_obs = this.bionApi.psaApiQuery(this.selectedDatasource.id, this.rowsPerPage, first,false,this.showLatestPackage,this.showMetaFields); // TODO: neue Flag showMetaFields noch implementieren
		}
		if (this.selectedDataStore) {
			query_obs = this.cubeService.datastoreApiQuery(this.selectedDataStore.id, this.rowsPerPage, first, false, this.showMetaFields, this.showLatestPackage);
		}

		if (query_obs === undefined) return;

		this.loading = true;
		query_obs.subscribe(res => {
			this.columnCount = res.Columns.length;
			this.table.columns = res.Columns;
			this.psaDataFull = this.createPsaDataModel(res);
			this.psaData = this.psaDataFull;
			this.table.value = this.psaDataFull;

		},
			(err: Error) => {
				this.errorService.handleError(err);
			}, () => {
				this.loading = false
			});
	}
	customSort(event: SortEvent) {
		if(!event.data) {
			return
		}
		event.data.sort((data1, data2) => {
			let value1 = data1[event.field];
			let value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1 === 'string' && typeof value2 === 'string')
				result = value1.localeCompare(value2);
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
	}

	onCloseDialog() {
		this.psaData = [];
		this.psaDataFull = [];
		this.cols = []
	}
	displayDeleteDataPackageDialog() {
		if(this.selectedDatasource) {

			this.datasourceService.displayDeleteDataPackageDialog([this.selectedDatasource,true]);
		}
		if(this.selectedDataStore) {

			//this.cubeService.displayDeleteDataStoreDataPackageDialog.emit(true);
			this.cubeService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Delete data package", DestinationDialogActionType.deleteDataPackage, "Delete", this.selectedDataStore));

		}
		this.displayPsa = false;

	}

	onChangeShowMetaFields(event: CheckBoxEvents.OnChange<any> ) {
		const metaFieldsFlag = event.checked;
		this.showMetaFields = metaFieldsFlag;

		if(this.selectedDataStore) {
			this.subs.sink = this.queryDataStore(this.selectedDataStore).subscribe(data_and_count => {
				this.init_data(data_and_count);
				//this.loading = false;
			},(err) => {
				this.errorService.handleError(err)
			},() => { this.loading = false})
		}
	}
	onChangeShowLatestPackage(event: CheckBoxEvents.OnChange<any> ) {
		const latestPackageFlag = event.checked;
		this.showLatestPackage = latestPackageFlag;


		if(this.selectedDataStore) {
			this.subs.sink = this.queryDataStore(this.selectedDataStore).subscribe(data_and_count => {
				this.init_data(data_and_count);
				//this.loading = false;
			},(err) => {
				this.errorService.handleError(err)
			},() => { this.loading = false})
		}

	}

}
