<app-loading-app [appStatus]="appStatus" *ngIf="!currentUserFull"></app-loading-app>

<div class="layout-wrapper" [ngClass]="{
    'layout-sidebar': app.menuMode === 'sidebar',
    'layout-static': app.menuMode === 'sidebar' && sidebarStatic,
    'layout-horizontal': app.menuMode === 'horizontal',
    'layout-rightpanel-active': rightPanelActive,
    'layout-slim': app.menuMode === 'slim',
    'layout-mobile-active': staticMenuMobileActive,
    'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple}"
    [class]="'layout-menu-'+ app.menuTheme+ ' layout-topbar-'+ app.topbarTheme" (click)="onLayoutClick()" *ngIf="currentUserFull">

    <app-topbar></app-topbar>

    <app-rightpanel [rightPanelActive]="rightPanelActive"></app-rightpanel>

    <!-- <app-config></app-config> -->

    <div class="layout-main" >
        <div class="layout-content">

            <router-outlet></router-outlet>
        </div>
        <!-- <app-footer></app-footer> -->
    </div>
    <!-- <div *ngIf="!LoggedIn">
        user not logged In
        <app-login></app-login>
    </div> -->

    <div class="layout-mask modal-in"></div>
</div>

<!-- <div *ngIf="!LoggedIn">
    User is not logged in, something went wrong.
    <button pButton (click)="login()">Login</button>
</div> -->
