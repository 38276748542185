import { EventEmitter } from "@angular/core";
import { MenuItem } from "primeng/api/menuitem";
import { DataSource } from "src/app/models/datasource.model";
import { DatasourcesService } from "src/app/services/datasources.service";
import { UserService } from "src/app/services/user.service";
import { ToolbarProvider } from "./toolbar-providers";

export class DataSourceToolbar implements ToolbarProvider<DataSource> {
    constructor(private datasourcesService: DatasourcesService, private userService: UserService) { }

    subscribeToEmitters(): EventEmitter<DataSource> {
        let selectedObs = this.datasourcesService.selectedDatasourceEmitter;

        return selectedObs
    }
    initObjectMenu(): MenuItem[] {
        return [
            // {
            //     label: "ViewData",
            //     icon: "pi pi-fw pi-table",
            //     command: () => {
            //         this.datasourcesService.displayPsaDialog.emit(true)
            //     }
            // },
            {
                label: "New",
                icon: "pi pi-fw pi-plus",
                styleClass: "p-button p-button-success p-mr-2 p-mb-2",
                command: () => {
                    this.datasourcesService.displayCreateDatasource.emit(true)
                }
            },
        ]
    }
    initActionMenu() {
        return [
            // {
            //     label: "ViewData",
            //     icon: "pi pi-fw pi-table",
            //     disabled: true,
            //     command: () => { this.datasourcesService.displayPsaDialog.emit(true) }
            // },
        ]
    }
}
