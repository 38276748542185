<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div class="p-grid" style="float: left">
            <div class="p-col-3" *ngIf="selectedActivity.start">
                <span style="font: bold" >Time</span>
            </div>
            <div class="p-col-7" *ngIf="selectedActivity.start">
                {{selectedActivity.start}}
            </div>
            <div class="p-col-3" *ngIf="selectedActivity.attribute">
                <span style="font: bold">Attribute</span>
            </div>
            <div class="p-col-7" *ngIf="selectedActivity.attribute">
                {{selectedActivity.attribute}}
            </div>
            <div class="p-col-3" *ngIf="selectedActivity.oldValue">
                <span style="font: bold">Old Value</span>
            </div>
            <div class="p-col-7" *ngIf="selectedActivity.oldValue">
                {{selectedActivity.oldValue}}
            </div>
            <div class="p-col-3" *ngIf="selectedActivity.newValue">
                <span style="font: bold">New Value</span>
            </div>
            <div class="p-col-7" *ngIf="selectedActivity.newValue">
                {{selectedActivity.newValue}}
            </div>
            <div class="p-col-3" *ngIf="selectedActivity.writeMode">
                <span style="font: bold">New Value</span>
            </div>
            <div class="p-col-7" *ngIf="selectedActivity.writeMode">
                {{selectedActivity.writeMode}}
            </div>
            <div class="p-col-3" *ngIf="selectedActivity.recordCount">
                <span style="font: bold">Records</span>
            </div>
            <div class="p-col-7" *ngIf="selectedActivity.recordCount">
                {{selectedActivity.recordCount}}
            </div>
        </div>
    </ng-template>
</p-overlayPanel>

<div style="margin:20px 0">
    <h5>
        {{'Activities' | translate }}<app-info-tooltip></app-info-tooltip>
    </h5>
</div>


<div class="card">
    <!-- <p-scrollPanel [style]="{height: 'calc(27.5rem)'}"> -->
    <p-table #dtc [value]="activities" selectionMode="single" styleClass="p-datatable-customers p-datatable-sm"
        [rows]="11" paginator="false" [filterDelay]="0"
        [globalFilterFields]="['action','objectType','objectId','user','end']">
        <!-- <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'SearchForActivities' | translate}}" />
                </span>

            </div>
        </ng-template> -->
        <ng-template pTemplate="header" let-activity>
            <tr>
                <th  pSortableColumn="end" style="width: 30px;">

                </th>
                <th  pSortableColumn="end" style="width: 40%;">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Date' | translate}}
                        <p-sortIcon field="end"></p-sortIcon>
                    </div>
                </th>
                <th style="width: 30%;" >
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Activity' | translate}}
                        <p-columnFilter type="text" field="action" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <!-- <th style="width: 20%;">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Object' | translate}}
                        <p-columnFilter type="text" field="objectId" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th> -->

                <!-- <th pSortableColumn="role.roleType">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Role Type' | translate}}
                        <p-sortIcon field="role.roleType"></p-sortIcon>

                    </div>
                </th> -->

                <th style="width: 40%;">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'PerformedBy' | translate}}
                        <p-columnFilter type="text" field="user" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>

                <!-- <th style="width: 4rem"></th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-activity>
            <tr (click)="op1.toggle($event)" (click)="onMenuClicked(activity)">
                <td style="width: 30px;">
                        <i class="pi pi-times" *ngIf="activity.action === 'Delete'"
                            style="font-size: 18px; color:rgb(195, 109, 109);margin-right:10px"></i>
                        <i class="pi pi-plus" *ngIf="activity.action === 'Write'"
                            style="font-size: 18px; color:rgb(109, 195, 112); margin-right:10px"></i>
                        <i class="pi pi-refresh" *ngIf="activity.action === 'Update'"
                            style="font-size: 18px; color:rgb(109, 195, 112); margin-right:10px"></i>
                </td>
                <td style="width: 40%;">
                    <span class="p-column-title">{{'Date' | translate}}</span>
                    <div style="display:flex;align-items: center;">
                        {{activity.end | date: 'short'}}
                        <!-- <span style="margin-left:10px"> 
                            <p-chip *ngIf="activity.image === undefined" label="{{activity.user}}"
                            image="assets/layout/images/avatar-placeholder.png">
                        </p-chip>
                        <p-chip *ngIf="activity.image !== undefined" label="{{activity.user}}"
                            [image]="activity.image"></p-chip>
                        </span> -->
                    </div>
                </td>
                <td style="width: 30%;">
                    <span class="p-column-title">{{'Activity' | translate}}</span>
                    <div style="display:flex;align-items: center;">

                        {{activity.action}}
                    </div>
                </td>

                <!-- <td style="width: 30%;">
                    <span class="p-column-title">{{'Activity' | translate}}</span>
                    <div style="display:flex;align-items: center;">
                        {{activity.objectType}} {{activity.objectId}}
                    </div>
                </td> -->
                <td style="width: 40%">
                    <span class="p-column-title">{{'Date' | translate}}</span>
                    <div style="display:flex;align-items: center;">
                        <span style="margin-left:10px"> 
                            <p-chip *ngIf="activity.image === undefined" label="{{activity.user}}"
                            image="assets/layout/images/avatar-placeholder.png">
                        </p-chip>
                        <p-chip *ngIf="activity.image !== undefined" label="{{activity.user}}"
                            [image]="activity.image"></p-chip>
                        </span>
                    </div>
                </td>


            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>

    <!-- <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
            </p-scrollTop>
        </p-scrollPanel> -->
</div>
