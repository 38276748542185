import { Observable, of } from 'rxjs';
import { Arrays } from 'src/app/helper/arrays';
import { RichDataSourceCake } from 'src/app/models/api/controllers/staging/RichDataSourceCake';
import * as dss from 'src/app/models/datasource.model';
import { UserDetailsRow } from 'src/app/models/user.model';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { ActivityEntry, hasImage, LatestActivityProvider } from './latest-activity-provider';
import { ActivityUtils } from './util-functions';

export class DataSourceNewActivity implements LatestActivityProvider<dss.DataSource, RichDataSourceCake.DataSourceView<any>[]> {

    constructor(private datasourcesService: DatasourcesService, private data:RichDataSourceCake.DataSourceView<any>[], private users: UserDetailsRow[], private utilService:UtilFunctionsService) {}

    getData(): Observable<RichDataSourceCake.DataSourceView<any>[]> {
        return of(this.data);
    }

    buildGuI(data: RichDataSourceCake.DataSourceView<any>[], select?:dss.DataSource): ActivityEntry[] {

        const target_data = select ? data.filter(d => d.DataSource.id == select.id) : data;

        const data_packages: dss.DataPackageProtocolEntry[] = [];
        const protocols: dss.DataSourceProtocolEntry[] = Arrays.flatMap(target_data, arg => arg.Protocols.map(p => p.Entry));


        console.log(data_packages, protocols);
        const aggregated_protocols: Array<dss.DataPackageProtocolEntry | dss.DataSourceProtocolEntry> = [...data_packages, ...protocols];

        const sorted_protocols = aggregated_protocols.sort(
            (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
        );

        const latest_protocols = sorted_protocols;

        // function to get user avatar

        const latest_protocols_with_img:ActivityEntry[] = latest_protocols.map((protocol) => {
            return ActivityUtils.extractImage(this.users,protocol, this.utilService)
        });

        return latest_protocols_with_img;
    }

    subscribeToEmitter(): Observable<dss.DataSource> {
        return this.datasourcesService.selectedDatasourceEmitter;
    }
}
