<p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'">
    <ng-template pTemplate>
        <div style="width:600px">
            <div class="p-col-12" *ngIf="toggleView === true">
                <div class="">
                    <div class="card-header">
                        <div class="card-title">
                            <h6>{{'Error Status' | translate}}</h6>
                        </div>
                    </div>
                    <p-table #StatesTable [value]="errorLogResult" [columns]="errorStatesCols"
                        [resizableColumns]="false" [reorderableColumns]="false" [rowHover]="true" [filterDelay]="0"
                        [lazy]="true" [globalFilterFields]="errorStatesCols">

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th [ngStyle]="{'width': col.width}" *ngFor="let col of columns" field="col.field"
                                    style="width: 100px">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData[0]}} </td>
                                <td><span class="p-column-title">{{'Message' | translate}}</span>
                                    <span
                                        [class]="'customer-badge status-' + rowData.Message">{{rowData[1].Message}}</span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">{{'NoEntryFound' | translate}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>

    </ng-template>
</p-overlayPanel>


<div *ngIf="!errorLogResult && toggleView === true && updateSettingsStatus == false && runWorkflowStatus == false ">
    <p-button icon="pi pi-pause" styleClass="p-button-text" [disabled]="true"></p-button>
</div>
<div *ngIf="errorLogResult && toggleView === true  && updateSettingsStatus == false && runWorkflowStatus == false ">
    <p-button icon="pi pi-times" (click)="op.toggle($event)" styleClass="p-button-danger p-button-text"
        *ngIf="errorLogResult.length > 0"></p-button>
    <p-button icon="pi pi-check" (click)="op.toggle($event)" styleClass="p-button-success p-button-text"
        *ngIf="errorLogResult.length === 0"></p-button>

    <!-- <h7 class="centerText">Artifacts</h7>  -->
    <!-- <button style="margin-top: 3px;" pButton (click)="op.toggle($event)" class="p-button-rounded p-button-text p-button-sm">Errors</button> -->
    <!-- <div *ngIf="errorLogResult" class="p-d-flex p-justify-center">
        <button pButton pRipple type="button" style="margin-top: 7px;" *ngIf="errorLogResult.length === 0"
            class="p-button-rounded p-button-success" (click)="op.toggle($event)">{{errorLogResult.length}}
            Errors</button>
        <button pButton pRipple type="button" style="margin-top: 7px;" *ngIf="errorLogResult.length > 0"
            class="p-button-rounded p-button-danger" (click)="op.toggle($event)">{{errorLogResult.length}}
            Errors</button>
    </div> -->
</div>

    <span *ngIf="updateSettingsStatus">
        <p-button icon="pi pi-spin pi-spinner" pTooltip="{{'Updating...' | translate}}" styleClass="p-button-text" [disabled]="true"></p-button>
        <!-- <span style="font-style:italic;">{{'Updating...' | translate}}</span> -->
    </span>
    <span *ngIf="runWorkflowStatus" >
        <p-button icon="pi pi-spin pi-spinner" pTooltip="{{'Workflow running...' | translate}}" styleClass="p-button-text" [disabled]="true"></p-button>
        <!-- <span style="font-style:italic;">{{'Workflow running...' | translate}}</span> -->
    </span>


<!-- <div *ngIf="toggleView === true">
    <p *ngIf="updateSettingsStatus" style="display:inline-block;margin-right:10px"><i class="pi pi-spin pi-spinner"
        style="margin-right: 5px"></i><span style="font-style:italic;">{{'Updating...' | translate}}</span></p>
    <p *ngIf="runWorkflowStatus" style="display:inline-block;margin-right:10px"><i class="pi pi-spin pi-spinner"
        style="margin-right: 5px"></i><span style="font-style:italic;">{{'Workflow running...' | translate}}</span>
    </p>
</div> -->
