import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationController } from "../models/api/controllers/AuthenticationController";
import { UserInfoController } from "../models/api/controllers/UserInfoController";

/**
 * Zeigt an, ob die Keycloak Authentifizierung im Backend erfolgreich war und liefert Keycloak Session Informationen.
 * @param P Profil
 */
export interface IKeycloakSessionInfo<P> {
    /**
     * Zeigt an, ob der User authentifiziert wurde.
     */
    isLoggedIn(): Observable<boolean>;
    /**
     * Liefert das oder die aktuellen Keycloak Nutzerprofile.
     */
    getProfiles(): Observable<Array<P>>;
}

export interface IKeycloakSessionPlain {
    /**
     * Zeigt an, ob der User authentifiziert wurde.
     */
    isLoggedIn(): Observable<boolean>;
    /**
     * Liefert das oder die aktuellen Keycloak Nutzerprofile.
     */
    getProfiles(): Observable<string>;
}

interface Profile {

}

@Injectable({
    providedIn: "root",
})
export class BackendAuthService implements IKeycloakSessionInfo<AuthenticationController.OIDProfileInfo> {

    constructor(private http: HttpClient) { }
    readonly BackEndUrl = environment.API_URL;

    isLoggedIn(): Observable<boolean> {
        return this.getProfiles().pipe(map(profiles => {
            console.log(profiles);
            return profiles.length > 0
        }));
    }
    getProfiles(): Observable<AuthenticationController.OIDProfileInfo[]> {
        console.log("Warning. BE Profiles URL not verified!");
        return this.http.get<Array<AuthenticationController.OIDProfileInfo>>("/auth/profiles", {});
    }
    // getProfiles(): Observable<string> {
    //     console.log("Warning. BE Profiles URL not verified!");
    //     return this.http.get<string>("/profiles", {});
    // }

    getFullUserInfo() : Observable<UserInfoController.FullUserInfo> {
        return this.http.get<UserInfoController.FullUserInfo>(this.BackEndUrl + "/Session/userFullInfo", {});
    }

    fullUserEmitter = new EventEmitter<UserInfoController.FullUserInfo>()
    emitFullUser(full_user) {
        this.fullUserEmitter.emit(full_user);
    }
}