import {
	Component,
	Input,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { FieldInfo } from "src/app/models/api/com/bion/etl/Workflow";

import { TableStats, DataTable, Table } from "src/app/models/designer.models";
// import { FieldInfo } from "src/app/models/workflow.model";

@Component({
	selector: "app-node-stats",
	templateUrl: "./node-stats.component.html",
	styleUrls: ["./node-stats.component.scss"],
})
export class NodeStatsComponent implements OnInit {
	// --- Stats Tables
	tableCols: any[] = [];
	columnCols: any[] = [];
	@Input() tableStats!: TableStats;
	@Input() wfPortResult!: Table;


	chartData: any;
	chartOptions: any;

	responsiveOptions;
	@Input() toggleView: boolean = false;

	constructor() {}
	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.tableStats
		) {
			// this.initChart(
			// 	changes.tableStats.currentValue,

			// );
			this.createTableStats(changes.tableStats.currentValue)
		}
	}

	ngOnInit(): void {
		this.responsiveOptions = [
			{
				breakpoint: "1024px",
				numVisible: 8,
				numScroll: 3,
			},
			{
				breakpoint: "768px",
				numVisible: 5,
				numScroll: 2,
			},
			{
				breakpoint: "560px",
				numVisible: 3,
				numScroll: 1,
			},
		];
		this.columnCols = [
			{ field: "Column Name", header: "Column", width: "40%" },
			{ field: "count", header: "Count", width: "10%" },
			{ field: "mean", header: "Mean", width: "10%" },
			{ field: "median", header: "Median", width: "10%" },
			{ field: "std", header: "Std", width: "10%" },
			{ field: "min", header: "Min", width: "10%" },
			{ field: "max", header: "Max", width: "10%" },
			// { field: "skew", header: "Skew", width: "10%" },
		];
		this.tableCols = [
			{ field: "Column Name", header: "Column", width: "40%" },
			{ field: "Valid", header: "Valid values", width: "20%" },
			{ field: "Missing", header: "Missing values", width: "20%" },
			{ field: "Unique", header: "Unique values", width: "20%" },
		];
		this.createTableStats(this.tableStats)
		this.initChart();
	}

	tableStatsRecords: any[] = [];
	columnStatsRecords: any[]= [];
	columnFieldInfos: FieldInfo[] = [];

	createTableStats(result: TableStats) {

		if (result === undefined) {
			this.resetTableStats();
			return;
		}

		let tableStats: DataTable = result.TableStats;
		let columnStats: DataTable = result.ColumnStats;

		let tableStatsRecords = tableStats.Data;
		let tableStatsFieldsInfo = tableStats.MetaData.FieldsInfo;

		let columnStatsRecords = columnStats.Data;
		let columnStatsFieldsInfo = columnStats.MetaData;
		this.columnFieldInfos = columnStatsFieldsInfo.FieldsInfo;

		//-- Prepare TableStats Record
		const tableData = [];
		for (let i = 0; i < tableStatsRecords.length; i++) {
			let row = {};
			for (let j = 0; j < this.tableCols.length; j++) {
				row[this.tableCols[j]["field"]] = tableStatsRecords[i][j];
			}
			tableData.push(row);
		}

		this.tableStatsRecords = tableData;
		console.log("tableStats",tableStats);

		//-- Prepare ColumnStats Record
		const columnData = [];

		if(columnStatsRecords.length === 0) {
			return;
		}
		for (let i = 0; i < columnStatsRecords.length; i++) {
			let row = {};
			for (let j = 0; j < this.columnCols.length; j++) {
				row[this.columnCols[j]["field"]] = columnStatsRecords[i][j];
			}
			columnData.push(row);
		}

		// Prepare add datatype info to entry

		let columnDataMap = [];
		let portResultData = this.wfPortResult.MetaData.FieldsInfo;
		console.log("columnData",columnData);
		console.log("portResultData",portResultData);


		for (let i = 0; i < columnData.length; i++) {
			let row = {};
			for (let j = 0; j < portResultData.length; j++) {
				if(portResultData[j]['Name'] === columnData[i]['Column Name']) {
					//console.log(i,j);
					//row[portResultData[j]['Name']] = columnData[i]['Column Name'];
					let columnRow = {...columnData[i]};
					columnRow["DataType"] = portResultData[j].DataType.Name;
					row = columnRow;
				}
			}
			columnDataMap.push(row);
		}
		console.log("columnDataMap",columnDataMap);


		this.columnStatsRecords = columnDataMap;

	}
	resetTableStats() {
		this.tableStatsRecords = [];
		this.columnStatsRecords = [];
	}

	initChart(tableStats?: any[], columnStats?: any[]) {
		let data = tableStats;
		let validNumber: number = 0;
		let missingNumber: number = 0;
		let uniqueNumber: number = 0;

		let dataArray: number[] = [];

		if (data !== undefined) {
			data.map((value) => {
				validNumber = parseInt(value["Valid"]) + validNumber;
				missingNumber = parseInt(value["Missing"]) + missingNumber;
				uniqueNumber = parseInt(value["Unique"]) + uniqueNumber;
			});
		}

		dataArray = [validNumber, uniqueNumber, missingNumber];

		let chartData = {
			labels: ["Valid", "Unique", "Missing"],
			datasets: [
				{
					data: dataArray,
					backgroundColor: ["#FF6384", "#36A2EB"],
					hoverBackgroundColor: ["#FF6384", "#36A2EB"],
				},
			],
		};

		let barData = {
			labels: ["Valid", "Unique", "Missing"],
			datasets: [
				{
					type: "bar",
					label: "Amount",
					backgroundColor: [
						"rgb(242, 249, 253)",
						"rgb(205, 234, 250)",
						"rgb(118, 189, 220)",
					],
					data: dataArray,
					borderColor: "white",
					borderWidth: 2,
				},
			],
		};
		this.chartOptions = {
			plugins: {
				legend: {
					display: false,
					labels: {
						fontColor: "#A0A7B5",
					},
				},
			},
			scales: {
				x: {
					display: false,
					grid: {
						color: "transparent",
					},
					ticks: {
						color: "#BFC2C6",
					},
				},
				y: {
					display: false,
					grid: {
						color: "rgba(191, 194, 198, .45)",
						borderDash: [5, 10],
					},
					ticks: {
						color: "#BFC2C6",
						min: 0,
						stepSize: 5,
					},
				},
			},
		};

		this.chartData = barData;

	}
}
