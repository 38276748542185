import { Component, OnInit } from '@angular/core';
import { Id } from 'src/app/helper/id';
import { MetaInfo } from 'src/app/models/api/com/bion/etl/NodeMetaData';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { WorkflowStatusLogEntry, WorkflowLogEntryGui, WorkflowLogEntry, DataArtifactLike, TableStats, DataTable, WorkflowPortBase, Table, DataArtifact, ExecutePlugInResult, ExceptionInfo } from 'src/app/models/designer.models';
import { GuiDropDown } from 'src/app/models/helperClass.model';
// import { MetaInfo, WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { DesignProgresSpinnerEvent, DesignerEvent, DesignerService, WorkflowOperationType } from 'src/app/services/designer.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { PortTableEntry, GenericEntry, DataPreviewEventObject, DataViewPortChangeEvent, PreviewEvents } from '../node-data-preview/node-data-preview.component';
import { mxWorkflowNodeValue, mxWorkflowNodePortData, mxWorkflowPortValue } from '../workflow-graph/mxWorkflowGraph';
import { IWorkflowGraphEventData, NodeCellClickedData, NodeDataChangedData, PortCellClickedData, WorkflowExecutedData, WorkflowGraphEventType } from '../workflow-graph/workflow-graph-events';
import { AppMainComponent } from 'src/app/app.main.component';
import { SubSink } from 'subsink';

@Component({
	selector: 'app-designer-status',
	templateUrl: './designer-status.component.html',
	styleUrls: ['./designer-status.component.scss']
})
export class DesignerStatusComponent implements OnInit {
	subs = new SubSink;
	displayDataPreview: boolean = false;
	loading: boolean = false;
	data: any[][] = [];
	dataFull: any[] = [];
	cols: FieldInfo[] = [];
	totalRecords: number = 0;
	rowsPerPage: number = 100;
	previewModes: GuiDropDown[] = [];
	selectedPreviewMode: GuiDropDown = { name: "DATA", value: "Data" };
	//portOptions: GuiDropDown[];
	//selectedPortOption: GuiDropDown;

	// --- Node Information
	_currentWorkflowNode?: mxWorkflowNodeValue;
	currentNodeID?: string;
	// --- PortsView
	nodePorts: PortTableEntry[] = [];
	multiPortOptions: GenericEntry<mxWorkflowNodePortData>[] = [];
	selectedMultiPortOption?: GenericEntry<mxWorkflowNodePortData> | undefined;
	selectedNodePort: PortTableEntry | undefined;

	tableStats?: TableStats;
	errorLogResult?: Array<[string, ExceptionInfo]>;
	workflowErrorLog : WorkflowLogEntryGui[] = [];
	workflowStatesLog : WorkflowStatusLogEntry[] = [];
	artifacts: DataArtifact[] = [];

    updateSettingsStatus: boolean = false;
	runWorkflowStatus: boolean = false;

	constructor(private designerService: DesignerService, private logService: SystemMessageLogService, public appMain: AppMainComponent
	) { }

	ngOnInit(): void {
		this.subs.sink =
		this.designerService.designerProgressSpinnerEmitter.subscribe(
			(res: DesignProgresSpinnerEvent) => {

				try {
					if (res.workflowOperation == WorkflowOperationType.UpdateSettings) {
						this.updateSettingsStatus = res.inProgress;
					} else {
						this.runWorkflowStatus = res.inProgress;

					}


				} catch (e) {
					// nothing
				} finally {
					// TODO: check what we need to clean up here
				}

				// if (res.workflowOperation == WorkflowOperationType.UpdateSettings) {
				//     this.updateSettingsStatus = res.inProgress;
				//     this.disableRunFullButton = res.inProgress;
				//     this.disableRunPartiallyButton = res.inProgress;
				// } else {
				//     this.savingInProgress = res.inProgress;

				// }

				//this.savingInProgress = res.inProgress;
				//console.log(res)
			}
		);
		this.designerService.workflowGraphEmitter.subscribe(
			(res: DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>) => {
				this.loading = true;
				if (res.Type === WorkflowGraphEventType.NodeCellClicked) {
					try {
						const data = <NodeCellClickedData>res.Data;

						this.currentNodeID = data.Cell.id;
						this._currentWorkflowNode = data.Value;

						const portInfos = data.Value.PortInfos;

						if (portInfos === undefined) {
							console.log("PortInfos Undefined");
							return;
						}
						// TODO: PortInfos ist undefined bzw. wird nicht vom Event mitgegeben.

						this.initGui(portInfos);

						const currentPort = this.selectedNodePort?.Name;
						const currentPreview = this.selectedPreviewMode.value;

						this.onActionClicked(currentPort, currentPreview);
					} catch (e) {
						this.logService.errorEventEmitter.emit(e);
					}
				}
				if (res.Type === WorkflowGraphEventType.NodeDataChanged) {

					try {
						const data = <NodeDataChangedData>res.Data;

						if (this.currentNodeID === undefined) return;

						if (data.NodeID !== this.currentNodeID) return;

						const currentPort = this.selectedNodePort?.Name;
						const currentPreview = this.selectedPreviewMode.value;

						this.nodeResultIntoGui(data.WorkflowNodeValue.Data, currentPort, currentPreview);
					} catch (e) {
						this.logService.errorEventEmitter.emit(e);
					}
				}
				if (res.Type === WorkflowGraphEventType.PortCellClicked) {
					try {
						const data = <PortCellClickedData>res.Data;
						console.log("onPortCellClicked: ", data);

						const nodeID = data.Cell.id;
						const nodeCell = data.ParentCellValue;
						const portValue = data.Value;

						this._currentWorkflowNode = data.ParentCellValue;

						if (!nodeCell.PortInfos) {
							return;
						}
						this.initGui(nodeCell.PortInfos); // TODO: validate, alternative: event.makeWorkflowNode(...)

						// find new port to select
						const port_to_select = this.nodePorts.find(
							(p) => p.Name == portValue.Name
						);
						if (port_to_select !== undefined)
							this.selectedNodePort = port_to_select;

						const currentPort = this.selectedNodePort;
						const currentPreview = this.selectedPreviewMode.value;

						this.onActionClicked(currentPort?.Name, currentPreview);
					} catch (e) {
						this.logService.errorEventEmitter.emit(e);
					}
				}
				// if (res.Type === WorkflowGraphEventType.ManualSelectionCellChanged) {
				// 	try {
				// 		const data = <ManualSelectionCellChangedData>res.Data;
				// 		console.log("ManualSelectionCellChanged: ",data);

				// 		const nodeID = data.oldSelectedCell.getId();
				// 		const nodeCell = <mxWorkflowNodeValue>data.oldSelectedCell.getValue();
				// 		const portValue = <mxWorkflowPortValue> data.newSelectedCell.getValue();

				// 		this._currentWorkflowNode = nodeCell;

				// 		if (!nodeCell.PortInfos) {
				// 			return;
				// 		}
				// 		this.initGui(nodeCell.PortInfos); // TODO: validate, alternative: event.makeWorkflowNode(...)

				// 		// find new port to select
				// 		const port_to_select = this.nodePorts.find(
				// 			(p) => p.Name == portValue.Name
				// 		);
				// 		if (port_to_select !== undefined)
				// 			this.selectedNodePort = port_to_select;

				// 		const currentPort = this.selectedNodePort;
				// 		const currentPreview = this.selectedPreviewMode.value;

				// 		this.onActionClicked(currentPort.Name, currentPreview);
				// 	} catch (e) {
				// 		this.logService.errorEventEmitter.emit(e);
				// 	}
				// }
				if (res.Type === WorkflowGraphEventType.WorkflowExecuted) {
					const data = <WorkflowExecutedData>res.Data;
					const result = data.Result;

					let nodeStates = result.NodeStates;
					const node_states_map = new Map<string, string>();
					nodeStates.forEach((state) => {
						node_states_map.set(state[0], state[1]);
					});
					let logResult = result.Log;
					let errorLogResult = result.Errors;
					let nodeDataResultMap = result.OutNodeData;
					this.errorLogResult = errorLogResult;

					// -- Create Log Data
					if (logResult.length > 0) {
						this.workflowErrorLog = this.createLogTable(logResult);
					}

					if (node_states_map.size > 0) {
						this.workflowStatesLog = this.createStatusLogTable(node_states_map);
					}

					// Check workflow if artefacts are included
					let artifactsMap = this.extractArtefactFromWorkflowResult(nodeDataResultMap);
					console.log("artifactsMap", artifactsMap);
					this.artifactsMap = artifactsMap.filter((arti) => { return arti[1].length > 0 });
					//this.artifactsMap = artifactsMap;


					// Write found artefacts to variable


					// if (nodeDataResultMap.size > 0) {

					// 	if (this._currentWorkflowNode === undefined) return;
					// 	// -- Get necessary information (Selected Node, selected Port)
					// 	let currentNodePort = this.selectedNodePort;

					// 	let currentNode = this._currentWorkflowNode.Name;

					// 	// -- Check for undefined node and port -> exit
					// 	if (currentNode === undefined) return;
					// 	if (currentNodePort === undefined) return;

					// 	// -- Check if Results exists, if not exit
					// 	if (!nodeDataResultMap.has(currentNode)) return;

					// 	let currentNodeResult = nodeDataResultMap.get(currentNode);

					// 	if (!currentNodeResult.PortResults.has(currentNodePort.Name)) return;

					// 	let artifacts = currentNodeResult.Artifacts;
					// 	this.artifacts = this.createArtefacts(artifacts);
					// }
					// Workflow Executed -> Jump to first output port! (if avaialble)

					// -- get selected cell
					// -- get out port

					if (this._currentWorkflowNode === undefined) return;
					const in_port = this._currentWorkflowNode.PortInfos.find(p => !p.IsInput);

					if (in_port === undefined) return;

					const node_in_port = this.nodePorts.find(p => p.Name == in_port.Name);
					
					const node_in_port_given = Id.assertSet(node_in_port, new Error("Node in Port not found, should not happen"));

					// -- display!
					//const currentPreview = this.selectedPreviewMode.value;
					//this.onActionClicked(in_port.Name, currentPreview);
					this.selectedNodePort = node_in_port_given;
					this.change_port(node_in_port_given.Name);

					// -- Create Table Stats Data
					if (nodeDataResultMap.size == 0) return;


					// -- Get necessary information (Selected Node, selected Port)
					let currentNodePort = this.selectedNodePort;
					let currentNode = this._currentWorkflowNode.Name;



					// -- Check for undefined node and port -> exit
					if (currentNode === undefined) return;
					if (currentNodePort === undefined) return;

					// -- Check if Results exists, if not exit
					if (!nodeDataResultMap.has(currentNode)) return;

					let currentNodeResult = nodeDataResultMap.get(currentNode);

					if (!currentNodeResult?.PortResults.has(currentNodePort.Name)) return;

					// let currentNodePortResults = currentNodeResult.PortResults.get(
					// 	currentNodePort.Name
					// );
					// let artifacts = currentNodeResult.Artifacts;
					// this.artifacts = this.createArtefacts(artifacts);



					// let stats = currentNodePortResults.Tables[0].Stats; // ---> TODO: multi-port to be included

					// if (stats === undefined) return;

					// //this.createTableStats(stats);
				}
			}
		);
	}

	artifactsMap: Array<[string, DataArtifact[]]> = [];
	/**
	 * Returns the artifacts for each node.
	 * @param nodeData Workflow Execution Node Results
	 * @returns Artifacts keyed by Node ID.
	 */
	extractArtefactFromWorkflowResult(nodeData: Map<string, ExecutePlugInResult<WorkflowNodeSettings>>): Array<[string, DataArtifact[]]> {

		const artifacts = new Array<[string, DataArtifact[]]>();

		for (let node of nodeData.keys()) {
			const result = nodeData.get(node);
			if (result)
				artifacts.push([node, result.Artifacts]);
			else
				artifacts.push([node, []]);
		}

		return artifacts;
	}

	/**
	 * Draw the new port menu
	 */
	initGui(node_ports: WorkflowPortBase[]) {
		// -- reset the view
		this.resetView();
		// -- create menu object for gui
		let node_ports_ex = <mxWorkflowPortValue[]>node_ports;

		let portsMapArray = new Array<PortTableEntry>();

		if (node_ports_ex === undefined) return;
		// -- Build Port Data View
		for (let node_port of node_ports_ex) {

			const new_meta_info = new MetaInfo(new Array<FieldInfo>());
			const empty_table = new DataTable(new_meta_info, new Array<Array<any>>());
			const port_entry = new PortTableEntry(node_port.Name, empty_table);

			portsMapArray.push(port_entry);
		}

		this.nodePorts = portsMapArray;

		if (this.nodePorts.length > 0) {
			this.selectedNodePort = this.nodePorts[0];
		}
	}

	resetView() {
		this.data = [];
		this.cols = [];
		//this.globalFilterArray = [];
		this.selectedNodePort = undefined;
		this.nodePorts = [];
		this.multiPortOptions = [];
		this.selectedMultiPortOption = undefined;
	}


	onActionClicked(
		currentPort?: string,
		currentPreview?: string,
		currentEdge?: string
	) {
		// IF there is no selected node => QUiT!
		if (this._currentWorkflowNode === undefined) {
			return;
		}

		try {
			let nodeValue = <mxWorkflowNodeValue>this._currentWorkflowNode;

			let nodeValueData = nodeValue.Data;

			this.nodeResultIntoGui(
				nodeValueData,
				currentPort,
				currentPreview,
				currentEdge
			);
		} finally {
			//this.designerService.emitLoadingEvent(false);
		}
	}

	/**
	 * Extract Port Options from a Port Result
	 * @param portData Node Port Data
	 * @returns
	 */
	extractPortOptions(portData: mxWorkflowNodePortData[]): GenericEntry<mxWorkflowNodePortData>[] {
		const multi_port_options = portData.map((result) => {

			const edge_label = result.Table.MetaData.EdgeLabel ? result.Table.MetaData.EdgeLabel : "";

			const newEntry = new GenericEntry<mxWorkflowNodePortData>(
				edge_label,
				result
			);
			return newEntry;
		});
		return multi_port_options;
	}

	nodeResultIntoGui(
		node_result?: Map<string, mxWorkflowNodePortData[]>,
		currentPort?: string,
		currentPreview?: string,
		currentEdge?: string
	): void {
		if (node_result === undefined) {
			console.log("No PortInfos available yet, exit!");
			return;
		}

		if (currentPort === undefined) {
			this.totalRecords = 0;
			this.cols = [];
			this.data = [];
			return;
		}

		// TODO: Check case: Union Input
		let current_port_results = node_result.get(currentPort);

		if (current_port_results === undefined) {
			this.totalRecords = 0;
			this.cols = [];
			this.data = [];
			return;
		}

		const is_multi_port_result = current_port_results.length > 1;
		const port_options = this.extractPortOptions(current_port_results);

		if (is_multi_port_result) {

			this.multiPortOptions = port_options;

			if (this.selectedMultiPortOption === undefined) {
				this.selectedMultiPortOption = port_options[0];
			}

			if (currentEdge !== undefined) {

				const opt_result = port_options.find(o => o.Name == currentEdge);
				if (opt_result) {
					this.selectedMultiPortOption = opt_result;
				} else {
					console.log("No Port Options available for the selected edge " + currentEdge + ". Selecting first!");
					this.selectedMultiPortOption = port_options[0];
				}

				// let edge_index = currentEdge.substring(1);
				// let edge_index_value = parseInt(edge_index);
				// this.selectedMultiPortOption = multi_port_options[edge_index_value];
			}
		} else {
			this.multiPortOptions = port_options;
			this.selectedMultiPortOption = port_options[0];
		}

		// const selected_port_data = node_result.get(currentPort)[0];
		const selected_port_data = this.selectedMultiPortOption;
		//console.log("Selected Port", selected_port_data);

		if (selected_port_data === undefined) {
			console.log("WARNING: No Selected Port Data found");
			return;
		}

		this.loadRecordsIntoView(selected_port_data.Value.Table, currentPreview);

		if (selected_port_data.Value.TableStats !== undefined) {
			this.tableStats = selected_port_data.Value.TableStats;
		} else {
			//TODO: validate!
			this.tableStats = undefined;
		}
	}


	loadRecordsIntoView(table: Table, currentPreview?: string) {

		if (currentPreview === undefined) {
			return;
		}

		let recordData = table.Data;
		let recordMeta = table.MetaData.FieldsInfo;

		let newCols = [];
		let dataFull: any[][] = [];
		let data: any[][] = [];
		let totalRecords: number;

		//-- Check current view
		if (currentPreview === "Data") {

			// == Performance Optimized Mode (experimental)
			const data_result = this.tableToPrimeNgIndex(table);

			newCols = data_result[0];
			dataFull = data_result[1];
			data = data_result[1].slice(0, 100);
			totalRecords = data_result[1].length;
		} else {

			// -- Flatten MetaData Records to fit table
			let flatMetaData: any[] = [];
			recordMeta.forEach((element) => {
				let dsFieldName = { Field: element["Name"] };
				let flatRecordMeta = Object.assign(
					{},
					...(function _flatten(o) {
						return [].concat(
							...Object.keys(o).map((k) =>
								typeof o[k] === "object" ? _flatten(o[k]) : { [k]: o[k] }
							)
						);
					})(element)
				);
				let flatAgg = { ...dsFieldName, ...flatRecordMeta };
				flatMetaData.push(flatAgg);
			});

			dataFull = flatMetaData;
			data = flatMetaData.slice(0, 100);
			totalRecords = flatMetaData.length;

			// -- create new columns and push
			let cols = [];
			cols.push(
				{ field: "Field", header: "Field" },
				{ field: "Name", header: "Datatype" },
				{ field: "Length", header: "Length" }
			);
			newCols = cols;
		}

		this.cols = newCols;			// write col data to UI

		this.dataFull = dataFull;
		this.data = data;
		this.totalRecords = totalRecords;



	}

	/**
 * Converts a table to a prime ng table data and col format indexed by their column index
 * @param table Table
 * @returns Column and Data indexed by position index
 */
	tableToPrimeNgIndex(table: Table): [any[], any[][]] {

		const field_infos = table.MetaData.FieldsInfo;
		const cols = [];

		for (let i = 0; i < table.MetaData.FieldsInfo.length; i++) {
			// const head_entry = { field: i, header: field_infos[i].Name }
			const head_entry = { field: i, header: field_infos[i].Name, dataType: field_infos[i].DataType.Name }
			cols.push(head_entry);
		}

		// todo convert date string

		let result: [Array<any>, Array<Array<any>>]

		result = [cols, table.Data]

		return result;
	}


	protected change_port(port: string) {

		let currentPort: string;
		let currentPreview: string;
		let currentEdge: string | undefined = undefined;

		currentPort = port;

		if (this.selectedMultiPortOption) {
			currentEdge = this.selectedMultiPortOption.Value.Table.MetaData.EdgeLabel;
		}

		currentPreview = this.selectedPreviewMode.value;

		this.onActionClicked(currentPort, currentPreview, currentEdge);

		let nodeValue = <mxWorkflowNodeValue>this._currentWorkflowNode;

		let portChangedEvent = new DataPreviewEventObject();
		portChangedEvent.Type = PreviewEvents.PortSelected;

		let portData = new DataViewPortChangeEvent();
		portData.Node = nodeValue;
		portData.Port = currentPort;
		portChangedEvent.Data = portData;

		this.designerService.previewEventsEmitter.emit(portChangedEvent);
		console.log("portChangedEvent", portChangedEvent);
	}

	createStatusLogTable(statusLog: Map<string, string>) {
		let newArray: WorkflowStatusLogEntry[] = [];

		statusLog.forEach((value: string, key: string) => {
			newArray.push(new WorkflowStatusLogEntry(key, value));
		});

		return newArray;
	}

	createLogTable(log: WorkflowLogEntry[]): WorkflowLogEntryGui[] {
		// -- check for error entries & get respective Error Message
		let logResultextend: WorkflowLogEntryGui[] = [];

		log.forEach((entry: WorkflowLogEntry) => {
			let newEntry = new WorkflowLogEntryGui();
			newEntry.NodeID = entry.NodeID;
			newEntry.Message = entry.Message;
			newEntry.DateTime = entry.DateTime;
			newEntry.Language = entry.Language;
			newEntry.Level = entry.Level;

			logResultextend.push(newEntry);
		});

		return logResultextend;
	}

	createArtefacts(artifacts: DataArtifactLike[]) {
		console.log("artifacts", artifacts);
		return artifacts
	}

}
