import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { Table } from "primeng/table";
import { Customer } from "src/app/demo/domain/customer";
import { WorkflowNodeGuiInfo } from "src/app/models/designer.models";
import { DesignerService } from "src/app/services/designer.service";

@Component({
	selector: "app-nodes-list",
	templateUrl: "./nodes-list.component.html",
	styleUrls: ["./nodes-list.component.scss"],
})
export class NodesListComponent implements OnInit {
	displayNodeInfo: boolean = false;
	@Input() plugInInfos: WorkflowNodeGuiInfo[] = [];
	selectedNode?: WorkflowNodeGuiInfo;

	rowGroupMetadata: any;
	items: MenuItem[] = [];
    layout: string = 'grid';

	//@ViewChild("dt") table!: Table;
	@ViewChild("dt") table!: ElementRef;
	@ViewChild("plugInItem") plugInItem!: ElementRef;

	constructor(private designerService: DesignerService, private translate: TranslateService) { }

	ngOnInit() {
		this.items = [
			{
				label: this.translate.instant('Show node info'),
				icon: 'pi pi-fw pi-info-circle',
				disabled: false,
				command: () => {this.displayNodeInfo = true}
			}
		];
	}

	setSelectedNode(node: WorkflowNodeGuiInfo) {
		this.selectedNode = node;
		this.displayNodeInfo = true;
	}
	resetCurrentForm() {
		this.selectedNode = undefined;
	}

	onSort() {
		this.updateRowGroupMetaData();
	}
	updateRowGroupMetaData() {
		this.rowGroupMetadata = {};
	}

	findIndex(product: Customer) {
		let index = -1;
		for (let i = 0; i < this.plugInInfos.length; i++) {
			if (product.name === this.plugInInfos[i].Name) {
				index = i;
				break;
			}
		}
		return index;
	}

	// RIGHT CLICK
	onRightClick(event: MouseEvent, plugIn: WorkflowNodeGuiInfo) {
		// Handle the right-click event here
		event.preventDefault(); // Prevent the default browser context menu
		// Add your custom logic or actions
		this.selectedNode = plugIn;
	  }

	// DRAG & DROP
	draggedNodeGuiInfo?: WorkflowNodeGuiInfo = undefined;

	dragStart(event: DragEvent, product: WorkflowNodeGuiInfo) {


		this.draggedNodeGuiInfo = product;
		console.log(product);
		this.designerService.draggedNodeEmitter.emit(product);
	}


	dragEnd(event: DragEvent) {
		//this.draggedProduct = null;

		this.draggedNodeGuiInfo = undefined;
	}

	onRequestNode() {
		const subject = this.translate.instant("I want to request a new node");
		const bodyText = this.translate.instant("Hi Bion support, i want to request a new node. Here are my requirements:");
		const mailText = "mailto:support@bion-analytics.com?subject="+subject+"&body="+bodyText; // add the links to body
		window.location.href = mailText;
	}
}
