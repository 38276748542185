import { Observable, of, throwError } from "rxjs";
import { ObjectProtocolRow } from "src/app/models/datasource.model";
import { UserDetailsRow } from "src/app/models/user.model";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { ActivityEntry, LatestActivityProvider } from "./latest-activity-provider";
import { ActivityUtils } from "./util-functions";

export class DashboardActivity implements LatestActivityProvider<ObjectProtocolRow, ObjectProtocolRow[]> {

    constructor(private data:ObjectProtocolRow[], private users: UserDetailsRow[], private utilService:UtilFunctionsService) {}


    getData(): Observable<ObjectProtocolRow[]> {
        return of(this.data);
    }
    buildGuI(data: ObjectProtocolRow[], select?: ObjectProtocolRow): ActivityEntry[] {
        //const target_data = select ? data.filter(d => d.DataSource.id == select.id) : data;

        // const data_packages: dss.DataPackageProtocolEntry[] = [];
        // const protocols: dss.DataSourceProtocolEntry[] = Arrays.flatMap(target_data, arg => arg.Protocols.map(p => p.Entry));


        // console.log(data_packages, protocols);
        // const aggregated_protocols: Array<dss.DataPackageProtocolEntry | dss.DataSourceProtocolEntry> = [...data_packages, ...protocols];

        const sorted_protocols = data.sort(
            (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
        );

        //const latest_protocols = sorted_protocols;

        // function to get user avatar

        const latest_protocols_with_img:ActivityEntry[] = sorted_protocols.map((protocol) => {
            return ActivityUtils.extractImage(this.users,protocol, this.utilService)
        });

        return latest_protocols_with_img;
    }
    subscribeToEmitter(): Observable<any> {
        return of(undefined);

    } 

}