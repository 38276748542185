
 
<div class="p-grid" style="margin:0 0">
    <div class="p-col-6" style="font-weight:bold; padding-left: 0;">
        {{'Choose Datasource' |translate }}<app-info-tooltip></app-info-tooltip>
    </div>

    <div class="p-fluid p-col">
        <span class="p-float-label">
            <p-dropdown id="datasourceName" appendTo="body" #dsSelection [options]="currentConfig.DataSources"
                [(ngModel)]="currentConfig.SelectedDataSource" [autoDisplayFirst]="false"
                (onChange)="onUpdateSettings(true)" [filter]="true" filterBy="Name" optionLabel="Name"></p-dropdown>
            <label for="datasourceName">{{'Selected Datasource' |translate }}</label>
            <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Create' | translate }}"
            class="p-button p-mr-2 p-mb-2" pTooltip="{{'CreateNewDatasource' | translate }}" tooltipPosition="bottom"
            (click)="displayCreateDatasourceDialog(true)"></button>
        </span>
    </div>
</div>

<div class="p-grid" style="margin:0 0">
    <div class="p-col-6" style="padding-left: 0;">
        {{'Do you only want to show the latest data package?' |translate }}<app-info-tooltip></app-info-tooltip>
    </div>
    <div class="p-fluid p-col-6" style="text-align: right">
        <p-checkbox name="group1" [binary]="true" (onChange)="onCheckLastPackageOnly($event)"
        [(ngModel)]="currentConfig.QuerySettings.LastPackageOnly" inputId="ny2"></p-checkbox>
    </div>
</div>
<div class="p-grid" style="margin:0 0" *ngIf=" currentConfig.DataSourceInfo && currentConfig.DataSourceInfo.Psa.WriteMode === 'Delta' ">
    <div class="p-col-6" style="padding-left: 0;">
        {{'Do you only want to show the latest data?' |translate }}<app-info-tooltip></app-info-tooltip>
    </div>
    <div class="p-fluid p-col-6" style="text-align: right">
        <p-checkbox name="group1" [binary]="true" (onChange)="onChangeUseOnlyLatestDelta($event)"
        [(ngModel)]="currentConfig.UseOnlyLatestDelta" inputId="ny"></p-checkbox>
    </div>
</div>
<div class="p-grid" style="margin:0 0">
    <div class="p-col-6" style="padding-left: 0;">
        {{'Do you want to include meta data?' |translate }}<app-info-tooltip></app-info-tooltip>
    </div>
    <div class="p-fluid p-col-6" style="text-align: right">
        <p-checkbox name="group1" [binary]="true" (onChange)="onCheckIncludeMetaFields($event)"
        [(ngModel)]="currentConfig.QuerySettings.IncludeMetaFields" inputId="ny3"></p-checkbox>
    </div>
</div>


<!-- 
<div class="p-grid">
    <div class="p-col-6 headerSection">
        <div class="p-fluid p-inputgroup">
            <span class="p-float-label">
                <p-dropdown id="datasourceName" #dsSelection [options]="currentConfig.DataSources"
                    [(ngModel)]="currentConfig.SelectedDataSource" [autoDisplayFirst]="false"
                    (onChange)="onUpdateSettings(true)" [filter]="true" filterBy="Name" optionLabel="Name"></p-dropdown>
                    <button type="button" pButton icon="pi pi-times" styleClass="p-button-danger"></button>

                <label for="datasourceName">{{'Choose Datasource' |translate }}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 headerSection">
        <button style="float: right" pButton pRipple type="button" icon="pi pi-plus" label="{{'Create' | translate }}"
            class="p-button p-mr-2 p-mb-2" pTooltip="{{'CreateNewDatasource' | translate }}"
            (click)="displayCreateDatasourceDialog(true)"></button>
    </div>
    <div class="p-col headerSection" *ngIf=" currentConfig.DataSourceInfo && currentConfig.DataSourceInfo.Psa.WriteMode === 'Delta' ">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" (onChange)="onChangeUseOnlyLatestDelta($event)"
                [(ngModel)]="currentConfig.UseOnlyLatestDelta" inputId="ny"></p-checkbox>
            <label for="ny">{{'Latest Data Only' |translate }}</label>
        </div>
    </div>
    <div class="p-col headerSection">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" (onChange)="onCheckLastPackageOnly($event)"
                [(ngModel)]="currentConfig.QuerySettings.LastPackageOnly" inputId="ny2"></p-checkbox>
            <label for="ny">{{'Last Package Only' |translate }}</label>
        </div>
    </div>
    <div class="p-col headerSection">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" (onChange)="onCheckIncludeMetaFields($event)"
                [(ngModel)]="currentConfig.QuerySettings.IncludeMetaFields" inputId="ny3"></p-checkbox>
            <label for="ny">{{'Include Meta Fields' |translate }}</label>
        </div>
    </div>
</div> -->
