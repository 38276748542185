export class UserLicStripeRow {
    id: number;
    customer: string;
    constructor(id: number, customer: string) {
        if (id === undefined) throw new Error("Class 'UserLicStripeRow': Required value 'id' is undefined");
        if (customer === undefined) throw new Error("Class 'UserLicStripeRow': Required value 'customer' is undefined");
        this.id = id;
        this.customer = customer;
    }
}

export class ViewInfoRow {
    name: string;
    description: string;
    label: string;
    constructor(name: string, description: string, label: string) {
        if (name === undefined) throw new Error("Class 'ViewInfo': Required value 'name' is undefined");
        if (description === undefined) throw new Error("Class 'ViewInfo': Required value 'description' is undefined");
        if (label === undefined) throw new Error("Class 'ViewInfo': Required value 'label' is undefined");
        this.name = name;
        this.description = description;
        this.label = label;
    }
}

export class UserRow {
    id: number;
    userName: string;
    passWord: string;
    eMail: string;
    isAdmin: boolean;
    firstName?: string;
    lastName?: string;
    avatar?: Array<number>;
    constructor(id: number,userName: string,passWord: string,eMail: string,isAdmin: boolean,firstName?: string,lastName?: string,avatar?: Array<number>) {
        if(id === undefined) throw new Error( "Class 'UserRow': Required value 'id' is undefined" );
        if(userName === undefined) throw new Error( "Class 'UserRow': Required value 'userName' is undefined" );
        if(passWord === undefined) throw new Error( "Class 'UserRow': Required value 'passWord' is undefined" );
        if(eMail === undefined) throw new Error( "Class 'UserRow': Required value 'eMail' is undefined" );
        if(isAdmin === undefined) throw new Error( "Class 'UserRow': Required value 'isAdmin' is undefined" );
        this.id = id;
        this.userName = userName;
        this.passWord = passWord;
        this.eMail = eMail;
        this.isAdmin = isAdmin;
        this.firstName = firstName;
        this.lastName = lastName;
        this.avatar = avatar;
    }
}

export class RoleMembershipRow {
    childRole: number;
    parentRole: number;
    constructor(childRole: number, parentRole: number) {
        if (childRole === undefined) throw new Error("Class 'RoleMembershipRow': Required value 'childRole' is undefined");
        if (parentRole === undefined) throw new Error("Class 'RoleMembershipRow': Required value 'parentRole' is undefined");
        this.childRole = childRole;
        this.parentRole = parentRole;
    }
}

export class DestinationRow {
    id: number;
    name: string;
    description?: string;
    stream: string;
    targetWriteMode: string;
    entityWriteMode: string;
    recordsWriteMode: string;
    constructor(id: number,name: string,stream: string,targetWriteMode: string,entityWriteMode: string,recordsWriteMode: string,description?: string) {
        if(id === undefined) throw new Error( "Class 'DestinationRow': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'DestinationRow': Required value 'name' is undefined" );
        if(stream === undefined) throw new Error( "Class 'DestinationRow': Required value 'stream' is undefined" );
        if(targetWriteMode === undefined) throw new Error( "Class 'DestinationRow': Required value 'targetWriteMode' is undefined" );
        if(entityWriteMode === undefined) throw new Error( "Class 'DestinationRow': Required value 'entityWriteMode' is undefined" );
        if(recordsWriteMode === undefined) throw new Error( "Class 'DestinationRow': Required value 'recordsWriteMode' is undefined" );
        this.id = id;
        this.name = name;
        this.description = description;
        this.stream = stream;
        this.targetWriteMode = targetWriteMode;
        this.entityWriteMode = entityWriteMode;
        this.recordsWriteMode = recordsWriteMode;
    }
}

export class DestinationFieldRow {
    id: number;
    name: string;
    destination: number;
    position: number;
    dataType: string;
    length: number;
    format: string;
    isFixedSize: boolean;
    precision: number;
    isKey: boolean;
    constructor(id: number,name: string,destination: number,position: number,dataType: string,length: number,format: string,isFixedSize: boolean,precision: number,isKey: boolean) {
        if(id === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'name' is undefined" );
        if(destination === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'destination' is undefined" );
        if(position === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'position' is undefined" );
        if(dataType === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'dataType' is undefined" );
        if(length === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'length' is undefined" );
        if(format === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'format' is undefined" );
        if(isFixedSize === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'isFixedSize' is undefined" );
        if(precision === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'precision' is undefined" );
        if(isKey === undefined) throw new Error( "Class 'DestinationFieldRow': Required value 'isKey' is undefined" );
        this.id = id;
        this.name = name;
        this.destination = destination;
        this.position = position;
        this.dataType = dataType;
        this.length = length;
        this.format = format;
        this.isFixedSize = isFixedSize;
        this.precision = precision;
        this.isKey = isKey;
    }
}

export class DestinationConnectorRow {
    destination: number;
    connector: number;
    settings: string;
    constructor(destination: number,connector: number,settings: string) {
        if(destination === undefined) throw new Error( "Class 'DestinationConnectorRow': Required value 'destination' is undefined" );
        if(connector === undefined) throw new Error( "Class 'DestinationConnectorRow': Required value 'connector' is undefined" );
        if(settings === undefined) throw new Error( "Class 'DestinationConnectorRow': Required value 'settings' is undefined" );
        this.destination = destination;
        this.connector = connector;
        this.settings = settings;
    }
}

export class ScheduleActionPlanRow {
    id: number;
    name: string;
    description?: string;
    isActive: boolean;
    constructor(id: number,name: string,isActive: boolean,description?: string) {
        if(id === undefined) throw new Error( "Class 'ScheduleActionPlanRow': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'ScheduleActionPlanRow': Required value 'name' is undefined" );
        if(isActive === undefined) throw new Error( "Class 'ScheduleActionPlanRow': Required value 'isActive' is undefined" );
        this.id = id;
        this.name = name;
        this.description = description;
        this.isActive = isActive;
    }
}

export class DataSourceRow {
    id: number;
    name: string;
    sourceSystem: number;
    age: boolean;
    usePsa: boolean;
    description?: string;
    constructor(id: number,name: string,sourceSystem: number,age: boolean,usePsa: boolean,description?: string) {
        if(id === undefined) throw new Error( "Class 'DataSourceRow': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'DataSourceRow': Required value 'name' is undefined" );
        if(sourceSystem === undefined) throw new Error( "Class 'DataSourceRow': Required value 'sourceSystem' is undefined" );
        if(age === undefined) throw new Error( "Class 'DataSourceRow': Required value 'age' is undefined" );
        if(usePsa === undefined) throw new Error( "Class 'DataSourceRow': Required value 'usePsa' is undefined" );
        this.id = id;
        this.name = name;
        this.sourceSystem = sourceSystem;
        this.age = age;
        this.usePsa = usePsa;
        this.description = description;
    }
}
export class DataSourceFieldRow {
    id: number;
    name: string;
    dataSource: number;
    position: number;
    dataType: string;
    length: number;
    format: string;
    isFixedSize: boolean;
    precision: number;
    isKey: boolean;
    constructor(id: number,name: string,dataSource: number,position: number,dataType: string,length: number,format: string,isFixedSize: boolean,precision: number,isKey: boolean) {
        if(id === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'name' is undefined" );
        if(dataSource === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'dataSource' is undefined" );
        if(position === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'position' is undefined" );
        if(dataType === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'dataType' is undefined" );
        if(length === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'length' is undefined" );
        if(format === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'format' is undefined" );
        if(isFixedSize === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'isFixedSize' is undefined" );
        if(precision === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'precision' is undefined" );
        if(isKey === undefined) throw new Error( "Class 'DataSourceFieldRow': Required value 'isKey' is undefined" );
        this.id = id;
        this.name = name;
        this.dataSource = dataSource;
        this.position = position;
        this.dataType = dataType;
        this.length = length;
        this.format = format;
        this.isFixedSize = isFixedSize;
        this.precision = precision;
        this.isKey = isKey;
    }
}

export class DataStoreRow {
    id: number;
    name: string;
    type: string;
    table: string;
    writeMode: string;
    description?: string;
    constructor(id: number,name: string,type: string,table: string,writeMode: string,description?: string) {
        if(id === undefined) throw new Error( "Class 'DataStoreRow': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'DataStoreRow': Required value 'name' is undefined" );
        if(type === undefined) throw new Error( "Class 'DataStoreRow': Required value 'type' is undefined" );
        if(table === undefined) throw new Error( "Class 'DataStoreRow': Required value 'table' is undefined" );
        if(writeMode === undefined) throw new Error( "Class 'DataStoreRow': Required value 'writeMode' is undefined" );
        this.id = id;
        this.name = name;
        this.type = type;
        this.table = table;
        this.writeMode = writeMode;
        this.description = description;
    }
}

export class DataStoreFieldRow {
    id: number;
    name: string;
    dataStore: number;
    position: number;
    dataType: string;
    length: number;
    format?: string;
    isFixed: boolean;
    precision?: number;
    isKey: boolean;
    physicalName: string;
    constructor(id: number,name: string,dataStore: number,position: number,dataType: string,length: number,isFixed: boolean,isKey: boolean,physicalName: string,format?: string,precision?: number) {
        if(id === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'name' is undefined" );
        if(dataStore === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'dataStore' is undefined" );
        if(position === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'position' is undefined" );
        if(dataType === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'dataType' is undefined" );
        if(length === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'length' is undefined" );
        if(isFixed === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'isFixed' is undefined" );
        if(isKey === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'isKey' is undefined" );
        if(physicalName === undefined) throw new Error( "Class 'DataStoreFieldRow': Required value 'physicalName' is undefined" );
        this.id = id;
        this.name = name;
        this.dataStore = dataStore;
        this.position = position;
        this.dataType = dataType;
        this.length = length;
        this.format = format;
        this.isFixed = isFixed;
        this.precision = precision;
        this.isKey = isKey;
        this.physicalName = physicalName;
    }
}