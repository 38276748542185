import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { ApiBackendService } from "./api-backend.service";
import {
	ExecuteUpdateSettingsArg,
	SettingsResult,
	UpdateWorkflowSettingsResult,
	WorkflowNodeGuiInfo,
	WorkflowNodeInfo,
	WorkflowResult,
} from "./../models/designer.models";
import { Customer } from "../demo/domain/customer";
import { Observable } from "rxjs";
// import {
// 	Workflow,
// 	WorkflowNodeSettings,
// 	MetaInfo,
// 	EngineInfo,
// 	RunWorkflowArg,
// } from "../models/workflow.model";
import { ToolbarCommand } from "../views/designer/components/designer-nav-toolbar/designer-nav-toolbar.component";
import {
	WorkflowGraphEventType,
	IWorkflowGraphEventData,
} from "../views/designer/components/workflow-graph/workflow-graph-events";
import { NodeConfigSettingsChanged } from "../views/designer/components/node-settings/node-config-component-base";
import {
	DataPreviewEventObject,
} from "../views/designer/components/node-data-preview/node-data-preview.component";
// import { JobRequestResult, JobStatusInfo } from "../models/staging/LoadDataModel";
import { EngineInfo } from "../models/api/com/bion/etl/EngineInfo";
import { MetaInfo } from "../models/api/com/bion/etl/NodeMetaData";
import { Workflow, WorkflowNodeSettings } from "../models/api/com/bion/etl/Workflow";
import { RunWorkflowArg } from "../models/api/models/workflow/RunWorkflowArg";
import { TaskJobModel } from "../models/api/models/staging/TaskJobModel";
import { mxCell } from "mxgraph";


export interface IDesignProgresSpinnerEvent {
	inProgress: boolean;
	workflowOperation: WorkflowOperationType;

}
export class DesignProgresSpinnerEvent implements IDesignProgresSpinnerEvent{
	readonly inProgress: boolean;
	readonly workflowOperation: WorkflowOperationType;
	constructor(inProgress: boolean, workflowOperation: WorkflowOperationType) {
		this.inProgress = inProgress;
		this.workflowOperation = workflowOperation;
	}
}
export enum WorkflowOperationType {
	RunWorkflow,
	UpdateSettings,
	RunWorkflowPartially
}

@Injectable({
	providedIn: "root",
})
export class DesignerService {
	@Output() designerProgressSpinnerEmitter = new EventEmitter<IDesignProgresSpinnerEvent>(false);
	@Output() toolBarCommandEmitter = new EventEmitter<ToolbarCommand>();
	@Output() workflowGraphEmitter = new EventEmitter<
		DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>
	>();

	@Output() draggedNodeEmitter = new EventEmitter<WorkflowNodeGuiInfo>();

	@Output() NodeConfigSettingsChangedEmitter = new EventEmitter<
		NodeConfigSettingsChanged<any>
	>();

	@Output() previewEventsEmitter = new EventEmitter<DataPreviewEventObject>();
	@Output() displayConfigEmitter = new EventEmitter<boolean>();
	@Output() isDirtyFlagEmitter = new EventEmitter<boolean>();

	@Output() forceViewToSettingsEmitter = new EventEmitter(false);

	nodePlugIns: Customer[] = [];

    @Output() displayDataPreview = new EventEmitter<[boolean,mxCell]>();

	constructor(public api: ApiBackendService, private http: HttpClient) { }

	getNodePlugIns() {
		return this.http
			.get<any>("assets/data/nodes.json")
			.toPromise()
			.then((res) => res.data as Customer[])
			.then((data) => data);
	}

	public executeWorkflow(workflow: Workflow): Observable<WorkflowResult> {
		return this.api.executeWorkflow(workflow);
	}

	public executeWorkflow_async(arg: Workflow): Observable<TaskJobModel.JobRequestResult> {
		return this.api.executeWorkflow_async(arg);
	}
	public executeWorkflowNew_async(arg: RunWorkflowArg): Observable<TaskJobModel.JobRequestResult> {
		return this.api.executeWorkflowNew_async(arg);
	}
	public executePartialWorkflow_async(arg: RunWorkflowArg): Observable<TaskJobModel.JobRequestResult> {
		return this.api.executePartialWorkflow_async(arg);
	}

	public executePartialWorkflow_status(id: string): Observable<TaskJobModel.JobStatusInfo> {
		return this.api.executeWorkflow_status(id);
	}

	public executePartialWorkflow_result(id: String): Observable<WorkflowResult> {
		return this.api.executeWorkflow_result(id);
	}

	/**
	 * Retrieve the status information of a running Job execution.
	 * @param id Job ID
	 * @returns Job status information
	 */
	public executeWorkflow_status(id: string): Observable<TaskJobModel.JobStatusInfo> {
		return this.api.executeWorkflow_status(id);
	}

	public executeWorkflow_result(id: String): Observable<WorkflowResult> {
		return this.api.executeWorkflow_result(id);
	}



	public executePartialWorkflow(arg: RunWorkflowArg): Observable<WorkflowResult> {
		return this.api.executePartialWorkflow(arg);
	}

	public executeUpdateSettings(
		arg: ExecuteUpdateSettingsArg
	): Observable<UpdateWorkflowSettingsResult> {
		return this.api.executeUpdateSettings(arg);
	}

	/**
	 * Get Initial Node Settings
	 * @param engineInfo Plug In Engine Information
	 * @returns Initial Node Settings
	 */
	public getNodeSettings(
		engineInfo: EngineInfo
	): Observable<SettingsResult<WorkflowNodeSettings, MetaInfo>> {
		return this.api.getNodeSettings(engineInfo);
	}

	public getPlugIns(): Observable<WorkflowNodeInfo[]> {
		return this.api.listPlugIns();
	}
}

/**
 * A Designer Event
 * @type T Event Type
 * @type D Event Data Type
 */
export interface DesignerEvent<T, D> {
	Type: T;
	Data: D;
}
