import { MenuItem } from "primeng/api";
import { Observable, forkJoin, of } from "rxjs";
import { Workflow } from "src/app/models/api/com/bion/etl/Workflow";
import { PsaInputPlugIn } from "src/app/models/api/models/etl/PsaInputPlugIn";
import { WorkflowPermission } from "src/app/models/api/models/workflow/WorkflowPermission";
import { WorkflowProtocolEntry } from "src/app/models/api/models/workflow/WorkflowProtocolEntry";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataStore } from "src/app/models/datastore.model";
import { WorkflowNodeGuiInfo, WorkflowNodeInfo } from "src/app/models/designer.models";
import { NodePlugInInfos } from "src/app/models/nodePlugIn.model";
import { DataStoreOutPlugInSettings } from "src/app/models/nodeSettings.model";
import { ScheduleActionPlan, ScheduleBaseAction, RunWorkflowAction } from "src/app/models/schedule.model";
import { UserDetailsRow } from "src/app/models/user.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { ObjectSearchService } from "src/app/services/object-search.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";
import { DropDownModel } from "../general-search/drop-down-model";
import { FilterProvider } from "../general-search/filter-provider";
import { RecordView, RecordProvider, DropDownEntry, HeaderRow, RecordViewSorter } from "./record-provider";
import * as dss from 'src/app/models/datasource.model';
import { RichtWorkflowCake } from "src/app/models/api/models/workflow/RichWorkflowCake";
import { Id } from "src/app/helper/id";
import { WorkflowActionEvent, WorkflowDialogActionType } from "src/app/models/dialog-actions.model";


export class WorkflowRecordView implements RecordView<WorkflowRepositoryEntry, dss.ObjectProtocolRow> {
  object: WorkflowRepositoryEntry;
  lastUpdated?: dss.ObjectProtocolRow;
  info: Workflow;
  nodes: WorkflowNodeGuiInfo[]= [];
  permissions: WorkflowPermission[]= [];
  schedulePlans: ScheduleActionPlan[]= [];
  datasources: dss.DataSource[]= [];
  datastores: DataStore[] = [];
  loading: boolean = false;
  image?: any;
  shared?:any[];

  constructor(object: WorkflowRepositoryEntry, info: Workflow) {
    this.object = object;
    this.info = info
  }
}

class FilterUtil {
  static filter(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: WorkflowRecordView[]) {
    let intermediateDataSets = objList;

    for (let providerEntry of params[1].keys()) {

      const provider = params[1].get(providerEntry);
      if(provider === undefined) continue;

      // TODO: get all filters from drop downs
      //console.log(params[0].has(providerEntry));
      //console.log("Get Entry");

      const dropDownSelection: DropDownEntry<{}>[] | undefined = params[0].get(providerEntry)?.selection;

      console.log("selection");
      console.log(dropDownSelection);

      if (dropDownSelection === undefined) continue;
      const dropDownValues = dropDownSelection.map(s => s.value);

      if (dropDownValues.length == 0) continue;

      console.log(intermediateDataSets);

      intermediateDataSets = intermediateDataSets.filter(ds => {
        const foundValues = dropDownValues.filter(va => provider.filter(va, ds));
        return foundValues.length > 0
      });
    }
    return intermediateDataSets;
  }
}


export class WorkflowRecord implements RecordProvider<WorkflowRepositoryEntry, WorkflowRecordView, [WorkflowNodeInfo[], WorkflowProtocolEntry[], WorkflowPermission[], ScheduleActionPlan[], ScheduleBaseAction[], dss.DataSource[], DataStore[]], dss.ObjectProtocolRow>{
  constructor(private bionApi: ApiBackendService, private errorService: SystemMessageLogService, private userService: UserService, private utilService: UtilFunctionsService, private workflowsService: WorkflowsService, private objectFilterService: ObjectSearchService) { }
  sortEntries(entries: WorkflowRecordView[]): WorkflowRecordView[] {
    // let sortedProtocols = entries.sort(
    //   (objA, objB) => new Date(objB.lastUpdated?.end).getTime() - new Date(objA.lastUpdated?.end).getTime()
    // );

    // return sortedProtocols;

    let sortedProtocols = entries.sort(
      (objA, objB) => RecordViewSorter.sortLastUpdate(objA,objB)
    );

    return sortedProtocols;
    
  }
  asActions(): MenuItem[] {
    const dialogActions: MenuItem[] = [
      {
        label: 'Change', tooltip: "Workflows.ChangeWorkflowInformation", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-cog", command: () => {
            this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Edit workflow",WorkflowDialogActionType.editWorkflow,"Edit",this.selectedWorkflow,this.selectedWorkflow?.workflowData))
          //this.workflowsService.displayEditWorkflow.emit(true);

        }
      },
      {
        label: 'Delete', tooltip: "Workflows.DeleteWorkflow", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
          //this.workflowsService.displayDeleteWorkflow.emit([this.selectedWorkflow,true]);
          this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Delete workflow",WorkflowDialogActionType.deleteWorkflow,"Delete",this.selectedWorkflow,this.selectedWorkflow?.workflowData))


        }
      },
    ];

    return dialogActions;
  }

  filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: WorkflowRecordView[]) {
    return FilterUtil.filter(params, objList);
  }

  emitObjectAction(action: string, object: WorkflowRecordView) {
    this.workflowsService.selectedMenuWorkflowEmitter.emit(object.object);
  }
  subscribeToEmitter(objList:WorkflowRepositoryEntry[]) {
    this.subs.sink = this.workflowsService.selectedWorkflowEmitter.subscribe((res: WorkflowRepositoryEntry) => {
      this.selectedWorkflow = res;
    },
      (err: Error) => {
        this.errorService.handleError(err);
      });
    this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {

      this.subs.sink = this.getData().subscribe((finalRecordResult) => {
        if (objList) {
          let objListViews: WorkflowRecordView[] = objList.map((obj) => {
            return this.asRecord(obj, finalRecordResult);
          });
          let filteredObjects = this.filterObjects(params, objListViews);

          this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

          console.log("Filtered Objects", filteredObjects);
        }
      },
        (err: Error) => {
          this.errorService.handleError(err);
        });
    });
  }
  getData(): Observable<[WorkflowNodeInfo[], WorkflowProtocolEntry[], WorkflowPermission[], ScheduleActionPlan[], ScheduleBaseAction[], dss.DataSource[], DataStore[]]> {
    let nodePlugInsObs = this.workflowsService.getNodePlugIns();
    let workflowsProtocolObs = this.bionApi.getWorkflowProtocolEntry();
    let wfPermissionsObs = this.bionApi.getWorkflowAuthPermissions();
    let schedulesObs = this.bionApi.getScheduleActionPlan();
    let scheduleActionsobs = this.bionApi.getScheduleActions();
    let datasourcesObs = this.bionApi.getDataSources();
    let dataStoreObs = this.bionApi.getDataStoreObjectList();


    let finalRecordsObs = forkJoin(nodePlugInsObs, workflowsProtocolObs, wfPermissionsObs, schedulesObs, scheduleActionsobs, datasourcesObs, dataStoreObs);

    return finalRecordsObs;
  }

  asHeader(): HeaderRow[] {
    const cols: HeaderRow[] = [
      { field: 'object.name', header: 'Workflow', width: "40%" },
      { field: 'permissions', header: 'SharedWith', width: "10%" },
      { field: 'lastUpdated.start', header: 'LastUpdated', width: "10%" },
      { field: 'lastUpdated.user', header: 'by', width: "10%" },
    ];

    return cols
  }
  asRecord(arg: WorkflowRepositoryEntry, data: [WorkflowNodeInfo[], WorkflowProtocolEntry[], WorkflowPermission[], ScheduleActionPlan[], ScheduleBaseAction[], dss.DataSource[], DataStore[]]): WorkflowRecordView {
    let recordView: WorkflowRecordView = new WorkflowRecordView(arg,arg.workflowData);

    recordView.object = arg;

    let objProtocol = data[1].filter((log) => { return log.objectType === "Workflow" && parseInt(log.objectId) === arg.id });

    console.log("OjbProtocol", objProtocol);

    if (objProtocol && objProtocol.length === 0) {
      console.log("WF has no protocol:", arg);
      return recordView
      //throw new Error("No protocol found for this WF");
    }
    let latestProtocol = objProtocol.reduce((a, b) => {
      return new Date(a.start) > new Date(b.start) ? a : b;
    });

    recordView.lastUpdated = latestProtocol;
    recordView.info = arg.workflowData;

    // Find related schedule Actions
    let wfSchedules = data[3];
    let wfScheduleActions = data[4];

    let extractActions = <RunWorkflowAction[]>wfScheduleActions.filter((schedule) => {
      return schedule._type = "models.scheduler.RunWorkflowAction"
    })
    let wfActions = extractActions.filter((action) => {
      return action.workflow === arg.id
    });

    let filteredSchedules: ScheduleActionPlan[] = [];
    for (let i of wfActions) {
      for (let y of wfSchedules) {
        if (i.actionPlan === y.id) {
          filteredSchedules.push(y);
        }
      }
    }

    recordView.schedulePlans = filteredSchedules;

    //find related permission
    let wfPermissions = data[2].filter((perm) => { return perm.Accessible.ID == arg.id });
    recordView.permissions = wfPermissions;

    recordView.loading = false;

    // find related nodes
    let allNodes = data[0];

    const rawGuiInfo = NodePlugInInfos.getNodeGuiInfo();
    const workflowNodeGuiInfo = NodePlugInInfos.getWorkflowNodeGuiInfo(
      allNodes,
      rawGuiInfo
    );

    let wfNodes = arg.workflowData.Nodes;

    let filteredNodes: WorkflowNodeGuiInfo[] = [];

    for (let i of wfNodes) {
      for (let y of workflowNodeGuiInfo) {
        if (i.Engine.Name === y.Name) {
          filteredNodes.push(y);
        }
      }
    }
    recordView.nodes = filteredNodes;


    // find related datasources and destinations from filtered nodes
    let datasources = data[5];
    let dataStores = data[6];
    let filteredDatasources: dss.DataSource[] = [];
    let filteredDestinations: DataStore[] = [];

    let wfNodesData = arg.workflowData.Nodes;
    wfNodesData.forEach((node) => {
      if (node.Name === "PsaInput") {
        let nodeConfig = <PsaInputPlugIn.Settings>node.Properties.Configuration;
        let foundEntry = datasources.find((ds) => ds.id == nodeConfig.SelectedDataSource?.ID);
        if (foundEntry) {
          filteredDatasources.push(foundEntry);
        }
      }
      if (node.Name === "DataStoreOutput") {
        let nodeConfig = <DataStoreOutPlugInSettings>node.Properties.Configuration;
        let foundEntry = dataStores.find((ds) => ds.id == nodeConfig.SelectedDataStore?.id);
        if (foundEntry) {
          filteredDestinations.push(foundEntry);
        }
      }
    });
    recordView.datasources = filteredDatasources;
    recordView.datastores = filteredDestinations;

    return recordView
  }

  subs = new SubSink;
  //usersDetails: UserDetailsRow[] = [];
  selectedWorkflow?: WorkflowRepositoryEntry;
}


//NEW
export class WorkflowViewRecord implements RecordProvider<WorkflowRepositoryEntry, WorkflowRecordView, RichtWorkflowCake.WorkflowView[], dss.ObjectProtocolRow> {

  constructor(private data: RichtWorkflowCake.WorkflowView[],private usersDetails: UserDetailsRow[] ,private workflowsService: WorkflowsService,
    private errorService: SystemMessageLogService, private objectFilterService: ObjectSearchService, private utilService: UtilFunctionsService) {

  }

  getData(): Observable<RichtWorkflowCake.WorkflowView[]> {
    return of(this.data);
  }
  asRecord(arg: WorkflowRepositoryEntry, data: RichtWorkflowCake.WorkflowView[]): WorkflowRecordView {

    const wf = data.find(d => d.Workflow.id == arg.id);

    if(wf === undefined) throw new Error("The workflow with id " + arg.id + " does not exist");

    const view = new WorkflowRecordView(arg, arg.workflowData);

    view.object = wf.Workflow;
    view.permissions = wf.Permissions.map(p => p.Entry);
    view.datasources = wf.DataSources;
    view.datastores = wf.DataStores;
    view.schedulePlans = wf.SchedulePlan;
    view.info = wf.Workflow.workflowData;

          
    const permSorted = view.permissions.sort((a, b) => {
      if (a.IsOwner === b.IsOwner) {
        return 0;
      }
      return a.IsOwner ? -1 : 1;
    })

  view.shared = permSorted.map((p) => {
      
      const userdetailinfos = this.usersDetails.find ( (detail) => {return p.Role == detail.id});

      if(userdetailinfos && userdetailinfos.avatar) {
        const image = this.utilService.int8ArrayToBase64Image(userdetailinfos.avatar)
          return image;
      } else {
          return undefined;
      }
  })


    let objProtocol = wf.Protocols.map(protocol => protocol.Entry);
    //console.log("OjbProtocol", objProtocol);
    let latestProtocol = objProtocol.reduce((a, b) => {
      return new Date(a.start) > new Date(b.start) ? a : b;
    });

    view.lastUpdated = latestProtocol;

    // this.subs.sink = this.userService.getUserDetailsRow(latestProtocol.userId).subscribe((userRow) => {

    //     if(userRow.length > 0) {
    //         view.image = this.utilService.int8ArrayToBase64Image(userRow[0].avatar);
    //     } else {
    //         view.image = undefined;
    //     }

    // });

    // function to get user avatar
    const userFound = this.usersDetails.find((user) => { return user.id === latestProtocol?.userId});

    if(userFound && userFound.avatar) {
        view.image = this.utilService.int8ArrayToBase64Image(userFound.avatar);
    } else {
        view.image = undefined;
    }


    return view;
  }
  asHeader(): HeaderRow[] {
    const cols: HeaderRow[] = [
      { field: 'object.name', header: 'Workflow', width: "65%" },
      { field: 'object.runInfo.simulation.ReadOnly', header: 'Mode', width: "10%" },
      { field: 'permissions', header: 'SharedWith', width: "10%" },
      { field: 'lastUpdated.start', header: 'LastUpdated', width: "15%" },
      { field: 'lastUpdated.user', header: 'by', width: "10%" },
    ];

    return cols;
  }
  asActions(): MenuItem[] {
    const dialogActions: MenuItem[] = [
      {
        label: 'Change', tooltip: "Workflows.ChangeWorkflowInformation", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-info", command: () => {
            this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Edit workflow",WorkflowDialogActionType.editWorkflow,"Edit",this.selectedWorkflow, this.selectedWorkflow?.workflowData))
          //this.workflowsService.displayEditWorkflow.emit(true);

        }
      },
      {
        label: 'Delete', tooltip: "Workflows.DeleteWorkflow", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
          this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Delete workflow",WorkflowDialogActionType.deleteWorkflow,"Edit",this.selectedWorkflow, this.selectedWorkflow?.workflowData))

          //this.workflowsService.displayDeleteWorkflow.emit([this.selectedWorkflow,true]);

        }
      },
    ];

    return dialogActions;
  }

  subs = new SubSink;
  //usersDetails: UserDetailsRow[] = [];
  selectedWorkflow?: WorkflowRepositoryEntry;

  subscribeToEmitter(objList: WorkflowRepositoryEntry[]): void {
    this.subs.sink = this.workflowsService.selectedWorkflowEmitter.subscribe((res: WorkflowRepositoryEntry) => {
      this.selectedWorkflow = res;
    },
      (err: Error) => {
        this.errorService.handleError(err);
      });
    this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {

      this.subs.sink = this.getData().subscribe((finalRecordResult) => {
        if (objList) {
          let objListViews: WorkflowRecordView[] = objList.map((obj) => {
            return this.asRecord(obj, finalRecordResult);
          });
          let filteredObjects = this.filterObjects(params, objListViews);

          this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

          console.log("Filtered Objects", filteredObjects);
        }
      },
        (err: Error) => {
          this.errorService.handleError(err);
        });
    });
  }
  emitObjectAction(action: string, object: WorkflowRecordView): void {
    if (action === "CreateNewObject") {
      console.log("CreateNewObject")
      //this.workflowsService.displayCreateWorkflow.emit(true);
      this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"New workflow",WorkflowDialogActionType.createWorkflow,"Create", undefined, undefined))

      return
    }
    this.workflowsService.selectedMenuWorkflowEmitter.emit(object.object);
  }
  filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: WorkflowRecordView[]) {
    return FilterUtil.filter(params, objList);
  }
  sortEntries(entries: WorkflowRecordView[]): WorkflowRecordView[] {
    // let sortedProtocols = entries.sort(
    //   (objA, objB) => new Date(objB.lastUpdated?.end).getTime() - new Date(objA.lastUpdated?.end).getTime()
    // );


    let sortedProtocols = entries.sort(
      (objA, objB) => RecordViewSorter.sortLastUpdate(objA,objB)
    );

    return sortedProtocols;
  }
}
