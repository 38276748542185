import { Observable, forkJoin, of } from "rxjs";
import { RichtWorkflowCake } from "src/app/models/api/models/workflow/RichWorkflowCake";
import { WorkflowProtocolEntry } from "src/app/models/api/models/workflow/WorkflowProtocolEntry";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { UserDetailsRow } from "src/app/models/user.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { UserService } from "src/app/services/user.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { ActivityEntry, LatestActivityProvider } from "./latest-activity-provider";
import { ActivityUtils } from "./util-functions";

export class WorkflowActivity implements LatestActivityProvider<WorkflowRepositoryEntry, [WorkflowProtocolEntry[], WorkflowRepositoryEntry[]]> {
    constructor(private bionApi: ApiBackendService, private workflowsService: WorkflowsService, private users: UserDetailsRow[], private utilService: UtilFunctionsService) {
  
    }
    getData(): Observable<[WorkflowProtocolEntry[], WorkflowRepositoryEntry[]]> {
  
      let wfProtocolObs = this.bionApi.getWorkflowProtocolEntry();
      let workflowsObs = this.bionApi.getWorkflowObjectList();
      let finalRecordsObs = forkJoin(wfProtocolObs,workflowsObs);
  
      return finalRecordsObs;
    }
    buildGuI(data: [WorkflowProtocolEntry[], WorkflowRepositoryEntry[]], select: WorkflowRepositoryEntry): ActivityEntry[] {
      let wfProtocols: WorkflowProtocolEntry[] = data[0];
  
      if(select) {
        wfProtocols = wfProtocols.filter((protocol) => {
          return protocol.workflow === select.id
        })
      }
  
      let sortedProtocols = wfProtocols.sort(
        (objA, objB) => new Date(objB.end).getTime() - new Date(objA.start).getTime()
      );
  
      let latestProtocol = sortedProtocols;

      const latest_protocols_with_img:ActivityEntry[] = latestProtocol.map((protocol) => {
        return ActivityUtils.extractImage(this.users,protocol, this.utilService)
      });
  
      return latest_protocols_with_img;
  
  
      //return latestProtocol
  
    }
    subscribeToEmitter(): Observable<WorkflowRepositoryEntry> {
      let selectedDsObs = this.workflowsService.selectedWorkflowEmitter;
      return selectedDsObs
    }
  
    selectedObject?: WorkflowRepositoryEntry;

  }

  export class WorkflowViewActivity implements LatestActivityProvider<WorkflowRepositoryEntry, RichtWorkflowCake.WorkflowView[]> {
    constructor(private data:RichtWorkflowCake.WorkflowView[], private workflowsService: WorkflowsService, private users: UserDetailsRow[], private utilService: UtilFunctionsService) {
  
    }
    getData(): Observable<RichtWorkflowCake.WorkflowView[]> {
  
      return of(this.data);
    }
    buildGuI(data: RichtWorkflowCake.WorkflowView[], select: WorkflowRepositoryEntry): ActivityEntry[] {

      const target_data = select ? data.filter(d => d.Workflow.id === select.id) : data;
      const wfProtocols = target_data.flatMap(v => v.Protocols.map(p => p.Entry));

      // let wfProtocols: WorkflowProtocolEntry[] = data[0];
  
      // if(select) {
      //   wfProtocols = wfProtocols.filter((protocol) => {
      //     return protocol.workflow === select.id
      //   })
      // }
  
      let sortedProtocols = wfProtocols.sort(
        (objA, objB) => new Date(objB.end).getTime() - new Date(objA.start).getTime()
      );
  
      const latest_protocols_with_img:ActivityEntry[] = sortedProtocols.map((protocol) => {
        return ActivityUtils.extractImage(this.users,protocol, this.utilService)
      });
  
      return latest_protocols_with_img;
  

      // let latestProtocol = sortedProtocols;
  
      // return latestProtocol
  
    }
    subscribeToEmitter(): Observable<WorkflowRepositoryEntry> {
      let selectedDsObs = this.workflowsService.selectedWorkflowEmitter;
      return selectedDsObs
    }
  
    selectedObject?: WorkflowRepositoryEntry;

  }