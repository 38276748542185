import {
	Component,
	Input,
	OnInit,
	ViewChild,
} from "@angular/core";
import { Carousel } from "primeng/carousel";
import { WorkflowNodeGuiInfo } from "src/app/models/designer.models";
import { DesignerService } from "src/app/services/designer.service";

@Component({
	selector: "app-nodes-toolbar",
	templateUrl: "./nodes-toolbar.component.html",
	styleUrls: ["./nodes-toolbar.component.scss"],
})
export class NodesToolbarComponent implements OnInit {
	@Input() plugInInfos: WorkflowNodeGuiInfo[] = [];
	displayNodeInfo: boolean = false;
	@ViewChild("op1") op1;
	@ViewChild("nodesList") nodesList: Carousel;
	responsiveOptions;
	selectedNode?: WorkflowNodeGuiInfo;

	nodeCategorySelection: any[] = [];
	nodeCategorySelectionOptions: any[]= [];
	constructor(private designerService: DesignerService) {}

	ngOnInit() {
		this.responsiveOptions = [
			{
				breakpoint: "1024px",
				numVisible: 8,
				numScroll: 3,
			},
			{
				breakpoint: "768px",
				numVisible: 5,
				numScroll: 2,
			},
			{
				breakpoint: "560px",
				numVisible: 3,
				numScroll: 1,
			},
		];
		this.nodeCategorySelectionOptions = [
			{ name: "In/Out", value: "In/Out" },
			{ name: "Aggregate", value: "Aggregation" },
			{ name: "Transform", value: "Transformation" },
			{ name: "Calculate", value: "Calculation" },
			{ name: "Enrich", value: "Enrichment" },
			{ name: "Validate", value: "Validation" },
			{ name: "Format", value: "Format" },
		];
	}

	openNodeMenu(evt, item) {
		this.selectedNode = item;
		this.op1.toggle(evt);
	}
	resetCurrentForm() {
		this.selectedNode = undefined;
	}

	filterNodes(event) {

		this.nodesList.value = [];
		let filter: string[] = event.value;

		if (filter.length === 0 || undefined) {
			this.nodesList.value = [...this.plugInInfos];
			this.nodesList.page = 0;
			this.nodesList.cd.markForCheck();

			//this.nodesList.filter("");
		} else {
			let newArray: WorkflowNodeGuiInfo[] = [];
			for (let i of filter) {
				this.plugInInfos
					.filter((node: WorkflowNodeGuiInfo) => {
						return node.Category === i;
					})
					.map((item) => {
						newArray.push(item);
					});
			}
			this.nodesList.value = [...newArray];
			this.nodesList.page = 0;
			this.nodesList.cd.markForCheck();

			//this.nodesList.filter("");
		}
	}

	// DRAG & DROP
	draggedNodeGuiInfo?: WorkflowNodeGuiInfo = undefined;

	dragStart(event: DragEvent, product: WorkflowNodeGuiInfo) {


		this.draggedNodeGuiInfo = product;
		this.designerService.draggedNodeEmitter.emit(product);
	}


	dragEnd(event: DragEvent) {
		//this.draggedProduct = null;

		this.draggedNodeGuiInfo = undefined;
	}
}
