import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { DataArtifact } from "src/app/models/designer.models";

@Component({
	selector: "app-workflow-artefact",
	templateUrl: "./workflow-artefact.component.html",
	styleUrls: ["./workflow-artefact.component.scss"],
})
export class WorkflowArtefactComponent implements OnInit {
	@Input() artifacts: DataArtifact[] = [];
	@Input() toggleView: boolean = true;
	@Input() artifactsMap: Array<[string, DataArtifact[]]> = [];
	chartData: any;
	chartOptions: any;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		// if (changes.artifacts.currentValue) {
		// 	//this.initChart(changes.artifacts.currentValue);
		// }
	}

	ngOnInit(): void {}

	// downloadFile(rowData: DataArtifact) {
	// 	console.log("File to be downloaded", rowData);

	// 	// Base64 to Blob
	// 	const byteCharacters = atob(rowData.Bytes);
	// 	const byteNumbers = new Array(byteCharacters.length);
	// 	for (let i = 0; i < byteCharacters.length; i++) {
	// 		byteNumbers[i] = byteCharacters.charCodeAt(i);
	// 	}
	// 	const byteArray = new Uint8Array(byteNumbers);
	// 	// Prepare download for Blob

	// 	const blob = new Blob([byteArray], { type: rowData.MimeType });

	// 	const data = window.URL.createObjectURL(blob);

	// 	var link = document.createElement("a");
	// 	link.href = data;
	// 	link.download = rowData.Name + "." + rowData.Extension;
	// 	// this is necessary as link.click() does not work on the latest firefox
	// 	link.dispatchEvent(
	// 		new MouseEvent("click", { bubbles: true, cancelable: true, view: window })
	// 	);
	// }

	downloadFileNew(rowData: DataArtifact) {
		const byte_array = rowData.Bytes;
		//const byteNumbers = new Array(byte_array.length);
		const u_int_array = new Uint8Array(byte_array);
		const byteBlob = new Blob([u_int_array]);

		const target_blob = byteBlob;

		const data = window.URL.createObjectURL(target_blob);

        const link = document.createElement("a");
        link.href = data;
		link.download = rowData.Name + "." + rowData.Extension;

		// this is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(
			new MouseEvent("click", {
				bubbles: true,
				cancelable: true,
				view: window,
			})
		);
	}

	// initChart(artifacts?: DataArtifact[]) {
	// 	let data = artifacts;
	// 	let typesArray = new Array();

	// 	let dataArray: number[] = new Array();

	// 	if (data !== undefined) {
	// 		let typesArray = data.map((a) => {
	// 			return a.Extension;
	// 		});
	// 		let typesArraySet = new Set(typesArray);
	// 		let size = typesArraySet.size;
	// 		let typesArrayUnique = typesArraySet.values();

	// 		data.map((value) => {
	// 			// if (value. === "Succeeded") {
	// 			// 	succeedNumber = succeedNumber + 1;
	// 			// }
	// 			// if (value.Message === "Failed") {
	// 			// 	failedNumber = failedNumber + 1;
	// 			// }
	// 		});
	// 	}

	// 	let chartData = {
	// 		labels: typesArray,
	// 		datasets: [
	// 			{
	// 				data: dataArray,
	// 				backgroundColor: ["#FF6384", "#36A2EB"],
	// 				hoverBackgroundColor: ["#FF6384", "#36A2EB"],
	// 			},
	// 		],
	// 	};
	// 	this.chartData = chartData;
	// 	this.chartOptions = {
	// 		plugins: {
	// 			legend: {
	// 				labels: {
	// 					fontColor: "#A0A7B5",
	// 				},
	// 				display: false,
	// 			},
	// 		},
	// 	};
	// }
}
