import { MenuItem } from "primeng/api";
import { Observable, forkJoin } from "rxjs";
import { PsaInputPlugIn } from "src/app/models/api/models/etl/PsaInputPlugIn";
import { TaskJobModel } from "src/app/models/api/models/staging/TaskJobModel";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { AdapterTypeInfo } from "src/app/models/connector.model";
import { ScheduleActionPlan, ScheduleBaseAction, ExtractDataSourceAction } from "src/app/models/schedule.model";
import { UserDetailsRow } from "src/app/models/user.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { ObjectSearchService } from "src/app/services/object-search.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { TasksService } from "src/app/services/tasks.service";
import { UserService } from "src/app/services/user.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { SubSink } from "subsink";
import { DropDownModel } from "../general-search/drop-down-model";
import { FilterProvider } from "../general-search/filter-provider";
import { DropDownEntry, HeaderRow, RecordProvider, RecordView, RecordViewSorter } from "./record-provider";
import * as dss from 'src/app/models/datasource.model';
import { Id } from "src/app/helper/id";

export class DataSourceRecordView implements RecordView<dss.DataSource, dss.ObjectProtocolRow> {
    object: dss.DataSource;
    lastUpdated?: dss.ObjectProtocolRow;
    info: any;
    fields: dss.DataSourceField[] = [];
    connector?: dss.DataSourceConnector<any>;
    permissions: dss.DataSourcePermission[] = [];
    adapter?: AdapterTypeInfo<any, any>;
    workflows: WorkflowRepositoryEntry[] = [];
    schedulePlans: ScheduleActionPlan[] = [];
    loading: boolean = false;
    image?: any;
    shared?: any[];

    constructor(object: dss.DataSource) {
        this.object = object
    }
}

export class DataSourceRecord implements RecordProvider<dss.DataSource, DataSourceRecordView, [dss.ObjectProtocolRow[], dss.DataSourceConnector<any>[], UserDetailsRow[], dss.DataSourceField[], dss.DataSourcePermission[], AdapterTypeInfo<any, any>[], ScheduleActionPlan[], ScheduleBaseAction[], WorkflowRepositoryEntry[], TaskJobModel.JobStatusInfo[]], dss.ObjectProtocolRow>{

    constructor(private bionApi: ApiBackendService, private errorService: SystemMessageLogService, private userService: UserService, private utilService: UtilFunctionsService, private datasourceService: DatasourcesService, private objectFilterService: ObjectSearchService, private tasksService: TasksService) { }
    sortEntries(entries: DataSourceRecordView[]): DataSourceRecordView[] {
        let sortedProtocols = entries.sort(
            (objA, objB) => RecordViewSorter.sortLastUpdate(objA,objB)
        );

        return sortedProtocols;
    }
    asActions(): MenuItem[] {
        let dialogActions: MenuItem[] = [
            {
                label: 'Change datasource', tooltip: "ChangeDatasourceInformation", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-cog", command: () => {
                    this.datasourceService.displayEditDatasourceDialog([this.selectedDataSource,true]);

                }
            },
            // { label: 'Change write mode' , tooltip: "ChangeWriteMode", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon:"pi pi-pencil", command: () => {
            //   this.datasourceService.displayChangeWriteModeDialog(true);

            // }},
            {
                label: 'Delete', tooltip: "DeleteDatasourcePermanently", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
                    this.datasourceService.displayDeleteDatasourceDialog([this.selectedDataSource,true]);

                }
            },
        ];

        return dialogActions
    }
    filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: DataSourceRecordView[]) {
        console.log(params);

        let intermediateDataSets = objList;

        const drop_down_models = params[0];
        const filter_providers = params[1];

        for (let provider_name of filter_providers.keys()) {

            console.log("Apply filter: " + provider_name);
            //console.log(providerEntry);

            const provider = Id.assertSet(filter_providers.get(provider_name), new Error("No provider for this entry found"+provider_name));

            // TODO: get all filters from drop downs
            // console.log("Has Entry");
            console.log(params[0].has(provider_name));

            console.log("Get Entry");
            // const xxx = drop_down_models.get(provider_name);
            // console.log(xxx);

            const dd_model = Id.assertSet(drop_down_models.get(provider_name), new Error("No drop down model available for " + provider_name));
            // const dropDownSelection: DropDownEntry<any>[] = drop_down_models.getOrElse(provider_name, new Array<DropDownEntry<any>>()).selection;
            const dropDownSelection: DropDownEntry<any>[] = dd_model.selection;

            // console.log("selection");
            // console.log(dropDownSelection);

            if (dropDownSelection === undefined) continue;
            const dropDownValues = dropDownSelection.map(s => s.value);

            if (dropDownValues.length == 0) continue;

            console.log(intermediateDataSets);

            intermediateDataSets = intermediateDataSets.filter(ds => {
                const foundValues = dropDownValues.filter(va => {
                    return provider.filter(va, ds)
                });
                console.log(foundValues);
                return foundValues.length > 0
            });
        }
        return intermediateDataSets;

    }
    emitObjectAction(action: string, object: DataSourceRecordView) {
        console.log(object);
        this.datasourceService.selectedMenuDatasourceEmitter.emit(object.object);

        if (action === "PullAction") {
            this.datasourceService.displayPullDatasourceDialog([object.object, true]);
        }
        if (action === "UploadFile") {
            this.datasourceService.displayPushNewDataDialog([object.object, true]);
        }
        if (action === "ChangeSettings") {
            this.datasourceService.displayChangeConnectorSettingsDialog([object.object, true]);
        }
        if (action == "EditWriteMode") {
            this.datasourceService.displayChangeWriteModeDialog([object.object,true]);
        }

        // Check which action is performed --> dispatcher to check the switch and cast object type from any
        //throw new Error('Method not implemented.');
    }
    subscribeToEmitter(objList: dss.DataSource[]) {
        this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe((res: dss.DataSource) => {
            this.selectedDataSource = res;
            console.log(this.selectedDataSource);
        },
            (err: Error) => {
                this.errorService.handleError(err);
            });
        this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {
            //console.log(params);

            this.subs.sink = this.getData().subscribe((finalRecordResult) => {
                if (objList) {
                    //console.log(finalRecordResult);
                    let objListViews: DataSourceRecordView[] = objList.map((obj) => {
                        return this.asRecord(obj, finalRecordResult);
                    });
                    let filteredObjects = this.filterObjects(params, objListViews);
                    this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

                    console.log("Filtered Objects", filteredObjects);
                }
            },
                (err: Error) => {
                    this.errorService.handleError(err);
                });
        });
        this.subs.sink = this.datasourceService.changedObjectLoadingEmitter.subscribe((loading: boolean) => {
            // Update loading status icon for respective ds
        },
            (err: Error) => {
                this.errorService.handleError(err);
            });
    }
    subs = new SubSink;
    psaProtocols: dss.DataPackageProtocolEntry[] = [];
    usersDetails: UserDetailsRow[] = [];
    connectors: dss.DataSourceConnector<any>[] = [];
    selectedDataSource?: dss.DataSource;

    // @Output() changedFilterEmitter = new EventEmitter<DataSourceRecordView[]>();

    getData(): Observable<[dss.ObjectProtocolRow[], dss.DataSourceConnector<any>[], UserDetailsRow[], dss.DataSourceField[], dss.DataSourcePermission[], AdapterTypeInfo<any, any>[], ScheduleActionPlan[], ScheduleBaseAction[], WorkflowRepositoryEntry[], TaskJobModel.JobStatusInfo[]]> {
        let dsDataPackagesObs = this.bionApi.getDataPackageProtocols();
        let objectProtocolObs = this.bionApi.getObjectProtocolRow();
        let connectorInfosObs = this.bionApi.getConnectorsInfo();
        let usersDetailsObs = this.userService.getUserDetailsRow();
        let dsFieldsObs = this.bionApi.getDataSourceFields();
        let dsPermissionObs = this.bionApi.getDataSourcePermission();
        let dsAdapterTypeObs = this.bionApi.getAdapterTypeInfo();
        let schedulesObs = this.bionApi.getScheduleActionPlan();
        let scheduleActionsObs = this.bionApi.getScheduleActions();
        let workflowsObs = this.bionApi.getWorkflowObjectList();
        let extractJobs = this.tasksService.ExtractDataToPsa_jobs_status();

        let finalRecordsObs = forkJoin(objectProtocolObs, connectorInfosObs, usersDetailsObs, dsFieldsObs, dsPermissionObs, dsAdapterTypeObs, schedulesObs, scheduleActionsObs, workflowsObs, extractJobs);

        return finalRecordsObs;
    }

    asHeader(): HeaderRow[] {
        let cols: HeaderRow[] = [
            { field: 'object.name', header: 'Datasource', width: "40%" },
            { field: 'lastUpdated.start', header: 'LastUpdated', width: "30%" },
        ];

        return cols
    }
    asRecord(arg: dss.DataSource, data: [dss.ObjectProtocolRow[], dss.DataSourceConnector<any>[], UserDetailsRow[], dss.DataSourceField[], dss.DataSourcePermission[], AdapterTypeInfo<any, any>[], ScheduleActionPlan[], ScheduleBaseAction[], WorkflowRepositoryEntry[], TaskJobModel.JobStatusInfo[]]): DataSourceRecordView {
        //console.log(arg,data);
        let recordView: DataSourceRecordView = new DataSourceRecordView(arg);

        recordView.object = arg;
        let objProtocol = data[0].filter((log) => { return log.objectType === "DataSource" && parseInt(log.objectId) === arg.id });
        let connector = data[1].find((connector) => { return connector.DataSource === arg.id });
        let dsFields = data[3].filter((field) => { return field.dataSource == arg.id });
        let dsPermissions = data[4].filter((perm) => { return perm.Accessible.ID == arg.id });
        let adapterInfo = data[5].find((adapter) => { return adapter.Name === connector?.Connector });

        if (objProtocol && objProtocol.length === 0) {
            console.log("DS has no protocol:", arg);
            return recordView
            //throw new Error("No protocol found for this ds");
        }
        let latestProtocol = objProtocol.reduce((a, b) => {
            return new Date(a.start) > new Date(b.start) ? a : b;
        });
        let userDetails = data[2].find((user: UserDetailsRow) => {
            return user.id === latestProtocol.userId;
        });
        if (userDetails && userDetails.avatar) {
            recordView.image = this.utilService.int8ArrayToBase64Image(userDetails.avatar);
        }



        // Assign to data record
        recordView.lastUpdated = latestProtocol;
        recordView.connector = connector;
        recordView.fields = dsFields;
        recordView.info = "TODO";
        recordView.permissions = dsPermissions;
        recordView.adapter = adapterInfo;
        recordView.loading = false;

        // Find related schedule Actions
        let dsSchedules = data[6];
        let dsScheduleActions = data[7];

        let extractActions = <ExtractDataSourceAction[]>dsScheduleActions.filter((schedule) => {
            return schedule._type = "models.scheduler.ExtractDataSourceAction"
        })
        let dsActions = extractActions.filter((action) => {
            return action.dataSource === arg.id
        });
        //console.log("filtered dsActions",dsActions);



        let filteredSchedules: ScheduleActionPlan[] = [];

        for (let i of dsActions) {
            for (let y of dsSchedules) {
                if (i.actionPlan === y.id) {
                    filteredSchedules.push(y);
                }
            }
        }

        recordView.schedulePlans = filteredSchedules;

        //Find related workflows
        let workflows = data[8];

        let filteredWorkflows = [];

        for (let workflow of workflows) {
            let wfData = workflow.workflowData;
            let wfNodes = wfData.Nodes;
            //console.log(wfNodes);
            let filteredNodes = wfNodes.filter((node) => { return node.Name == "PsaInput" });

            for (let node of filteredNodes) {
                //console.log("node",node);
                let config = <PsaInputPlugIn.Settings>node.Properties.Configuration;
                let ds = config.SelectedDataSource;
                //console.log("DS NAME",ds.id);
                if (config.SelectedDataSource?.ID === arg.id) {
                    filteredWorkflows.push(workflow);
                }
            }
        }
        recordView.workflows = filteredWorkflows;

        // Set Loading Status - TODO!!!!!!!
        // Check if datasource is in List of ExtractJobs
        //let dsStatus = data[9];
        return recordView
    }
}