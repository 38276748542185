
<!-- <p-toolbar>
    <div class="p-toolbar-group-left"> -->
<!-- <p-button label="{{'Data' | translate}}" icon="pi pi-table" (click)="displayDataPreview = true"></p-button> -->
<!-- <app-workflow-artefact [artifacts]="artifacts" [artifactsMap]="artifactsMap" [toggleView]="true">
        </app-workflow-artefact> -->
<!-- </div> -->
<!--
    <span *ngIf="totalRecords"> {{totalRecords}} {{'rows' | translate}} | {{cols.length}} {{'columns' | translate}}
    </span> -->

<!-- <div class="p-toolbar-group-right" style="margin-left: 5px">
        <app-nodes-errors [errorLogResult]="errorLogResult" [toggleView]="true"></app-nodes-errors>
    </div>
</p-toolbar> -->

<div>
    <app-nodes-errors [errorLogResult]="errorLogResult" [toggleView]="true" [runWorkflowStatus]="runWorkflowStatus"  [updateSettingsStatus]="updateSettingsStatus"></app-nodes-errors>

</div>