<!-- <p-dialog [modal]="false" header="{{'Datapool' | translate}}" [baseZIndex]="100" (onHide)="onCloseDialog()"
    [(visible)]="displayPsa" [position]="'center'" [resizable]="false"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> -->
    <div style="margin:20px 0">
        <h5>
            {{'Data' | translate }} <app-info-tooltip></app-info-tooltip>                     
            <span style="float: right">
                <button pButton pRipple type="button" icon="pi pi-file-o" label="{{'ManagePackages' | translate}}"
                pTooltip="{{'RemoveDataPackage' | translate}}"
                [disabled]="!(dt.value.length > 0 && (selectedDatasource || selectedDataStore))" *ngIf="selectedDatasource || selectedDataStore"
                (click)="displayDeleteDataPackageDialog()"
                class="p-button-rounded p-button-info p-button-text p-mr-2 p-mb-2"></button>
            </span>
        </h5>
        <span *ngIf="!loading">
            <p class="subtitle" *ngIf="rowCount && selectedDatasource">{{columnCount}} {{'columns' |
                translate}} |
                {{rowCount}}
                {{'rows' | translate}}</p>
            <p class="subtitle" *ngIf="rowCount && selectedDataStore">{{columnCount}} {{'columns' |
                translate}} |
                {{currentRowCount}} {{'of' | translate}} {{rowCount}} {{'rows' | translate}}</p>
            <p class="subtitle" *ngIf="!selectedDatasource && !selectedDataStore">{{'NoDataProviderSelected'
                |
                translate}}</p>
            <p class="subtitle" *ngIf="!rowCount && selectedDatasource">{{'DataPoolEmpty' | translate}}</p>
        </span>
        <span *ngIf="loading">
            <p class="subtitle">{{'LoadingData' | translate}}</p>
        </span>


    </div>
    <!-- <div>        
        <p-dropdown placeholder="{{'SelectDataPackage' | translate}}" [showClear]="false" [options]="dataPackages"
            (onChange)="selectDataPackage($event)" [(ngModel)]="selectedDataPackage" optionLabel="id">
        </p-dropdown>
    </div> -->

    <div class="card">
        <p-table #dt [columns]="cols" [value]="psaData" styleClass="p-datatable-sm" scrollDirection="both"
            [resizableColumns]="true" columnResizeMode="expand" [scrollable]="true" scrollHeight="550px"
            autoLayout="true" (sortFunction)="customSort($event)" [paginator]="false" [rows]="rowsPerPage"
            [totalRecords]="totalRecords" [customSort]="true" [loading]="loading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    <span>
                        <span style="margin-right: 5px"><p-badge *ngIf="psaInfos && writeMode" [value]="writeMode"></p-badge></span>
                        <span style="margin-right: 5px"><p-checkbox  *ngIf="selectedDataStore" [binary]="true"  label="{{'Show meta fields' | translate }}" [(ngModel)]="showMetaFields" (onChange)="onChangeShowMetaFields($event)"></p-checkbox></span>
                        <span><p-checkbox  *ngIf="selectedDataStore" [binary]="true"  label="{{'Show latest package only' | translate }}" [(ngModel)]="showLatestPackage" (onChange)="onChangeShowLatestPackage($event)"></p-checkbox></span>
                    </span>

                    <!-- <span class="p-input-icon-right">
                        <button pButton pRipple type="button" icon="pi pi-file-o" label="{{'ManagePackages' | translate}}"
                            pTooltip="{{'RemoveDataPackage' | translate}}"
                            [disabled]="!(dt.value.length > 0 && (selectedDatasource || selectedDataStore))" *ngIf="selectedDatasource || selectedDataStore"
                            (click)="displayDeleteDataPackageDialog()"
                            class="p-button-rounded p-button-info p-button-text p-mr-2 p-mb-2"></button>
                    </span> -->
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>

                    <th pResizableColumn [pSortableColumn]="col.Name" [style]="{'width':'150px', 'font-size':'12px'}"
                        *ngFor="let col of columns">
                        {{col.Name}} <p-sortIcon [field]="col.Name"></p-sortIcon>
                        <p-columnFilter type="text" [field]="col.Name" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td [style]="{'width':'150px', 'font-size':'12px'}" *ngFor="let col of columns">
                        {{rowData[col.Name]}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td style="align-items: center;" colspan="5">{{'NoEntryFound' | translate }}.  <a>{{'Create new entry' | translate}}</a></td>
                </tr>
            </ng-template>
        </p-table>
        <p-paginator [rows]="rowsPerPage" [totalRecords]="totalRecords" pageLinkSize="3"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            (onPageChange)="onTablePageChanged($event)"></p-paginator>

    </div>
<!-- </p-dialog> -->
