import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
//import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";
//import { FormLayoutDemoComponent } from "./demo/view/formlayoutdemo.component";
// import { InvalidStateDemoComponent } from "./demo/view/invalidstatedemo.component";
// import { FloatLabelDemoComponent } from "./demo/view/floatlabeldemo.component";
// import { PanelsDemoComponent } from "./demo/view/panelsdemo.component";
// import { OverlaysDemoComponent } from "./demo/view/overlaysdemo.component";
// import { MediaDemoComponent } from "./demo/view/mediademo.component";
// import { MenusDemoComponent } from "./demo/view/menusdemo.component";
// import { MessagesDemoComponent } from "./demo/view/messagesdemo.component";
// import { MiscDemoComponent } from "./demo/view/miscdemo.component";
// import { EmptyDemoComponent } from "./demo/view/emptydemo.component";
// import { ChartsDemoComponent } from "./demo/view/chartsdemo.component";
// import { FileDemoComponent } from "./demo/view/filedemo.component";
// import { DocumentationComponent } from "./demo/view/documentation.component";
import { AppMainComponent } from "./app.main.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";
// import { InputDemoComponent } from "./demo/view/inputdemo.component";
// import { ButtonDemoComponent } from "./demo/view/buttondemo.component";
// import { TableDemoComponent } from "./demo/view/tabledemo.component";
// import { ListDemoComponent } from "./demo/view/listdemo.component";
// import { TreeDemoComponent } from "./demo/view/treedemo.component";
// import { DisplayComponent } from "./utilities/display.component";
// import { ElevationComponent } from "./utilities/elevation.component";
// import { FlexboxComponent } from "./utilities/flexbox.component";
// import { GridComponent } from "./utilities/grid.component";
// import { IconsComponent } from "./utilities/icons.component";
// import { WidgetsComponent } from "./utilities/widgets.component";
// import { SpacingComponent } from "./utilities/spacing.component";
// import { TypographyComponent } from "./utilities/typography.component";
// import { TextComponent } from "./utilities/text.component";
// import { AppCrudComponent } from "./pages/app.crud.component";
// import { AppCalendarComponent } from "./pages/app.calendar.component";
// import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";
// import { AppInvoiceComponent } from "./pages/app.invoice.component";
// import { AppHelpComponent } from "./pages/app.help.component";
import { AppRegisterComponent } from "./pages/app-register/app-register.component";

import { UserProfileComponent } from "./views/users/components/user-profile/user-profile.component";
import { UserSettingsComponent } from "./views/users/components/user-settings/user-settings.component";
import { UsersViewComponent } from "./views/users/users-view.component";
import { UsersListComponent } from "./views/users/components/users-list/users-list.component";

import { DesignerViewComponent } from "./views/designer/designer-view.component";

import { DataMarketViewComponent } from "./views/datamarket/data-market-view.component";
import { AppTutorialComponent } from "./pages/app-tutorial/app-tutorial.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
// import { MsalGuard } from "@azure/msal-angular";
import { DatasourcesViewRelayoutComponent } from "./views/datasources/view/datasources-view.component";
import { WorkflowsViewRelayoutComponent } from './views/workflows/view/workflows-view.component';
import { DestinationsViewRelayoutComponent } from "./views/destinations/view/destinations-view.component";
import { SchedulesViewRelayoutComponent } from "./views/schedules/view/schedules-view.component";
import { SchedulePlannerComponent } from "./views/schedule-planner/schedule-planner.component";
import { WebSocketTesterComponent } from "./views/experimental/web-socket-tester/web-socket-tester.component";
import { SqlDesignerViewComponent } from "./views/sql-designer/designer-view/designer-view.component";
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { CreateAbSourceComponent } from "./views/datasources/components/create-ab-source/create-ab-source.component";
import { PowerbiComponent } from "./views/reports/powerbi-component/powerbi.component";
import { PowerbiViewComponent } from "./views/reports/powerbi-view/powerbi-view.component";

const routes: Routes = [
	{
		path: "",
		component: AppMainComponent,
		children: [
			{ path: "", component: DashboardComponent },
			{ path: "personal/users", component: UsersViewComponent },
			{ path: "profile", component: UserProfileComponent },
			{ path: "user/settings", component: UserSettingsComponent },
			{ path: "tutorial", component: AppTutorialComponent },
			{
				path: "datasources",
				component: DatasourcesViewRelayoutComponent,
			},
			{
				path: "workflows",
				component: WorkflowsViewRelayoutComponent,
			},
			{
				path: "planner",
				component: SchedulePlannerComponent,
			},
			{ path: "planner/:id", component: SchedulePlannerComponent },
			{
				path: "designer",
				component: DesignerViewComponent,
			},
			{ path: "designer/:id", component: DesignerViewComponent },
			{
				path: "schedules",
				component: SchedulesViewRelayoutComponent,
			},
			{
				path: "destinations",
				component: DestinationsViewRelayoutComponent,
			},

			{
				path: "datamarket",
				component: DataMarketViewComponent,
			},
			{
				path: "sqlDesigner",
				component: SqlDesignerViewComponent,
			},
			{ path: "webSocketTester", component: WebSocketTesterComponent },
			{ path: "testAirbyteDialogs", component: CreateAbSourceComponent },
			{ path: "reports", component: PowerbiViewComponent },
			{ path: "reportsFull/:id", component: PowerbiComponent },


		],
	},
	{ path: "error", component: AppErrorComponent },
	{ path: "access", component: AppAccessdeniedComponent },
	{ path: "notfound", component: AppNotfoundComponent },
	{ path: "login", component: AppLoginComponent },
	{ path: "register", component: AppRegisterComponent },
	{ path: "forgotPassword", component: ForgotPasswordComponent },
	{ path: "resetPassword", component: ResetPasswordComponent },
	{ path: "**", redirectTo: "" },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			//initialNavigation: !isIframe ? 'enabled' : 'disabled', // Don't perform initial navigation in iframes,
			scrollPositionRestoration: "enabled",
		}),

	],
	exports: [RouterModule],
	providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class AppRoutingModule { }
