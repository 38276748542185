import { DesignerEvent } from "src/app/services/designer.service";
import { mxWorkflowGraph, mxWorkflowNodeValue, mxWorkflowPortValue } from "./mxWorkflowGraph";
import { mxCell, mxEventSource, mxEventObject } from "mxgraph"; // <- import types only
import {
	UpdateSettingsPortResult,
	UpdateSettingsResult,
	UpdateWorkflowSettingsResult,
	WorkflowResult,
} from "src/app/models/designer.models";
import { WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { WorkflowGraphComponent } from "./workflow-graph.component";
// import { WorkflowNodeSettings } from "src/app/models/workflow.model";

/**
 * Workflow Graph Event Type Enumeration
 */
export enum WorkflowGraphEventType {
	/**
	 * Cell added
	 */
	CellsAdded,
	CellConnected,
	/**
	 * Unknown Cell clicked
	 */
	CellClicked,
	GraphClicked,
	/**
	 * Workflow Node Cell clicked
	 */
	NodeCellClicked,
	/**
	 * Node Port Cell clicked
	 */
	PortCellClicked,
	/**
	 * Node Settings updated
	 */
	UpdateSettingsExecuted,
	/**
	 * Node Data changed
	 */
	NodeDataChanged,
	/**
	 * Port Data changed
	 */
	PortDataChanged,
	/**
	 * Workflow executed
	 */
	WorkflowExecuted,
		/**
	 * manual selection changed
	 */
	ManualSelectionCellChanged,
    /**
     *  NodeCell Double-Clicked
     */
    NodeCellDoubleClicked,
	/**
	 * GraphViewLoaded
	 */
	GraphViewLoaded
}

/**
 * Marker Interface for Workflow Graph Event Data.
 */
export interface IWorkflowGraphEventData {}

/**
 * Workflow Graph Event Data for mxGraph based events.
 * @type T Additional Data Type
 */
export class mxGraphEventData implements IWorkflowGraphEventData {
	readonly sender: mxEventSource;
	readonly evt: mxEventObject;

	constructor(sender: mxEventSource, evt: mxEventObject) {
		this.sender = sender;
		this.evt = evt;
	}
}

/**
 * Workflow Graph Event
 */
export class WorkflowGraphEvent<D extends IWorkflowGraphEventData>
	implements DesignerEvent<WorkflowGraphEventType, D>
{
	readonly Type: WorkflowGraphEventType;
	readonly Data: D;

	constructor(type: WorkflowGraphEventType, data: D) {
		this.Type = type;
		this.Data = data;
	}
}

// Event Type based Data: GraphViewLoaded
export class GraphViewLoadedData {
	readonly Graph: mxWorkflowGraph;
	readonly Sender: WorkflowGraphComponent;
	constructor(sender: WorkflowGraphComponent, graph: mxWorkflowGraph) {
		this.Graph = graph;
		this.Sender = sender;
	}
}


// Event Type based Data
export class CellsAddedData extends mxGraphEventData {
	readonly Cells: mxCell[];
	constructor(sender: mxEventSource, evt: mxEventObject, cells: mxCell[]) {
		super(sender, evt);
		this.Cells = cells;
	}
}

/**
 * An unknown cell type
 */
export class CellClickedData extends mxGraphEventData {
	readonly Cell: mxCell;
	constructor(sender: mxEventSource, evt: mxEventObject, cell: mxCell) {
		super(sender, evt);
		this.Cell = cell;
	}
}

/**
 * Workflow Node Cell Clicked.
 */
export class NodeCellClickedData extends CellClickedData {
	readonly Value: mxWorkflowNodeValue;
	constructor(
		sender: mxEventSource,
		evt: mxEventObject,
		cell: mxCell,
		value: mxWorkflowNodeValue
	) {
		super(sender, evt, cell);
		this.Value = value;
	}
}

export class PortCellClickedData extends CellClickedData {
	readonly Value: mxWorkflowPortValue;
	readonly ParentCell: mxCell;
	readonly ParentCellValue: mxWorkflowNodeValue;
	readonly Cell: mxCell;

	constructor(
		cell: mxCell,
		value: mxWorkflowPortValue,
		sender?: mxEventSource,
		evt?: mxEventObject
	) {
		super(sender, evt, cell);
		this.Value = value;
		this.ParentCell = cell.getParent();
		this.ParentCellValue = <mxWorkflowNodeValue>this.ParentCell.getValue();
	}
}

export class UpdateSettingsExecutedData {
	readonly Result: UpdateWorkflowSettingsResult;

	constructor(result: UpdateWorkflowSettingsResult) {
		this.Result = result;
	}
}

export class NodeDataChangedData implements IWorkflowGraphEventData {
	readonly Result: UpdateSettingsResult<WorkflowNodeSettings>;
	readonly NodeID: string;
	readonly WorkflowNodeValue:mxWorkflowNodeValue;

	constructor(
		result: UpdateSettingsResult<WorkflowNodeSettings>,
		nodeID: string,
		workflowNodeValue:mxWorkflowNodeValue
	) {
		this.Result = result;
		this.NodeID = nodeID;
		this.WorkflowNodeValue = workflowNodeValue;
	}
}

export class PortDataChangedData implements IWorkflowGraphEventData {
	readonly Result: UpdateSettingsPortResult;
	readonly NodeID: string;
	readonly PortID: string;

	constructor(result: UpdateSettingsPortResult, nodeID: string, portID) {
		this.Result = result;
		this.NodeID = nodeID;
		this.PortID = portID;
	}
}

export class WorkflowExecutedData {
	readonly Result: WorkflowResult;

	constructor(result: WorkflowResult) {
		this.Result = result;
	}
}

export class ManualSelectionCellChangedData {
	readonly oldSelectedCell: mxCell;
	readonly newSelectedCell: mxCell;

	constructor(oldSelectedCell:mxCell, newSelectedCell: mxCell) {
		this.oldSelectedCell = oldSelectedCell;
		this.newSelectedCell = newSelectedCell;
	}
}
