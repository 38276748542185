import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NodeMetaData } from "src/app/models/api/com/bion/etl/NodeMetaData";
import { WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { UpdateSettingsResult } from "src/app/models/designer.models";
import { IWorkflowNode } from "src/app/models/workflow.model";

import {
	DesignerEvent,
	DesignerService,
} from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";
import { WorkflowNodeAdapter } from "../node-config/node-config.component";
import {
	IWorkflowGraphEventData,
	NodeCellClickedData,
	UpdateSettingsExecutedData,
	WorkflowGraphEventType,
} from "../workflow-graph/workflow-graph-events";

export interface INodeConfigViewEvent<C> {
	Name: string;
	NodeID: string;
	Configuration: C;
}

export class NodeConfigViewEvent<C> implements INodeConfigViewEvent<C> {
	Name: string;
	NodeID: string;
	Configuration: C;

	static readonly SettingsChangedEvent: string = "Settings Changed";
}

export class NodeConfigSettingsChanged<C> extends NodeConfigViewEvent<C> {
	Name: string = NodeConfigViewEvent.SettingsChangedEvent;
}

export interface INodeCell {
	id: string;
}

/**
 * Node Configuration View.
 *
 * Contains basic methods to update settings and communicate with mother component.
 */
export interface NodeConfigView<C> {
	settingsToView(settings: C): void;
	viewToSettings(): C;

	/**
	 * Perform update settings with current view
	 * @param executeAfterwards True, if the function should execute the plug-in afterwards.
	 */
	onUpdateSettings(executeAfterwards: boolean): void;

	// /**
	//  * Write the settings result back to the workflow node.
	//  * @param result Update- or Get-Settings Result
	//  */
	// settingsResultToNode(result:SettingsResult<C,MetaInfo>) : void;

	// Workflow
	getCurrentWorkflowNode(): IWorkflowNode;

	getCurrentWorkflowNodeId(): string;

	/**
	 * Hook method for sub classes
	 * @param settings New settings
	 */
	onSettingsChanged(settings: C): void;
}

/**
 * Basis Komponente für Node Configuration Views.
 *
 * Diese Klasse imlementiert die Standardfunktionen, die für (fast) alle Config Views gelten.
 * Die Unterklassen kümmern sich nur um den individuellen Code, z.B. Settings in der UI anzeigen.
 */
@Component({
	template: "",
})
export abstract class NodeConfigComponentBase<C>
	implements NodeConfigView<C>, OnInit, OnDestroy {
	subs = new SubSink();

	@Input() set currentWorkflowNodeInfo(value: WorkflowNodeAdapter) {
		this._currentWorkflowNodeAdapter = value;

		// valid value?
		if (value === undefined) return;

		if (value.IWorkflowNode.Properties === undefined)
			throw new Error("Properties undefined");

		// update view
		let currentWorkflowNodeConfig = <C>(
			value.IWorkflowNode.Properties.Configuration
		);
		if (currentWorkflowNodeConfig) {
			this.settingsToView(currentWorkflowNodeConfig);
		}
	}

	protected _currentWorkflowNodeAdapter: WorkflowNodeAdapter;

	loading: boolean;

	protected constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) { }

	ngOnInit(): void {

		this.subs.sink = this.designerService.forceViewToSettingsEmitter.subscribe( () => {
			console.log("Force View to Settings received");
			this.forceViewToSettings();
			console.log("Force Settings to view Done");
		})

		this.subs.sink = this.designerService.workflowGraphEmitter.subscribe(
			(
				event: DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>
			) => {
				if (event.Type === WorkflowGraphEventType.NodeCellClicked) {
					const data = <NodeCellClickedData>event.Data;

					this._currentWorkflowNodeAdapter = new WorkflowNodeAdapter(
						data.Cell.id,
						data.Value
					);
				}
				if (event.Type === WorkflowGraphEventType.UpdateSettingsExecuted) {
					const data = <UpdateSettingsExecutedData>event.Data; // noch auf neue Klasse zu casten

					const outSettingsGiven = data.Result.OutNodeData.has(
						this._currentWorkflowNodeAdapter.Id
					);

					if (!outSettingsGiven) return;

					const outSettings = data.Result.OutNodeData.get(
						this._currentWorkflowNodeAdapter.Id
					);
					this.hook_nodeDataChanged(outSettings);
				}

				// if(event.Type === WorkflowGraphEventType.NodeDataChanged) {
				//     console.log("NodeDataChanged");
				//     let nodeDataChangedEvent = <NodeDataChangedData>event.Data;

				//     if(nodeDataChangedEvent.NodeID !== this._currentWorkflowNodeAdapter.Id) return;

				//     this.hook_nodeDataChanged(nodeDataChangedEvent.Result);
				// }
			}
		);
	}

	/**
	 * Hook method, when update settings returns, to write the settings to the view.
	 * @param event New Settings
	 */
	protected hook_nodeDataChanged(event: UpdateSettingsResult<WorkflowNodeSettings>) {
		this.settingsToView(event.Configuration);
	}

	// Individual Component Functions
	abstract settingsToView(settings: WorkflowNodeSettings): void;
	abstract viewToSettings(): C;

	getCurrentWorkflowNode(): IWorkflowNode {
		return this._currentWorkflowNodeAdapter.IWorkflowNode;
	}

	getCurrentWorkflowNodeId(): string {
		return this._currentWorkflowNodeAdapter.Id;
	}

	abstract onSettingsChanged(settings: C): void;

	/**
	 * Clean the settings without calling update settings.
	 */
	forceViewToSettings() : void {
		const node = this.getCurrentWorkflowNode();
		const nodeId = this.getCurrentWorkflowNodeId();
		const settings = this.viewToSettings();

		node.Properties.Configuration = settings;		// assign settings to node
	}

	onUpdateSettings(executeAfterwards: boolean): void {
		const node = this.getCurrentWorkflowNode();
		const nodeId = this.getCurrentWorkflowNodeId();
		const settings = this.viewToSettings();

		node.Properties.Configuration = settings;		// assign settings to node

		const event = new NodeConfigSettingsChanged<C>();
		event.NodeID = nodeId;
		event.Configuration = settings;

		console.log("NodeConfigSettingsChanged", event);

		this.designerService.NodeConfigSettingsChangedEmitter.emit(event);
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	/**
	 * Neue Divide & Conquer Variante
	 */
	newSettingsToView(settings: C, meta:NodeMetaData,) {

		if(settings === undefined) {
			// Default Routine
			this.onSettingsDefault();
		} else {
			if(this.newSettingsChanged()) {
				// changed
				this.onNewSettingsChanged();
			} else {
				// unchanged
				this.onNewSettingsUnchanged();
			}
		}
	}

	/**
	 * Prüfe ob:
	 * - Passen neue Meta Infos zu gegebenen Settings!
	 */
	newSettingsChanged() : boolean {return true}

	/**
	 * Beue neue Views mit Fehler Informationen & Spaltenlisten für Dropdowns mit fehlenden Spalten ergänzen, falls Spalten fehlen
	 */
	onNewSettingsChanged(): void {}
	onNewSettingsUnchanged(): void{}
	onSettingsDefault(): void{}
	onBeforeSettingsCheck(): void {}

}
