import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigItem } from '../create-ab-source/config-item';
import { ConfigDirective } from '../create-ab-source/config.directive';
import { ConfigComponent } from '../create-ab-source/config.component';


/**
 * Hier werden die dynamischen Config views gehalten.
 */
@Component({
  selector: 'app-create-ab-source-config',
  templateUrl: './create-ab-source-config.component.html',
  styleUrls: ['./create-ab-source-config.component.scss']
})
export class CreateAbSourceConfigComponent implements OnInit {

  @Input() configs: ConfigItem[] = [];    // Config Views

  lastError:string = "";
  currentSourceDef?:string;   // TODO: As Input? From parent combo box.

  @ViewChild(ConfigDirective, {static: true}) configHost!: ConfigDirective;


  constructor() { }

  ngOnInit(): void {
    // load initial component
    this.loadComponent();
  }

  loadComponent() {
    // select current view based on the selected source definition / connector

    this.lastError = "";
    if(this.currentSourceDef) {

      // Load component based on the source definition id, etc...
      // See: https://angular.io/guide/dynamic-component-loader

      const item = this.configs.find(c => c.id == this.currentSourceDef)
      if(item) {
        // laden
        const viewContainerRef = this.configHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<ConfigComponent>(item.component);
        componentRef.instance.data = item.data;

      } else {

        // -- vielleicht können wir auch hier den view auch einfach leer machen
        this.lastError = "Für den Connector " + this.currentSourceDef + " existiert kein View."
      }

    }
  }

}
