<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button *ngFor="let action of actions" pButton pRipple type="button" [icon]="action.icon"
                [class]="action.styleClass" pTooltip="{{action.tooltip | translate }}"
                (click)="action.command()"></button>
        </div>
    </ng-template>
</p-overlayPanel>

<div [ngSwitch]="currentView">
    <div *ngSwitchCase="'Datasources'">
        <p-table #dt [value]="records" [(selection)]="selectedObject"  (onRowSelect)="onSelectObject($event)"  rowGroupMode="subheader" groupRowsBy="connector.Connector"
            (onRowUnselect)="onUnselectObject($event)" selectionMode="single" sortMode="single"
            [globalFilterFields]="['datasource.object.id','datasource.connector.Connector','usePsa','age']" [rows]="8"
            paginator=true styleClass="p-datatable-customers p-datatable-sm">

            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" [width]="col.width" [pSortableColumn]="col.field">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{col.header | translate }}
                            <!-- <p-columnFilter type="text" [field]="col.field" display="menu" class="p-ml-auto">
                        </p-columnFilter> -->
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="groupheader" let-datasource>
                <tr pRowGroupHeader>
                    <td colspan="4" [pSelectableRow]="" >
                        <img [alt]="datasource.connector.Connector" src="assets/layout/images/connectors/{{datasource.connector.Connector}}.png" width="32" style="vertical-align: middle" />
                        <span class="font-bold ml-2" style="margin-left: 10px; font-weight: bold;">{{datasource.connector.Connector}}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-datasource let-rowIndex="rowIndex">
                <tr [pSelectableRow]="datasource"  class="icon-highlight">
                    <td>
                        <span class="p-column-title">{{'Name' | translate }}</span>
                        <span style="display:flex;align-items: center;">
                                <i class="pi pi-table"></i>
                            <span style="margin-left:10px; margin-right: 30px">{{datasource.object.name}}
                            </span>
                            <div class="ui-table-buttongroup-right"  *ngIf="datasource === selectedObject" style="float:right">
                                <button *ngIf="datasource.adapter.Info.IsPullSource === true"  pButton pRipple
                                    type="button" icon="pi pi-refresh"
                                    class="p-button-rounded p-button-text p-button-success p-mr-2 p-mb-2"
                                    style="color: white;"
                                    pTooltip="{{'PullData' | translate }}"
                                    (click)="typeClass.emitObjectAction('PullAction',datasource)"></button>
                                <button *ngIf="datasource.adapter.Info.IsPushSource === true" pButton pRipple type="button"
                                    icon="pi pi-upload"
                                    style="color: white;"
                                    class="p-button-rounded p-button-text p-button-success p-mr-2 p-mb-2"
                                    pTooltip="{{'UploadFile' | translate }}"
                                    (click)="typeClass.emitObjectAction('UploadFile',datasource)"></button>
                                <button pButton pRipple type="button" icon="pi pi-cog"
                                    class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                    style="color: white;"
                                    pTooltip="{{'ChangeSettings' | translate }}"
                                    (click)="typeClass.emitObjectAction('ChangeSettings',datasource)"></button>
                                <button pButton pRipple type="button" icon="pi pi-pencil"
                                    class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                    style="color: white;"
                                    pTooltip="{{'ChangeWriteModeHeader' | translate }}"
                                    (click)="typeClass.emitObjectAction('EditWriteMode',datasource)"></button>
                                <button *ngFor="let action of actions" style="color: white;"
                                    pButton pRipple type="button" [icon]="action.icon"
                                    [class]="action.styleClass" pTooltip="{{action.tooltip | translate }}"
                                    (click)="action.command()"></button>
                            </div>

                        </span>
                    </td>
                    <td><span class="p-column-title">{{'Shared with' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <p-avatarGroup styleClass="mb-3">
                                <p-avatar *ngFor="let p of datasource.shared" [image]="p" size="large" shape="circle"></p-avatar>
                            </p-avatarGroup>
                        </div>
                    </td>
                    <td><span class="p-column-title">{{'LastUpdated' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <span style="margin-right: 10px">{{datasource.lastUpdated.start | date: 'short'}}</span>
                        </div>
                    </td>
                    <td><span class="p-column-title">{{'by' | translate}}</span>
                        <p-chip *ngIf="datasource.image === undefined" label="{{datasource.lastUpdated?.user}}"
                            image="assets/layout/images/avatar-placeholder.png">
                        </p-chip>
                        <p-chip *ngIf="datasource.image  !== undefined" label="{{datasource.lastUpdated?.user}}"
                            [image]="datasource.image"></p-chip>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr >
                    <td style="align-items: center;" colspan="4">{{'NoEntryFound' | translate }}.  <a (click)="typeClass.emitObjectAction('CreateNewObject',undefined)">{{'Create new entry' | translate}}</a></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngSwitchCase="'Workflows'">
        <p-table #dt [value]="records" [(selection)]="selectedObject" (onRowSelect)="onSelectWf($event)"
            (onRowUnselect)="onUnselectWf($event)" selectionMode="single" sortMode="single"
            [globalFilterFields]="['workflow.object.id','workflow.connector.Connector','usePsa','age']" [rows]="10"
            paginator=true styleClass="p-datatable-customers p-datatable-sm">

            <ng-template pTemplate="header">

                <tr>
                    <th *ngFor="let col of cols" [width]="col.width" [pSortableColumn]="col.field">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{col.header | translate }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </div>
                    </th>
                    <!-- <th style="width: 8rem"></th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-workflow let-rowIndex="rowIndex">
                <tr [pSelectableRow]="workflow">
                    <td>
                        <span class="p-column-title">{{'Name' | translate }}</span>
                        <span style="display:flex;align-items: center;">
                            <i class="pi pi-share-alt"></i>
                            <span style="margin-left:10px; margin-right: 30px">{{workflow.object.name}}
                                <!-- <app-info-tooltip [text]="'ID: ' + workflow.object.id"></app-info-tooltip> -->
                            </span>
                            <div class="ui-table-buttongroup-right"  *ngIf="workflow === selectedObject" style="float:right">
                                <button pButton pRipple type="button" icon="pi pi-folder"
                                class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                style="color: white;"
                                pTooltip="{{'Workflows.OpenWorkflow' | translate }}"
                                (click)="onOpenWorkflow(workflow)"></button>
                                <button pButton pRipple type="button" icon="pi pi-play"
                                    class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                    style="color: white;"
                                    pTooltip="{{'RunWorkflow' | translate }}"
                                    (click)="onRunWorkflowAsync(workflow)"></button>

                                    <button *ngFor="let action of actions" style="color: white;"
                                    pButton pRipple type="button" [icon]="action.icon"
                                    [class]="action.styleClass" pTooltip="{{action.tooltip | translate }}"
                                    (click)="action.command()"></button>
                            </div>
                        </span>
                    </td>
                    <td><span class="p-column-title">{{'Mode' | translate }}</span>
                        <div style="display:flex;align-items: center;" *ngIf="workflow.object.runInfo">
                            <p-tag value="Simulation" *ngIf="workflow.object.runInfo.simulation.ReadOnly === true" [rounded]="true"></p-tag>
                            <p-tag severity="success" *ngIf="workflow.object.runInfo.simulation.ReadOnly === false" value="Production" [rounded]="true"></p-tag>
                        </div>
                    </td>
                    <td><span class="p-column-title">{{'Shared with' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <p-avatarGroup styleClass="mb-3">
                                <p-avatar *ngFor="let p of workflow.shared" [image]="p" size="large" shape="circle"></p-avatar>
                            </p-avatarGroup>
                        </div>
                    </td>
                    <td><span class="p-column-title">{{'LastUpdated' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <span style="margin-right: 10px">{{workflow.lastUpdated?.start | date: 'short'}}</span>
                        </div>
                    </td>
                    <td><span class="p-column-title">{{'by' | translate}}</span>
                        <p-chip *ngIf="workflow.image === undefined" label="{{workflow.lastUpdated?.user}}"
                            image="assets/layout/images/avatar-placeholder.png">
                        </p-chip>
                        <p-chip *ngIf="workflow.image  !== undefined" label="{{workflow.lastUpdated?.user}}"
                            [image]="workflow.image"></p-chip>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr >
                    <td style="align-items: center;" colspan="5">{{'NoEntryFound' | translate }}.  <a (click)="typeClass.emitObjectAction('CreateNewObject',undefined)">{{'Create new entry' | translate}}</a></td>
                </tr>
            </ng-template>
        </p-table>

    </div>
    <div *ngSwitchCase="'Destinations'">
        <p-table #dt [value]="records" [(selection)]="selectedObject" (onRowSelect)="onSelectDestination($event)"
            (onRowUnselect)="onUnselectDestination($event)" selectionMode="single" sortMode="single"
            [globalFilterFields]="['destination.object.id','destination.connector.Connector','usePsa','age']"
            [rows]="10" paginator=true styleClass="p-datatable-customers p-datatable-sm">

            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" [width]="col.width" [pSortableColumn]="col.field">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{col.header | translate }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-destination let-rowIndex="rowIndex">
                <tr [pSelectableRow]="destination">
                    <td>
                        <span class="p-column-title">{{'Name' | translate }}</span>
                        <span style="display:flex;align-items: center;">
                            <i class="pi pi-th-large"></i>
                            <span style="margin-left:10px; margin-right: 30px">{{destination.object.name}}
                            </span>
                            <div class="ui-table-buttongroup-right"  *ngIf="destination === selectedObject" style="float:right">
                                <button style="color: white;" *ngFor="let action of actions" style="color: white;" pButton pRipple type="button" [icon]="action.icon"
                                [class]="action.styleClass" pTooltip="{{action.tooltip | translate }}"
                                (click)="action.command()"></button>
                            </div>
                        </span>
                    </td>
                    <td><span class="p-column-title"> {{'WriteMode' | translate}}</span>
                        <!-- <span class="image-text">{{destination.object.writeMode}}</span> -->
                        <p-tag value="Append" severity="info"  *ngIf="destination.object.writeMode === 'Append'" [rounded]="true"></p-tag>
                        <p-tag severity="warning" *ngIf="destination.object.writeMode === 'Replace'" value="Replace" [rounded]="true"></p-tag>
                    </td>
                    <td><span class="p-column-title"> {{'Info' | translate}}</span>
                        <span class="image-text">{{destination.fields?.length}} {{'fields' | translate}}</span>

                    </td>
                    <td><span class="p-column-title">{{'Shared with' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <p-avatarGroup styleClass="mb-3">
                                <p-avatar *ngFor="let p of destination.shared" [image]="p" size="large" shape="circle"></p-avatar>
                            </p-avatarGroup>
                        </div>
                    </td>
                    <td><span class="p-column-title">{{'LastUpdated' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <span style="margin-right: 10px">{{destination.lastUpdated?.start | date: 'short'}}</span>
                        </div>
                    </td>
                    <td><span class="p-column-title">{{'by' | translate}}</span>
                        <p-chip *ngIf="destination.image === undefined" label="{{destination.lastUpdated?.user}}"
                            image="assets/layout/images/avatar-placeholder.png">
                        </p-chip>
                        <p-chip *ngIf="destination.image  !== undefined" label="{{destination.lastUpdated?.user}}"
                            [image]="destination.image"></p-chip>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td style="align-items: center;" colspan="6">{{'NoEntryFound' | translate }}.  <a (click)="typeClass.emitObjectAction('CreateNewObject',undefined)">{{'Create new entry' | translate}}</a></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngSwitchCase="'Schedules'">
        <p-table #dt [value]="records" [(selection)]="selectedObject" (onRowSelect)="onSelectAP($event)"
            (onRowUnselect)="onUnselectAP($event)" selectionMode="single" sortMode="single"
            [globalFilterFields]="['schedule.object.id','schedule.connector.Connector','usePsa','age']" [rows]="10"
            paginator=true styleClass="p-datatable-customers p-datatable-sm">

            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" [width]="col.width" [pSortableColumn]="col.field">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{col.header | translate }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>

                        </div>
                    </th>
                    <!-- <th style="width: 8rem" ></th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-schedule let-rowIndex="rowIndex">
                <tr [pSelectableRow]="schedule">
                    <td [pTooltip]="schedule.object.description">
                        <span class="p-column-title">{{'Name' | translate }}</span>
                        <span style="display:flex;align-items: center;">
                            <i class="pi pi-calendar"></i>
                            <span style="margin-left:10px; margin-right: 30px">{{schedule.object.name}}
                            </span>
                            <div class="ui-table-buttongroup-right" *ngIf="schedule == selectedObject" style="float:right">
                                <button style="color: white;" pButton pRipple type="button" icon="pi pi-play"
                                    class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                    pTooltip="{{'StartSchedule' | translate }}"
                                    (click)="onRunScheduleWorkflow(schedule)"></button>
                                    <button *ngFor="let action of actions" style="color: white;"
                                    pButton pRipple type="button" [icon]="action.icon"
                                    [class]="action.styleClass" pTooltip="{{action.tooltip | translate }}"
                                    (click)="action.command()"></button>
                            </div>
                        </span>
                    </td>
                    <td pTooltip="{{'This schedule is currently active' | translate}}" *ngIf="schedule.object.isActive">
                        <span class="p-column-title"></span>
                        <i class="pi pi-circle-on statusIcon" [class.on]="schedule.object.isActive"></i>
                        <span class="image-text statusIcon" [class.on]="schedule.object.isActive">{{'active' |
                            translate}}</span>
                    </td>
                    <td pTooltip="{{'This schedule is currently inactive' | translate}}"
                        *ngIf="!schedule.object.isActive"><span class="p-column-title"></span>
                        <i class="pi pi-circle-off statusIcon" [class.on]="schedule.object.isActive"></i>
                        <span class="image-text statusIcon" [class.on]="schedule.object.isActive">{{'inactive' |
                            translate}}</span>
                    </td>
                    <td><span class="p-column-title">{{'Shared with' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <p-avatarGroup styleClass="mb-3">
                                <p-avatar *ngFor="let p of schedule.shared" [image]="p" size="large" shape="circle"></p-avatar>
                            </p-avatarGroup>
                        </div>
                    </td>
                    <td [pTooltip]="schedule.lastUpdated?.action"><span class="p-column-title">{{'LastUpdated' |
                            translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <span style="margin-right: 10px">{{schedule.lastUpdated?.start | date: 'short'}}</span>
                        </div>
                    </td>

                    <td><span class="p-column-title">{{'by' | translate}}</span>
                            <p-chip *ngIf="schedule.image === undefined" label="{{schedule.lastUpdated?.user}}"
                                image="assets/layout/images/avatar-placeholder.png">
                            </p-chip>
                            <p-chip *ngIf="schedule.image  !== undefined" label="{{schedule.lastUpdated?.user}}"
                                [image]="schedule.image"></p-chip>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td style="align-items: center;" colspan="5">{{'NoEntryFound' | translate }}.  <a (click)="typeClass.emitObjectAction('CreateNewObject',undefined)">{{'Create new entry' | translate}}</a></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngSwitchCase="'PowerBiReports'">
        <p-table #dt [value]="records" [(selection)]="selectedObject" (onRowSelect)="onSelectReport($event)"
            (onRowUnselect)="onUnselectReport($event)" selectionMode="single" sortMode="single"
            [globalFilterFields]="['report.object.id','report.object.title']" [rows]="10"
            paginator=true styleClass="p-datatable-customers p-datatable-sm">

            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" [width]="col.width" [pSortableColumn]="col.field">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{col.header | translate }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>

                        </div>
                    </th>
                    <!-- <th style="width: 8rem" ></th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-report let-rowIndex="rowIndex">
                <tr [pSelectableRow]="report">
                    <td [pTooltip]="report.object.title">
                        <span class="p-column-title">{{'Name' | translate }}</span>
                        <span style="display:flex;align-items: center;">
                            <i class="pi pi-chart-line"></i>
                            <span style="margin-left:10px; margin-right: 30px">{{report.object.title}}
                            </span>
                            <div class="ui-table-buttongroup-right" *ngIf="report == selectedObject && appMain.currentUserFull.UserInfo.IsAdmin === true" style="float:right">
                                    <button *ngFor="let action of actions" style="color: white;"
                                    pButton pRipple type="button" [icon]="action.icon"
                                    [class]="action.styleClass" pTooltip="{{action.tooltip | translate }}"
                                    (click)="action.command()"></button>
                            </div>
                        </span>
                    </td>
                    <!-- <td><span class="p-column-title">{{'Shared with' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <p-avatarGroup styleClass="mb-3">
                                <p-avatar *ngFor="let p of report.shared" [image]="p" size="large" shape="circle"></p-avatar>
                            </p-avatarGroup>
                        </div>
                    </td> -->
                    <!-- <td pTooltip="{{'This schedule is currently active' | translate}}" *ngIf="schedule.object.isActive">
                        <span class="p-column-title"></span>
                        <i class="pi pi-circle-on statusIcon" [class.on]="schedule.object.isActive"></i>
                        <span class="image-text statusIcon" [class.on]="schedule.object.isActive">{{'active' |
                            translate}}</span>
                    </td>
                    <td pTooltip="{{'This schedule is currently inactive' | translate}}"
                        *ngIf="!schedule.object.isActive"><span class="p-column-title"></span>
                        <i class="pi pi-circle-off statusIcon" [class.on]="schedule.object.isActive"></i>
                        <span class="image-text statusIcon" [class.on]="schedule.object.isActive">{{'inactive' |
                            translate}}</span>
                    </td>
                    <td><span class="p-column-title">{{'Shared with' | translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <p-avatarGroup styleClass="mb-3">
                                <p-avatar *ngFor="let p of schedule.permissions" [image]="schedule.image" size="large" shape="circle"></p-avatar>
                            </p-avatarGroup>
                        </div>
                    </td>
                    <td [pTooltip]="schedule.lastUpdated?.action"><span class="p-column-title">{{'LastUpdated' |
                            translate }}</span>
                        <div style="display:flex;align-items: center;">
                            <span style="margin-right: 10px">{{schedule.lastUpdated?.start | date: 'short'}}</span>
                        </div>
                    </td>

                    <td><span class="p-column-title">{{'by' | translate}}</span>
                            <p-chip *ngIf="schedule.image === undefined" label="{{schedule.lastUpdated?.user}}"
                                image="assets/layout/images/avatar-placeholder.png">
                            </p-chip>
                            <p-chip *ngIf="schedule.image  !== undefined" label="{{schedule.lastUpdated?.user}}"
                                [image]="schedule.image"></p-chip>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td style="align-items: center;" colspan="1">{{'NoManagedBiFound' | translate }}{{'NoManagedBiFound2' | translate }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
