import { Component, OnInit } from "@angular/core";
import { ContentItem, NavigationItem } from "src/app/models/helperClass.model";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
	selector: "app-app-tutorial",
	templateUrl: "./app-tutorial.component.html",
	styleUrls: ["./app-tutorial.component.scss"],
})
export class AppTutorialComponent implements OnInit {
	text: any;
	tableOfContent: NavigationItem[];
	introductionContent: NavigationItem[];
	faqContent: NavigationItem[];
	safeURL: SafeResourceUrl;
	videoURL: string = "https://www.youtube.com/embed/LtlLcNi1vqo";

	constructor(private _sanitizer: DomSanitizer) {
		this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
			this.videoURL
		);
	}

	ngOnInit(): void {
		this.introductionContent = this.getIntroductionContent();
		this.tableOfContent = this.getContent();
		this.faqContent = this.getFaqContent();
	}

	onLinkClicked(item: ContentItem) {
		console.log(item);
	}

	getIntroductionContent() {
		return [
			{
				name: "Introduction",
				content: [
					{
						title: "New to Bion?",
						textarea: [
							{
								contentText: [
									"Bion is a cloud-based, no-code data transformation and management platform that enables you blend and transform massive amounts of data from different sources in an easy and efficient way.",
									"Bion is a cloud data platform made up of three parts:",
									"1. High-Performance Data Warehouse",
									"2.	Integrated ETEL (data Extraction, Transformation, Enrich and Loading) Services",
									"3.	Data Automation Services",
									"This means you can store all your data in one place and know that they are always up-to-date, ready to be combined, queried, and prepared for your analysis.",
									"Simplify your data flow through automation and smart data management and thereby empower your organization with consistent and clean data - from anywhere, at any time!.",
									"In this quickstart guide we’ll take you through the Bion Platform and show you how you can connect your first datasource, build a workflow and create a data cube.",
									"Most of your questions will probably arise as soon as you start with a real project. In this situation, you’ll find a lot of answers in the Tutorial- , and in the FAQ-Section on our website. But don’t get stuck in the guides. Feel free to contact us at support@bion-analytics.com . Another way of getting answers to your questions is to explore the workflows (nodes) available in the nodes repository. We are happy to help you there!",
								],
							},
						],
					},
					{
						title: "Our 3-Step-Approach",
						textarea: [
							{
								contentText: [
									"Bion follows a simple three step approach to make your data preparation process as quick and efficient as possible.",
									"Step 1 Connect",
									"Bion enables you to connect all data of your business in one central place by using standardized data connectors. Excel, csv, databases and other formats can be easily connected to our platform. Also keep your data up-to-date by creating individual update schedules for each data source.",
									"Step 2 Enrich",
									"Our data market provides real-time and historical insights from hundreds of public and external  sources  worldwide. Search, find, and choose the right data for your business and add value to your data!",
									"Step 3 Transform",
									"An intuitive, visual workflow environment with a wide range of transformation (nodes) makes data transformation, cleansing and aggregation easy as ever. Combine nodes into individual workflows by drag and drop and automate your cloud dataflow.",
								],
							},
						],
					},
					{
						title: "Sign up process",
						textarea: [
							{
								contentText: [
									'To sign up click on the "Try for Free" on top of the homepage. After completing the form, you will receive an email with your personal activation link. By confirming you have successfully signed up for our free 14 day trial!',
								],
							},
						],
					},
				],
			},
			{
				name: "Data",
				content: [
					{
						title: "Overview",
						textarea: [
							{
								contentText: [
									"Data Sources can be files, databases and external cloud application. Bion supports a variety of data sources such as:",
									"• File-based data sources, such as Excel,CSV and JSON files",
									"• High Performance databases such as AWS, Redshift, Big Query",
									"• Traditional relational databases, such as SQL Server, MySQL or Oracle",
									"• Other services, such as Salesforce, SAP and more.",
								],
							},
						],
					},
					{
						title: "Data Sources",
						textarea: [
							{
								contentHeader: "Connectors",
								contentText: [
									"A connector is a standardized adapter to connect or load data from different sources to Bion.",
									"Non-technical users can enjoy the possibility to work with various data connections in just a few clicks.",
								],
							},
							{
								contentHeader: "Fields",
								contentText: [
									"The Fields section contains the column information (Column Name, Column Datatype etc.) of the data source selected. Data source Fields are defined at the initial creation.",
									"Fields can be categorized into three groups:",
									"Numeric Fields: Numbers (quantified data), such as monthly sales figures. This datatype can be aggregated or calculated.",
									"Descriptive Fields: Items used to label and categorize, such as Products, Locations and Categories.",
									"Date & Time Fields: Date & Time are standardized formats to organize your data into hierarchies according to year, quarter, month, days and hours.",
									'For detailed information, check out our Tutorial section - "Data Types"',
								],
							},
							{
								contentHeader: "Persistance Staging Area",
								contentText: [
									"The PSA is a data pool, which contains all data records that were loaded into the data source. Each row is assigned to an unique data package",
									"The write mode determines how new data uploads are handled in the PSA. For more information see Tutorial –  Section 5  “Write Modes”",
								],
							},
							{
								contentHeader: "Protocol",
								contentText: [
									"The protocol section contains a full history of all changes to the selected data source. Every data upload to the data source is historized in the PSA and therefore ensures full traceability for every row that has ever been loaded into the datasource.",
								],
							},
							{
								contentHeader: "Permission",
								contentText: [
									"In the permissions tab, access permissions to the selected data source are assigned and administered. Here you can specify which users and groups have access to this specific data source and which actions they can perform on its content. Please note that permissions management is only available to the admins of the respective data source.",
								],
							},
						],
					},
					{
						title: "Data Market",
						textarea: [
							{
								contentText: [
									"Bion is a cloud-based, no-code data transformation and management platform that enables you blend and transform massive amounts of data from different sources in an easy and efficient way.",
									"Bion is a cloud data platform made up of three parts:",
									"1. High-Performance Data Warehouse",
									"2.	Integrated ETEL (data Extraction, Transformation, Enrich and Loading) Services",
									"3.	Data Automation Services",
									"This means you can store all your data in one place and know that they are always up-to-date, ready to be combined, queried, and prepared for your analysis.",
									"Simplify your data flow through automation and smart data management and thereby empower your organization with consistent and clean data - from anywhere, at any time!.",
									"In this quickstart guide we’ll take you through the Bion Platform and show you how you can connect your first datasource, build a workflow and create a data cube.",
									"Most of your questions will probably arise as soon as you start with a real project. In this situation, you’ll find a lot of answers in the Tutorial- , and in the FAQ-Section on our website. But don’t get stuck in the guides. Feel free to contact us at support@bion-analytics.com . Another way of getting answers to your questions is to explore the workflows (nodes) available in the nodes repository. We are happy to help you there!",
								],
							},
						],
					},
				],
			},
			{
				name: "Flows",
				content: [
					{
						title: "Workflows",
						textarea: [
							{
								contentText: [
									"The workflows section is the main area where to design individual workflows by using Nodes.  Nodes can perform different kind of data transformation tasks such as sorting, filtering and joining data.",
								],
							},
						],
					},
					{
						title: "Nodes",
						textarea: [
							{
								contentText: [
									"The Nodes Repository provides a comprehensive overview of all nodes that exist in Bion. Available nodes are organized under different categories (Input, Selection, Transformation, Output). For further node information regarding definition and guidance please click on the “details” button.",
								],
							},
						],
					},
					{
						title: "Cubes",
						textarea: [
							{
								contentText: [
									"Every Output node created in the Workflow tab results in a Cube which contains processed and clean data, ready for analysis. The cubes can also be exported in different file formats (e.g. Excel, CSV) or piped to other external applications.",
								],
							},
						],
					},
				],
			},
			{
				name: "Schedule & Jobs",
				content: [
					{
						title: "Schedule",
						textarea: [
							{
								contentText: [
									"Schedule your data pulls (only real-time data) and workflows to run automatically with a selected frequency, date, and time. The Schedules section gives you an overview of all your active and inactive schedules. Here you can add new schedules, edit existing ones, and start or stop running schedules.",
								],
							},
						],
					},
					{
						title: "Jobs",
						textarea: [
							{
								contentText: [
									"The Jobs Section gives an overview of all processes that are currently running in the background. Our job feature allows all users continues working while jobs are processed.",
								],
							},
						],
					},
				],
			},
		];
	}

	getContent() {
		return [
			{
				name: "Data",
				content: [
					{
						title: "Overview",
						img: "Connectors.jpg",
						textarea: [
							{
								contentText: [
									"To connect data from multiple sources to your Bion data warehouse, you must first create a datasource. There are 2 types of source connectors:",
									"1. Push sources: Push sources are files, such as Excel, CSV and JSON files. Those files are often stored locally and need to be updated manually.",
									"2. Pull sources: Those sources are tables from databases such as AWS, PostgreSQL or from other ERP systems such as Salesforce, Oracle. This sources can be scheduled and therefore will be updated accordingly",
									"For a complete list of data sources, please visit our Data Connectors page.",
								],
							},
						],
					},
					{
						title: "Create your first Datasource",
						img: "Create your first Datasource.jpg",
						textarea: [
							{
								contentHeader: "Choose a Connector",
								contentText: [
									'1. To add new data click on "Add Datasource" and select the right connector you want to use.',
									'2. Depending on the selected connector different connector settings needs to be specified. In our example we need to upload the file by clicking the "Browse" button, select the sheet and determine the update mode.',
									"3. After setting up the connector parameters, the datasource can be created. In order to create datasource please choose a name and select the columns to be imported.",
									"4. Click the “Load” button and the datasource will be created. Congratulations! You have successfully created your first Datasource which will now appear in the Datasources section.",
								],
							},
						],
					},
				],
			},
			{
				name: "Flows",
				content: [
					{
						title: "Example Description",
						img: "Workflow Example.jpg",
						textarea: [
							{
								contentText: [
									"Mrs. Mayer is the Head of Finance. For her monthly reporting she receives different files that she aggregates manually in order to create a management dashboard/analysis. Currently, all task are performed manually in Excel. How can it be automated in Bion? Bion uses workflows to automate routines. Those workflows consist of individual nodes that represent specific functions and can be linked to other nodes. By running the workflow all nodes will be executed sequentially.",
									"Let's start with our first workflow. For this exercise please download the example files: [LINK]",
								],
							},
						],
					},
					//    {
					//       "title":"Connect the Datasources",
					//       "textarea":[
					//          {
					//             "contentText":[
					//                "The example workflow in Figure A reads data from an Excel file, filters a subset of the columns, filters out some rows and JOINs the data with another data set from the data market [Link zur Datei im Datamarket].",
					//                " To get started, first add the “sales_test” Excel file that contains the data that we are going to use in the workflow. Please download the file by using the link in the Overview Section. Then go to the Datamarket and add the data set “sales_tutorial” to your Datasources."
					//             ]
					//          }
					//       ]
					//    },
					{
						title: "Create New Workflow",
						img: "Create new workflow.jpg",
						textarea: [
							{
								contentText: [
									'Click on "New Workflow" in the toolbar panel at the top of the Workflow Designer to create a new workflow canvas.',
								],
							},
						],
					},
					{
						title: "INPUT Nodes Settings",
						img: "Input.jpg",
						textarea: [
							{
								contentText: [
									"Every workflow starts with an INPUT Node. Each INPUT Node connects a specific datasource to the workflow.",
									'Click the INPUT Node to open the configuration. There, all available datasource are listed an can be selected. In our example, please select the datasource \'sales_test\' and click on "Save". Please repeat this step for the second dataset "sales_tutorial". In the Data Preview you can view the underlying data of each node.',
								],
							},
						],
					},
					{
						title: "Transformation 1: Add SELECT Node",
						img: "Select.jpg",
						textarea: [
							{
								contentText: [
									"Add the 'SELECT' Node to the workflow canvas and connect it to the INPUT Node ('sales_test'). Now select  all columns in the node configuration and execute the workflow by clicking on run (green play button).",
								],
							},
						],
					},
					//    {
					//       "title":"Transformation 2: Add ROW FILTER Node",
					//       "textarea":[
					//          {
					//             "contentText":[
					//                "Add the ROW FILTER Node to the workflow designer and connect it to the SELECT Node. The ROW FILTER Node removes one or more rows from the input data table according to the filtering criteria. The filtering criteria can be set in the configuration window of the row filter Node. Next, open the configuration dialog of the ROW FILTER Node and exclude rows from the input table where the column \"customer\” has the value \"unknown\".",
					//                "Now that the data has been filtered, let’s move on to joining both Datasources."
					//             ]
					//          }
					//       ]
					//    },
					{
						title: "Transformation 3: Add the JOIN Node",
						img: "Join.jpg",
						textarea: [
							{
								contentText: [
									"Add the JOIN Node to the workflow canvas. The JOIN operation aggregates two tables that share at least one unique identifier (e.g. customer ID). Connect the JOIN Node to the previous node, input Node 2 and to the output Node as shown in the figure below.",
									'Open the configuration dialog of JOIN Node and select the JOIN mode “left JOIN” and the column on which the JOIN should be performed as shown in the figure below. The workflow is finished, the next step is to execute it and view the output. You do this either by clicking the "Execute all executable Nodes” button, or by selecting the output Nodes, right clicking the output Node, and then clicking Execute.',
								],
							},
						],
					},

					{
						title: "OUTPUT Node Settings",
						img: "Output.jpg",
						textarea: [
							{
								contentText: [
									"Each workflow is completed with an OUTPUT Node. This Node creates a Cube that contains processed and clean data raedy for your analysis.",
									"In the configuration, each Datasource Field (Column) needs to be assigned to either a Measure or a Dimension. Measures are numerical values that can be calculated. For example, sales revenue figures are measures since a total or an average value can be determined. On the other hand, dimensions are attributes of your data, e.g. city, segment, product. For more information on Measures and Dimensions please refer to the Chapter – Basic Concepts and Terminology.",
									'In our example exercise, please verify if Measures and Dimensions have been set correctly since those information are required for cube modelling. Run your workflow to create your first Cube! Please go to the "Cubes" tab now.',
								],
							},
						],
					},

					{
						title: "Cubes",
						img: "Cubes.jpg",
						textarea: [
							{
								contentText: [
									"The Cubes serve as a single point of truth for your business. Cubes are multidimensional tables consisting of measures and dimensions. Each cube can be designed individual and contains the final results of your workflow, ready to be analysed. Thanks to the multidimensional structure, data can be easily analysed in different levels of detail. For example, we could create cube that analyzes Sales figures per Country, per Customer and per Product Line. In the Cubes tab you can also manage your own cubes or those that have been shared with you.",
								],
							},
						],
					},
				],
			},

			{
				name: "Schedules & Jobs",
				content: [
					{
						title: "Schedules",
						img: "Schedules.jpg",
						textarea: [
							{
								contentText: [
									"The Bion Scheduler allows you to create and manage schedules for different tasks. Tasks are for instance pulling new data or running workflows. The Schedule dashboard lists all created schedules, including status, type, creator, start and estimated end date.",
								],
							},
						],
					},
					// {
					// 	title: "Tasks",
					// 	img: "Jobs Section.jpg",
					// 	textarea: [
					// 		{
					// 			contentText: [
					// 				"The Tasks dashboard offers details on the current status of scheduled or running tasks. Here, tasks can be managed, e.g. starting, stopping and deleting individual tasks.",
					// 			],
					// 		},
					// 	],
					// },
				],
			},

			{
				name: "Basic Concepts and Terminology in Bion",
				content: [
					{
						title: "Data Types",
						img: "Placeholder.jpg",
						textarea: [
							{
								contentHeader: "Numeric Fields",
								contentText: [
									"Numeric Fields - Numbers (quantitative data), such as monthly sales figures. This datatype can be aggregated or calculated.",
									" -DOUBLE: This data type is used for all numbers with a decimal, such as 9.99. The standard length is 8 bytes which is sufficient for storing up to 15 decimal digits.",
									" -INTEGER: The Integer data type stores whole numbers with no decimal places. The standard length is 4 bytes which can store whole numbers up to a length of 10 digits.",
									"Descriptive Fields: In general descriptive Fields describe qualitative information such as products, locations, categories et cetera. In some cases numeric values are considered as descriptive fields such as product ID or postal codes.",
									"STRING - The String data type is used to store a sequence of characters (text).",
									"Date & Time are standardized formats to organize your data.",
									"DATE - The DATE type is used for values with a date part but no time part. Bion retrieves and displays DATE values in 'YYYY-MM-DD' format. The supported range is '1000-01-01' to '9999-12-31'.",
									"DATETIME - The DATETIME type is used for values that contain both date and time parts. Bion retrieves and displays DATETIME values in 'YYYY-MM-DD hh:mm:ss' format. The supported range is '1000-01-01 00:00:00' to '9999-12-31 23:59:59'.",
									"TIME - Bion retrieves and displays TIME values in 'hh:mm:ss' format (or 'hh:mm:ss' format for large hours values). TIME values describe a time interval ranging from '-838:59:59' to '838:59:59'.",
								],
							},
							
						],
					},
					{
						title: "Primary Keys",
						img: "Primary Key.jpg",
						textarea: [
							{
								contentText: [
									"The Primary key is a Datasource Field which is a unique value within each row of the respective table. Primary keys should never change. Therefore avoid using information such as passport number, social security number, or employee contract number as primary key as these primary key can change for real world situations. In our example 'salesman1', it is required to identify each salesman with a unique code, because salesmen may share the same name loosing its unique character. Therefore, it is not recommanded to create a primary key based on column 'salesman_name'. The column 'salesman_code' is the appropriate match for using as a primary key, since each column value is unique.",
								],
							},
						],
					},

					// {
					//   "title":"Measures & Dimensions",
					//   "img":"Primary Key.jpg",
					//   "textarea":[
					//      {
					//         "contentText":[
					//            "Dimensions and Measures are fundamentals, which are used in data analysis. Therefore,every column is assigned to either a dimension or a measure.",
					//            "A measure is a field that can be calculated, such as a sum or an average. For example, a sales revenue column is a measure because you can calculate a total or an average value. However, please keep in mind that not all numeric columns are measures. For instance, telephone numbers or postal codes are dimensions since it is not plausible to calculate a sum or an average.",
					//            "Dimensions are qualitative and do not total a sum. For example, a sales region, employee, location, or date are dimensions."
					//         ]
					//      }

					//   ]
					// },
				],
			},
		];
	}

	getFaqContent() {
		return [
			{
				name: "General Questions",
				content: [
					{
						title: "What is Bion?",
						textarea: [
							{
								contentText: [
									"Bion is a cloud-based, no-code data transformation and management platform that enables you blend and transform massive amounts of data from different sources in an easy and efficient way. Bion is  made up of three parts:",
									"1. High-Performance Data Warehouse",
									"2. Integrated ETEL (data Extraction, Transformation, Enrich and Loading) Services",
									"3. Data Automation Services",
									"This means you can store all your data in one place and know that they are always up-to-date, ready to be combined, queried, and prepared for your analysis. Simplify your data flow through automation and smart data management and thereby empower your organization with consistent and clean data - from anywhere, at any time!.",
								],
							},
						],
					},
					{
						title: "Do I need to install anything?",
						textarea: [
							{
								contentText: [
									"No! You don't need to install any extra software to use Bion. You can do it all through a web browser. Simply sign-up for a Free Trial, log in to your Account and get started.",
								],
							},
						],
					},
					{
						title: "What web browsers are supported?",
						textarea: [
							{
								contentText: [
									"For the best performance we recommend a HTML5 supported browsers such as:",
									"- Google Chrome",
									"- Mozilla Firefox",
									"- Microsoft Edge",
									"- Safari",
								],
							},
						],
					},
					{
						title: "Which connectors are supported by Bion?",
						textarea: [
							{
								contentText: [
									"Currently, we support file connectors such as Excel and Csv, as well as Databases and other ERP system all standard interfaces (ODBC /JDBC). Please visit the Data Connectors section to get a full list of available connectors.",
								],
							},
						],
					},
					{
						title: "What if the connector i need is not available?",
						textarea: [
							{
								contentText: [
									"If your connector is currently not available, please contact our support team (consulting@bion-analytics.com). We are contineously working on new connectors and we'd be happy to help you!",
								],
							},
						],
					},
					{
						title: "Do you offer customization or configuration services?",
						textarea: [
							{
								contentText: [
									"Yes! Our Team provides customization and configuration assistance. Our customers can describe the requirements and their business needs. We will evaluate the feasibility your request and get back to you as soon as possible. Please contact consulting@bion-analytics.com for more information.",
								],
							},
						],
					},
					{
						title: "Do you offer training for your software?",
						textarea: [
							{
								contentText: [
									"Yes! All users have access to our web documentation (Tutorials, FAQ). Additionally, customers can request a training specific to their organization. Please contact consulting@bion-analytics.com for personalized training sessions.",
								],
							},
						],
					},
					{
						title: "How can i contact you?",
						textarea: [
							{
								contentText: [
									"Bion support is available Monday-Friday from 9am – 5pm CET via E-mail, support@bion-analytics.com. Please leave us a message and we will get back to you shortly.",
								],
							},
						],
					},
				],
			},
			{
				name: "Subscription & Payment",
				content: [
					{
						title: "How does the free trial work?",
						textarea: [
							{
								contentText: [
									'We provide you with a full version of a "standard plan" for 14 days. Thus, you get the full experience of our software during the trial phase, so you know exactly what you\'re getting when you become our customer',
								],
							},
						],
					},
					{
						title:
							"Do i need to enter my credit card info to sign up for the free trial?",
						textarea: [
							{
								contentText: [
									"No, you can sign up for free and use Bion for 14 days without entering your credit card details. At the end of your trial, you will need to pick a subscription plan and enter your payment details.",
								],
							},
						],
					},

					{
						title: "What happens at the end of my free trial?",
						textarea: [
							{
								contentText: [
									'After the 14 day trial you will automatically be downgraded to our "free plan". If you choose to proceed with a paid plan after trial phase, you will need to enter your payment details.',
								],
							},
						],
					},

					{
						title: "What types of pricing plans do you offer?",
						textarea: [
							{
								contentText: [
									"We offer monthly and annual plans. Annual plans can be purchased for a discounted price. For more information please go to our pricing page [LINK].",
								],
							},
						],
					},
					{
						title: "What types of payment do you accept?",
						textarea: [
							{
								contentText: [
									"We accept Debit, Visa and MasterCard, preferably Debit and Visa cards.",
								],
							},
						],
					},
				],
			},

			{
				name: "Security",
				content: [
					{
						title: "How and where are my data stored?",
						textarea: [
							{
								contentText: [
									"We are data experts and committed to keep your data secure. Your data is stored on our cloud servers in Germany. Thus, we are working in accordance with the data protection regulations applicable in Germany and meet the highest security standards and industry best practices. You can find all information on this in our privacy policy.",
								],
							},
						],
					},
					{
						title: "How can I manage my data safely?",
						textarea: [
							{
								contentText: [
									"It is crucial for enterprises to monitor user-level data security to ensure compliance with internal and external application security standards. We provide you with role-based access controls (RBAC) that allow you to grant user-specific permissions on different levels. This enables you to give right access to the right people.",
								],
							},
						],
					},
				],
			},
		];
	}
}
