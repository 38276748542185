import {
    NodeGuiInfo,
    WorkflowNodeGuiInfo,
    WorkflowNodeInfo,
} from "./designer.models";

export class DescriptionLabel {
    Language: string;
    ShortText: string;
    LongText: string[];

    constructor(Language: string, ShortText: string, LongText: string[]) {
        this.Language = Language;
        this.LongText = LongText;
        this.ShortText = ShortText;
    }
}

// export interface EngineInfo {
//     /**
//      * Target Engine
//      */
//     Name: string;
//     /**
//      * Target Node Type, e.g. 'Select'
//      */
//     ClassName: string;
// }
/**
 * Basic Plugin Information
 */
// export interface WorkflowPlugInInfo {
//     Name:string;
//     Ports:Array<WorkflowPortBase>;
// }

// /**
//  * Plugin and Origin Information
//  */
// export interface WorkflowNodeBase extends WorkflowPlugInInfo{
//     Repository:string;
//     Engine:EngineInfo;
// }

/**
 * Describes a workflow node plugin
 */

export class NodeComplexity {
    Level: number;
    LevelDescription: string[];
    
    constructor(Level:number,LevelDescription: string[]) {
        this.Level = Level;
        this.LevelDescription = LevelDescription;
    }
}
export class NodeExample {
    Title: string;
    ExampleText: string[];
    img: string;

    constructor(Title: string,ExampleText: string[],img: string) {
        this.Title = Title;
        this.ExampleText = ExampleText;
        this.img = img;
    }
}

// export interface NodeGuiInfo {
//     Name: string;
//     NameLabel: string;
//     Category: string;
//     Settings: string[];
//     ComplexityLvl: NodeComplexity;
//     UseCases: string[];
//     NodeDescription: DescriptionLabel;
//     Examples?: NodeExample[];
//     IsFavourite: boolean;
// }

// export interface WorkflowPortBase {
//     Name:string;
//     IsInput:boolean;
//     Type:string;
//     IsMulti:boolean;
// }

// export class PortInfo implements WorkflowPortBase{
//     Name:string;
//     IsInput:boolean;
//     Type:string;
//     IsMulti:boolean;

//     constructor(name:string,isInput:boolean, isMulti:boolean=false,type:string=PortInfo.DefaultPort) {
//         this.Name = name;
//         this.IsInput = isInput;
//         this.Type = type;
//         this.IsMulti = isMulti;
//     }

//     static readonly DefaultPort:string = "Default";
// }

// export class WorkflowStyleInfo {
//     static PortStyles() {
//       throw new Error('Method not implemented.');
//     }
//     constructor(name:string, style:string) {
//         this.Name = name;
//         this.Style = style;
//     }
//     Name:String;
//     Style:String;
// }

export class NodePlugInInfos {
    /**
     * Provides additional information, e.g. Tooltips and Use Cases, on the GUI for the Workflow Node Plug-Ins.
     * @returns Node GUI Information
     */
    static getNodeGuiInfo(): NodeGuiInfo[] {
        const raw = [
            //INPUT
            {
                Name: "Bion.DataWarehouse.PsaInput",
                NameLabel: "Datasource Input",
                Category: "In/Out",
                Settings: ["Nodes.PsaInput.Settings1", "Nodes.PsaInput.Settings2"],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["input", "basic node"],
                Examples: [{
                    Title: "Nodes.PsaInput.Title",
                    ExampleText: ["Nodes.PsaInput.ExampleText"],
                    img: "image.png",
                },],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.PsaInput.ShortText",
                    LongText: ["Nodes.PsaInput.LongText"],
                },
                IsFavourite: true,
            },

            {
                Name: "Bion.BE.DataMarketInput",
                NameLabel: "DataMarket Input",
                Category: "In/Out",
                Settings: ["Nodes.DataMarketInput.Settings1", "Nodes.DataMarketInput.Settings2"],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["cube", "output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DataMarketInput.Title",
                        ExampleText: ["Nodes.DataMarketInput.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.DataMarketInput.ShortText",
                    LongText: ["Nodes.DataMarketInput.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.DataStoreInput",
                NameLabel: "Destination Input",
                Category: "In/Out",
                Settings: ["Nodes.DataStoreInput.Settings1", "Nodes.DataStoreInput.Settings2"],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["cube", "output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DataStoreInput.Title",
                        ExampleText: ["Nodes.DataStoreInput.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.DataStoreInput.ShortText",
                    LongText: ["Nodes.DataStoreInput.LongText"
                    ],
                },
                IsFavourite: true,
            },

            {
                Name: "Bion.BE.DataCubeInput",
                NameLabel: "DataCube Input",
                Category: "In/Out",
                Settings: ["Nodes.DataCubeInput.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["cube", "output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DataCubeInput.Title",
                        ExampleText: ["Nodes.DataCubeInput.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.DataCubeInput.ShortText",
                    LongText: ["Nodes.DataCubeInput.LongText"
                    ],
                },
                IsFavourite: false,
            },

            //TRANSFORMATION

            {
                Name: "Bion.Transformation.Select",
                NameLabel: "Select",
                Category: "Transformation",
                Settings: ["Nodes.Select.Settings1", "Nodes.Select.Settings2", "Nodes.Select.Settings3"

                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["data scoping", "selecting column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Select.Title",
                        ExampleText: ["Nodes.Select.ExampleText"
                        ],
                        img: "Selectexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.Select.ShortText",
                    LongText: ["Nodes.Select.LongText"
                    ],
                },
                IsFavourite: true,
                ImageUrls: [{
                    itemImageSrc: 'assets/layout/images/tutorial/Select1.png',
                    alt: 'Nodes.Select.ImageDesc1',
                    title: 'Nodes.Select.ImageTitle1'
                  },{
                    itemImageSrc: 'assets/layout/images/tutorial/Select2.png',
                    alt: 'Nodes.Select.ImageDesc2',
                    title: 'Nodes.Select.ImageTitle2'
                  },
                ],
                VideoUrls: [
                    'https://www.youtube.com/embed/LtlLcNi1vqo', 
                    'https://www.youtube.com/embed/LtlLcNi1vqo'
                ]
            },
            {
                Name: "Bion.Spark.Select",
                NameLabel: "Select",
                Category: "Transformation",
                Settings: ["Nodes.Select.Settings1", "Nodes.Select.Settings2", "Nodes.Select.Settings3"

                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["data scoping", "selecting column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Select.Title",
                        ExampleText: ["Nodes.Select.ExampleText"
                        ],
                        img: "Selectexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.Select.ShortText",
                    LongText: ["Nodes.Select.LongText"
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.BE.AutoSelect",
                NameLabel: "AutoSelect",
                Category: "Transformation",
                Settings: ["Nodes.AutoSelect.Settings1", "Nodes.AutoSelect.Settings2", "Nodes.AutoSelect.Settings3"

                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["data scoping", "selecting column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.AutoSelect.Title",
                        ExampleText: ["Nodes.AutoSelect.ExampleText"
                        ],
                        img: "Selectexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.AutoSelect.ShortText",
                    LongText: ["Nodes.AutoSelect.LongText"
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Spark.AutoSelect",
                NameLabel: "AutoSelect",
                Category: "Transformation",
                Settings: ["Nodes.AutoSelect.Settings1", "Nodes.AutoSelect.Settings2", "Nodes.AutoSelect.Settings3"

                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["data scoping", "selecting column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.AutoSelect.Title",
                        ExampleText: ["Nodes.AutoSelect.ExampleText"
                        ],
                        img: "Selectexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.AutoSelect.ShortText",
                    LongText: ["Nodes.AutoSelect.LongText"
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Transformation.FindAndReplace",
                NameLabel: "Find & Replace",
                Category: "Transformation",
                Settings: ["Nodes.FindAndReplace.Settings1", "Nodes.FindAndReplace.Settings2", "Nodes.FindAndReplace.Settings3", "Nodes.FindAndReplace.Settings4", "Nodes.FindAndReplace.Settings5", "Nodes.FindAndReplace.Settings6", "Nodes.FindAndReplace.Settings7"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["basic node"],
                Examples: [
                    {
                        Title: "Nodes.FindAndReplace.Title",
                        ExampleText: ["Nodes.FindAndReplace.ExampleText"
                        ],
                        img: "FindandReplaceExample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.FindAndReplace.ShortText",
                    LongText: ["Nodes.FindAndReplace.LongText"],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Spark.FindReplace",
                NameLabel: "Find & Replace",
                Category: "Transformation",
                Settings: ["Nodes.FindAndReplace.Settings1", "Nodes.FindAndReplace.Settings2", "Nodes.FindAndReplace.Settings3", "Nodes.FindAndReplace.Settings4", "Nodes.FindAndReplace.Settings5", "Nodes.FindAndReplace.Settings6", "Nodes.FindAndReplace.Settings7"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["basic node"],
                Examples: [
                    {
                        Title: "Nodes.FindAndReplace.Title",
                        ExampleText: ["Nodes.FindAndReplace.ExampleText"
                        ],
                        img: "FindandReplaceExample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.FindAndReplace.ShortText",
                    LongText: ["Nodes.FindAndReplace.LongText"],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Transformation.Transpose",
                NameLabel: "Transpose",
                Category: "Transformation",
                Settings: ["Nodes.Transpose.Settings1", "Nodes.Transpose.Settings2"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "column to row", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Transpose.Title",
                        ExampleText: ["Nodes.Transpose.ExampleText"],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Transpose.ShortText",
                    LongText: [
                        "Nodes.Transpose.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.Spark.Transpose",
                NameLabel: "Transpose",
                Category: "Transformation",
                Settings: ["Nodes.Transpose.Settings1", "Nodes.Transpose.Settings2"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "column to row", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Transpose.Title",
                        ExampleText: ["Nodes.Transpose.ExampleText"],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Transpose.ShortText",
                    LongText: [
                        "Nodes.Transpose.LongText",
                    ],
                },
                IsFavourite: false,
            },
            {
                Name: "Bion.Transformation.Filter",
                NameLabel: "Filter",
                Category: "Transformation",
                Settings: ["Nodes.Filter.Settings1", "Nodes.Filter.Settings2", "Nodes.Filter.Settings3", "Nodes.Filter.Settings4", "Nodes.Filter.Settings5", "Nodes.Filter.Settings6", "Nodes.Filter.Settings7", "Nodes.Filter.Settings8", "Nodes.Filter.Settings9"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["filter", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Filter.Title",
                        ExampleText: ["Nodes.Filter.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Filter.ShortText",
                    LongText: [
                        "Nodes.Filter.LongText",
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Spark.Filter",
                NameLabel: "Filter",
                Category: "Transformation",
                Settings: ["Nodes.Filter.Settings1", "Nodes.Filter.Settings2", "Nodes.Filter.Settings3", "Nodes.Filter.Settings4", "Nodes.Filter.Settings5", "Nodes.Filter.Settings6", "Nodes.Filter.Settings7", "Nodes.Filter.Settings8", "Nodes.Filter.Settings9"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["filter", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Filter.Title",
                        ExampleText: ["Nodes.Filter.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Filter.ShortText",
                    LongText: [
                        "Nodes.Filter.LongText",
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Transformation.Sort",
                NameLabel: "Sort",
                Category: "Transformation",
                Settings: ["Nodes.Sort.Settings1", "Nodes.Sort.Settings2"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Sort.Title",
                        ExampleText: ["Nodes.Sort.ExampleText"
                        ],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.Sort.ShortText",
                    LongText: [
                        "Nodes.Sort.LongText",
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Spark.Sort",
                NameLabel: "Sort",
                Category: "Transformation",
                Settings: ["Nodes.Sort.Settings1", "Nodes.Sort.Settings2"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Sort.Title",
                        ExampleText: ["Nodes.Sort.ExampleText"
                        ],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.Sort.ShortText",
                    LongText: [
                        "Nodes.Sort.LongText",
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.BE.AutoDataType",
                NameLabel: "Auto Datatype",
                Category: "Transformation",
                Settings: ["Nodes.AutoDataType.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "row to column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.AutoDataType.Title",
                        ExampleText: ["Nodes.AutoDataType.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.AutoDataType.ShortText",
                    LongText: [
                        "Nodes.AutoDataType.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.FuzzyMatch",
                NameLabel: "Fuzzy Match",
                Category: "Transformation",
                Settings: ["Nodes.FuzzyMatch.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "row to column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.FuzzyMatch.Title",
                        ExampleText: ["Nodes.FuzzyMatch.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.FuzzyMatch.ShortText",
                    LongText: [
                        "Nodes.FuzzyMatch.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.MultiRowFormula",
                NameLabel: "Multi Row Formula",
                Category: "Transformation",
                Settings: ["Nodes.MultiRowFormula.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["parse data", "running totals", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.MultiRowFormula.Title",
                        ExampleText: ["Nodes.MultiRowFormula.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.MultiRowFormula.ShortText",
                    LongText: [
                        "Nodes.MultiRowFormula.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.MultiColumnFormula",
                NameLabel: "Multi Column Formula",
                Category: "Transformation",
                Settings: ["Nodes.MultiColumnFormula.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["multiple columns", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.MultiColumnFormula.Title",
                        ExampleText: ["Nodes.MultiColumnFormula.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.MultiColumnFormula.ShortText",
                    LongText: [
                        "Nodes.MultiColumnFormula.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.DynamicSelect",
                NameLabel: "Dynamic Select",
                Category: "Transformation",
                Settings: ["Nodes.DynamicSelect.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "row to column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DynamicSelect.Title",
                        ExampleText: ["Nodes.DynamicSelect.ExampleText"],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.DynamicSelect.ShortText",
                    LongText: [
                        "Nodes.DynamicSelect.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.RegEx",
                NameLabel: "RegEx",
                Category: "Transformation",
                Settings: ["Nodes.RegEx.Settings1", "Nodes.RegEx.Settings2", "Nodes.RegEx.Settings3", "Nodes.RegEx.Settings4", "Nodes.RegEx.Settings5", "Nodes.RegEx.Settings6", "Nodes.RegEx.Settings7"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["basic node", "find&replace", "pattern-matching", "parsing"],
                Examples: [
                    {
                        Title: "Nodes.RegEx.Title",
                        ExampleText: ["Nodes.RegEx.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.RegEx.ShortText",
                    LongText: [
                        "Nodes.RegEx.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Pivot",
                NameLabel: "Pivot",
                Category: "Transformation",
                Settings: ["Nodes.Pivot.Settings1", "Nodes.Pivot.Settings2", "Nodes.Pivot.Settings3", "Nodes.Pivot.Settings4"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "row to column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Pivot.Title",
                        ExampleText: ["Nodes.Pivot.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Pivot.ShortText",
                    LongText: ["Nodes.Pivot.LongText"],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.ChangeDatatype",
                NameLabel: "Change Datatype",
                Category: "Transformation",
                Settings: ["Nodes.ChangeDatatype.Settings1","Nodes.ChangeDatatype.Settings2"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "row to column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ChangeDatatype.Title",
                        ExampleText: ["Nodes.ChangeDatatype.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.ChangeDatatype.ShortText",
                    LongText: [
                        "Nodes.ChangeDatatype.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.ConstantValue",
                NameLabel: "Constant Value",
                Category: "Transformation",
                Settings: ["Nodes.ConstantValue.Settings1", "Nodes.ConstantValue.Settings2", "Nodes.ConstantValue.Settings3"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["insert value", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ConstantValue.Title",
                        ExampleText: ["Nodes.ConstantValue.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.ConstantValue.ShortText",
                    LongText: [
                        "Nodes.ConstantValue.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.Spark.ConstantValue",
                NameLabel: "Constant Value",
                Category: "Transformation",
                Settings: ["Nodes.ConstantValue.Settings1", "Nodes.ConstantValue.Settings2", "Nodes.ConstantValue.Settings3"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["insert value", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ConstantValue.Title",
                        ExampleText: ["Nodes.ConstantValue.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.ConstantValue.ShortText",
                    LongText: [
                        "Nodes.ConstantValue.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.DateTime",
                NameLabel: "DateTime",
                Category: "Transformation",
                Settings: ["Nodes.DateTime.Settings1", "Nodes.DateTime.Settings2", "Nodes.DateTime.Settings3", "Nodes.DateTime.Settings4", "Nodes.DateTime.Settings5", "Nodes.DateTime.Settings6", "Nodes.DateTime.Settings7"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["date", "formatting", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DateTime.Title",
                        ExampleText: ["Nodes.DateTime.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.DateTime.ShortText",
                    LongText: [
                        "Nodes.DateTime.LongText1", "Nodes.DateTime.LongText2", "Nodes.DateTime.LongText3",
                    ],
                },
                IsFavourite: true,
            },

            // ENRICHMENT

            {
                Name: "Bion.BE.FxConversion",
                NameLabel: "FX Convert",
                Category: "Enrichment",
                Settings: ["Nodes.FxConversion.Settings1", "Nodes.FxConversion.Settings2", "Nodes.FxConversion.Settings3", "Nodes.FxConversion.Settings4"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["conversion", "finance", "currency", "themed node"],
                Examples: [
                    {
                        Title: "Nodes.FxConversion.Title",
                        ExampleText: ["Nodes.FxConversion.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.FxConversion.ShortText",
                    LongText: [
                        "Nodes.FxConversion.LongText1", "Nodes.FxConversion.LongText2",
                    ],
                },
                IsFavourite: false,
            },
            {
                Name: "Bion.BE.UnitConversion",
                NameLabel: "Unit Convert",
                Category: "Enrichment",
                Settings: ["Nodes.UnitConversion.Settings1", "Nodes.UnitConversion.Settings2", "Nodes.UnitConversion.Settings3"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["conversion", "themed node"],
                Examples: [
                    {
                        Title: "Nodes.UnitConversion.Title",
                        ExampleText: ["Nodes.UnitConversion.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.UnitConversion.ShortText",
                    LongText: [
                        "Nodes.UnitConversion.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.GeoDataAppender",
                NameLabel: "GeoData Appender",
                Category: "Enrichment",
                Settings: ["Nodes.GeoDataAppender.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "row to column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.GeoDataAppender.Title",
                        ExampleText: ["Nodes.GeoDataAppender.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.GeoDataAppender.ShortText",
                    LongText: [
                        "Nodes.GeoDataAppender.LongText",
                    ],
                },
                IsFavourite: false,
            },
            {
                Name: "Bion.BE.Annotate",
                NameLabel: "Annotate",
                Category: "Enrichment",
                Settings: ["Nodes.Annotate.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "row to column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Annotate.Title",
                        ExampleText: ["Nodes.Annotate.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Annotate.ShortText",
                    LongText: [
                        "Nodes.Annotate.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.ImputeValues",
                NameLabel: "Impute Values",
                Category: "Enrichment",
                Settings: ["Nodes.ImputeValues.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["transpose axis", "row to column", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ImputeValues.Title",
                        ExampleText: ["Nodes.ImputeValues.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.ImputeValues.ShortText",
                    LongText: [
                        "Nodes.ImputeValues.LongText",
                    ],
                },
                IsFavourite: false,
            },

            // AGGREGATE & SPLIT
            {
                Name: "Bion.Transformation.Union",
                NameLabel: "Union",
                Category: "Aggregation",
                Settings: [
                    "Nodes.Union.Settings1", "Nodes.Union.Settings2"
                ],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["aggregation", "combine tables", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Union.Title",
                        ExampleText: ["Nodes.Union.ExampleText"
                        ],
                        img: "Unionexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.Union.ShortText",
                    LongText: ["Nodes.Union.LongText"
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Transformation.Join",
                NameLabel: "Join",
                Category: "Aggregation",
                Settings: ["Nodes.Join.Settings1", "Nodes.Join.Settings2", "Nodes.Join.Settings3", "Nodes.Join.Settings4", "Nodes.Join.Settings5", "Nodes.Join.Settings6"
                ],
                ComplexityLvl: {
                    Level: 3,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["aggregation", "combine tables", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Join.Title",
                        ExampleText: ["Nodes.Join.ExampleText"
                        ],
                        img: "Joinexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Join.ShortText",
                    LongText: ["Nodes.Join.LongText1", "Nodes.Join.LongText2", "Nodes.Join.LongText3", "Nodes.Join.LongText4", "Nodes.Join.LongText5", "Nodes.Join.LongText6"],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.Spark.Join",
                NameLabel: "Join",
                Category: "Aggregation",
                Settings: ["Nodes.Join.Settings1", "Nodes.Join.Settings2", "Nodes.Join.Settings3", "Nodes.Join.Settings4", "Nodes.Join.Settings5", "Nodes.Join.Settings6"
                ],
                ComplexityLvl: {
                    Level: 3,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["aggregation", "combine tables", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Join.Title",
                        ExampleText: ["Nodes.Join.ExampleText"
                        ],
                        img: "Joinexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Join.ShortText",
                    LongText: ["Nodes.Join.LongText1", "Nodes.Join.LongText2", "Nodes.Join.LongText3", "Nodes.Join.LongText4", "Nodes.Join.LongText5", "Nodes.Join.LongText6"],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.BE.ColumnSplitter",
                NameLabel: "Split Columns",
                Category: "Aggregation",
                Settings: ["Nodes.ColumnSplitter.Settings1", "Nodes.ColumnSplitter.Settings2", "Nodes.ColumnSplitter.Settings3", "Nodes.ColumnSplitter.Settings4", "Nodes.ColumnSplitter.Settings5", "Nodes.ColumnSplitter.Settings6", "Nodes.ColumnSplitter.Settings7"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["split columns", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ColumnSplitter.Title",
                        ExampleText: ["Nodes.ColumnSplitter.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.ColumnSplitter.ShortText",
                    LongText: [
                        "Nodes.ColumnSplitter.LongText"
                    ],
                },
                IsFavourite: false,
            },
            {
                Name: "Bion.Spark.SplitColumn",
                NameLabel: "Split Columns",
                Category: "Aggregation",
                Settings: ["Nodes.ColumnSplitter.Settings1", "Nodes.ColumnSplitter.Settings2", "Nodes.ColumnSplitter.Settings3", "Nodes.ColumnSplitter.Settings4", "Nodes.ColumnSplitter.Settings5", "Nodes.ColumnSplitter.Settings6", "Nodes.ColumnSplitter.Settings7"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["split columns", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ColumnSplitter.Title",
                        ExampleText: ["Nodes.ColumnSplitter.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.ColumnSplitter.ShortText",
                    LongText: [
                        "Nodes.ColumnSplitter.LongText"
                    ],
                },
                IsFavourite: false,
            },
            {
                Name: "Bion.BE.ColumnAggregator",
                NameLabel: "Aggregate Columns",
                Category: "Aggregation",
                Settings: ["Nodes.ColumnAggregator.Settings1", "Nodes.ColumnAggregator.Settings2", "Nodes.ColumnAggregator.Settings3", "Nodes.ColumnAggregator.Settings4", "Nodes.ColumnAggregator.Settings5", "Nodes.ColumnAggregator.Settings6"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["concatenate columns", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ColumnAggregator.Title",
                        ExampleText: ["Nodes.ColumnAggregator.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.ColumnAggregator.ShortText",
                    LongText: [
                        "Nodes.ColumnAggregator.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.TableSplitter",
                NameLabel: "Table Splitter",
                Category: "Aggregation",
                Settings: ["Nodes.TableSplitter.Settings1", "Nodes.TableSplitter.Settings2"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["split tables", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Tablesplitter.Title",
                        ExampleText: ["Nodes.TableSplitter.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.TableSplitter.ShortText",
                    LongText: [
                        "Nodes.TableSplitter.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.UniqueValueSplitter",
                NameLabel: "Unique Values",
                Category: "Aggregation",
                Settings: ["Nodes.UniqueValueSplitter.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["find unique values", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.UniqueValueSplitter.Title",
                        ExampleText: ["Nodes.UniqueValueSplitter.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.UniqueValueSplitter.ShortText",
                    LongText: [
                        "Nodes.UniqueValueSplitter.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Summarize",
                NameLabel: "Summarize",
                Category: "Aggregation",
                Settings: ["Nodes.Summarize.Settings1", "Nodes.Summarize.Settings2", "Nodes.Summarize.Settings3", "Nodes.Summarize.Settings4", "Nodes.Summarize.Settings5", "Nodes.Summarize.Settings6", "Nodes.Summarize.Settings7", "Nodes.Summarize.Settings8", "Nodes.Summarize.Settings9"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["group values", "aggregation", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Summarize.Title",
                        ExampleText: ["Nodes.Summarize.ExampleText"
                        ],
                        img: "Transposeexample.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.Summarize.ShortText",
                    LongText: [
                        "Nodes.Summarize.LongText1", "Nodes.Summarize.LongText2", "Nodes.Summarize.LongText3", "Nodes.Summarize.LongText4", "Nodes.Summarize.LongText5", "Nodes.Summarize.LongText6", "Nodes.Summarize.LongText7"
                    ],
                },
                IsFavourite: true,
            },

            //CALCULATION

            {
                Name: "Bion.BE.Calculate",
                NameLabel: "Calculate",
                Category: "Calculation",
                Settings: ["Nodes.Calculate.Settings1", "Nodes.Calculate.Settings2", "Nodes.Calculate.Settings3", "Nodes.Calculate.Settings4", "Nodes.Calculate.Settings5"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["calculation", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Calculate.Title",
                        ExampleText: ["Nodes.Calculate.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Summarize.ShortText",
                    LongText: [
                        "Nodes.Calculate.LongText1", "Nodes.Calculate.LongText2"
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.BE.Formula",
                NameLabel: "Formula",
                Category: "Calculation",
                Settings: ["Nodes.Formula.Settings1", "Nodes.Formula.Settings2", "Nodes.Formula.Settings3", "Nodes.Formula.Settings4", "Nodes.Formula.Settings5"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["calculation", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Formula.Title",
                        ExampleText: ["Nodes.Formula.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Summarize.ShortText",
                    LongText: [
                        "Nodes.Calculate.LongText1", "Nodes.Calculate.LongText2"
                    ],
                },
                IsFavourite: true,
            },

            {
                Name: "Bion.BE.Average",
                NameLabel: "Average",
                Category: "Calculation",
                Settings: ["Nodes.Average.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Average.Title",
                        ExampleText: ["Nodes.Average.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Summarize.ShortText",
                    LongText: [
                        "Nodes.Summarize.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Round",
                NameLabel: "Round",
                Category: "Calculation",
                Settings: ["Nodes.Round.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Round.Title",
                        ExampleText: ["Nodes.Round.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Summarize.ShortText",
                    LongText: [
                        "Nodes.Summarize.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Annuity",
                NameLabel: "Annuity",
                Category: "Calculation",
                Settings: ["Nodes.Annuity.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Annuity.Title",
                        ExampleText: ["Nodes.Annuity.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Annuity.ShortText",
                    LongText: [
                        "Nodes.Annuity.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.PresentValue",
                NameLabel: "Present Value",
                Category: "Calculation",
                Settings: ["Nodes.PresentValue.Settings1", "Nodes.PresentValue.Settings2", "Nodes.PresentValue.Settings3", "Nodes.PresentValue.Settings4", "Nodes.PresentValue.Settings5"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.PresentValue.Title",
                        ExampleText: ["Nodes.PresentValue.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.PresentValue.ShortText",
                    LongText: [
                        "Nodes.PresentValue.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.KPI",
                NameLabel: "KPI",
                Category: "Calculation",
                Settings: ["Nodes.KPI.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.KPI.Title",
                        ExampleText: ["Nodes.KPI.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.KPI.ShortText",
                    LongText: [
                        "Nodes.KPI.LongText"
                    ],
                },
                IsFavourite: false,
            },

            //VALIDATION
            {
                Name: "Bion.BE.AddressValidator",
                NameLabel: "Address Validator",
                Category: "Validation",
                Settings: ["Nodes.AddressValidator.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.AddressValidator.Title",
                        ExampleText: ["Nodes.AddressValidator.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.AddressValidator.ShortText",
                    LongText: [
                        "Nodes.AddressValidator.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.RuleCheck",
                NameLabel: "Rule Check",
                Category: "Validation",
                Settings: ["Nodes.RuleCheck.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.RuleCheck.Title",
                        ExampleText: ["Nodes.RuleCheck.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.RuleCheck.ShortText",
                    LongText: [
                        "Nodes.RuleCheck.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.MasterDataCheck",
                NameLabel: "Masterdata Check",
                Category: "Validation",
                Settings: ["Nodes.MasterDataCheck.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.MaterDataCheck.Title",
                        ExampleText: ["Nodes.MasterDataCheck.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.MasterDataCheck.ShortText",
                    LongText: [
                        "Nodes.MasterDataCheck.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.MissingValues",
                NameLabel: "Missing Values",
                Category: "Validation",
                Settings: ["Nodes.MissingValues.Settings1", "Nodes.MissingValues.Settings2", "Nodes.MissingValues.Settings3", "Nodes.MissingValues.Settings4", "Nodes.MissingValues.Settings5", "Nodes.MissingValues.Settings6", "Nodes.MissingValues.Settings7", "Nodes.MissingValues.Settings8", "Nodes.MissingValues.Settings9"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["missing value handling", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.MissingValues.Title",
                        ExampleText: ["Nodes.MissingValues.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.MissingValues.ShortText",
                    LongText: [
                        "Nodes.MissingValues.LongText"
                    ],
                },
                IsFavourite: false,
            },

            //OTHERS

            {
                Name: "Bion.BE.Compare",
                NameLabel: "Compare",
                Category: "Others",
                Settings: ["Nodes.Compare.Settings1", "Nodes.Compare.Settings2"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["table comparision", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Compare.Title",
                        ExampleText: ["Nodes.Compare.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Compare.ShortText",
                    LongText: [
                        "Nodes.Compare.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Anonymize",
                NameLabel: "Anonymize",
                Category: "Others",
                Settings: ["Nodes.Anonymize.Settings1", "Nodes.Anonymize.Settings2", "Nodes.Anonymize.Settings3", "Nodes.Anonymize.Settings4"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["data security", "anonymization", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Anomymize.Title",
                        ExampleText: ["Nodes.Anonymize.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Anonymize.ShortText",
                    LongText: [
                        "Nodes.Anonymize.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Sampling",
                NameLabel: "Sampling",
                Category: "Others",
                Settings: ["Nodes.Sampling.Settings1", "Nodes.Sampling.Settings2", "Nodes.Sampling.Settings3", "Nodes.Sampling.Settings4", "Nodes.Sampling.Settings5", "Nodes.Sampling.Settings6", "Nodes.Sampling.Settings7", "Nodes.Sampling.Settings8", "Nodes.Sampling.Settings9", "Nodes.Sampling.Settings10", "Nodes.Sampling.Settings11", "Nodes.Sampling.Settings12", "Nodes.Sampling.Settings13", "Nodes.Sampling.Settings14", "Nodes.Sampling.Settings15", "Nodes.Sampling.Settings16", "Nodes.Sampling.Settings17"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sampling", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Sampling.Title",
                        ExampleText: ["Nodes.Sampling.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Sampling.ShortText",
                    LongText: [
                        "Nodes.Sampling.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Encryption",
                NameLabel: "Encrypt",
                Category: "Others",
                Settings: ["Nodes.Encrypt.Settings1", "Nodes.Encrypt.Settings2", "Nodes.Encrypt.Settings3", "Nodes.Encrypt.Settings4"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["data security", "encryption", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Encrypt.Title",
                        ExampleText: ["Nodes.Encrypt.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Encrypt.ShortText",
                    LongText: [
                        "Nodes.Encrypt.LongText",
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.CountRecords",
                NameLabel: "Count Records",
                Category: "Others",
                Settings: ["Nodes.CountRecords.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.CountRecords.Title",
                        ExampleText: ["Nodes.CountRecords.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.CountRecords.ShortText",
                    LongText: [
                        "Nodes.CountRecords.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.FieldInfo",
                NameLabel: "Field Info",
                Category: "Others",
                Settings: ["Nodes.FieldInfo.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.FieldInfo.Title",
                        ExampleText: ["Nodes.FieldInfo.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.FieldInfo.ShortText",
                    LongText: [
                        "Nodes.FieldInfo.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Forecast",
                NameLabel: "Forecast",
                Category: "Others",
                Settings: ["Nodes.Forecast.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Forecast.Title",
                        ExampleText: ["Nodes.Forecast.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Forecast.ShortText",
                    LongText: [
                        "Nodes.Forecast.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.RandomSampling",
                NameLabel: "Random Sampling",
                Category: "Others",
                Settings: ["Nodes.RandomSampling.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.RandomSampling.Title",
                        ExampleText: ["Nodes.RandomSampling.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.RandomSampling.ShortText",
                    LongText: [
                        "Nodes.RandomSampling.LongText"
                    ],
                },
                IsFavourite: false,
            },

            
            {
                Name: "Bion.BE.EmailValidation",
                NameLabel: "Email Validation",
                Category: "Others",
                Settings: ["Nodes.EmailValidation.Settings1","Nodes.EmailValidation.Settings2"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.EmailValidation.Title",
                        ExampleText: ["Nodes.EmailValidation.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.EmailValidation.ShortText",
                    LongText: [
                        "Nodes.EmailValidation.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.GenderCheck",
                NameLabel: "Gender Check",
                Category: "Transformation",
                Settings: ["Nodes.GenderCheck.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.GenderCheck.Title",
                        ExampleText: ["Nodes.GenderCheck.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.GenderCheck.ShortText",
                    LongText: [
                        "Nodes.GenderCheck.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.Trim",
                NameLabel: "Trim",
                Category: "Transformation",
                Settings: ["Nodes.Trim.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Trim.Title",
                        ExampleText: ["Nodes.Trim.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Trim.ShortText",
                    LongText: [
                        "Nodes.Trim.LongText"
                    ],
                },
                IsFavourite: false,
            },
            {
                Name: "Bion.BE.ConvertCase",
                NameLabel: "Convert Case",
                Category: "Transformation",
                Settings: ["Nodes.ConvertCase.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ConvertCase.Title",
                        ExampleText: ["Nodes.ConvertCase.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.ConvertCase.ShortText",
                    LongText: [
                        "Nodes.ConvertCase.LongText"
                    ],
                },
                IsFavourite: false,
            },
            {
                Name: "Bion.BE.LimitRows",
                NameLabel: "Limit Rows",
                Category: "Transformation",
                Settings: ["Nodes.LimitRows.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.LimitRows.Title",
                        ExampleText: ["Nodes.LimitRows.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.LimitRows.ShortText",
                    LongText: [
                        "Nodes.LimitRows.LongText"
                    ],
                },
                IsFavourite: false,
            },


            {
                Name: "Bion.BE.RemoveDuplicates",
                NameLabel: "Remove Duplicates",
                Category: "Others",
                Settings: ["Nodes.RemoveDuplicates.Settings"
                ],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["sort", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.RemoveDuplicates.Title",
                        ExampleText: ["Nodes.RemoveDuplicates.ExampleText"
                        ],
                        img: "convert.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.RemoveDuplicates.ShortText",
                    LongText: [
                        "Nodes.RemoveDuplicates.LongText"
                    ],
                },
                IsFavourite: false,
            },

            // OUTPUTS
            {
                Name: "Bion.Pipeline.Preview.PlugIn",
                NameLabel: "Preview",
                Category: "In/Out",
                Settings: ["Nodes.Preview.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["cube", "output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.Preview.Title",
                        ExampleText: ["Nodes.Preview.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.Preview.ShortText",
                    LongText: ["Nodes.Preview.LongText"],
                },
                IsFavourite: true,
            },

            {
                Name: "Bion.DataWarehouse.DataStoreOutput",
                NameLabel: "Destination Output",
                Category: "In/Out",
                Settings: ["Nodes.DataStoreOutput.Settings1", "Nodes.DataStoreOutput.Settings2"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DataStoreOutput.Title",
                        ExampleText: ["Nodes.DataStoreOutput.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.DataStoreOutput.ShortText",
                    LongText: ["Nodes.DataStoreOutput.LongText"
                    ],
                },
                IsFavourite: true,
            },
            {
                Name: "Bion.BE.DataCubeOutput",
                NameLabel: "DataCube Output",
                Category: "In/Out",
                Settings: ["Nodes.DataCubeOutput.Settings1", "Nodes.DataCubeOutput.Settings2"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["cube", "output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DataCubeOutput.Title",
                        ExampleText: ["Nodes.DataCubeOutput.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.DataCubeOutput.ShortText",
                    LongText: ["Nodes.DataCubeOutput.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.ExportFileOutput",
                NameLabel: "Export File",
                Category: "In/Out",
                Settings: ["Nodes.ExportFileOutput.Settings1", "Nodes.ExportFileOutput.Settings2"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["file export", "output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.ExportFileOutput.Title",
                        ExampleText: ["Nodes.ExportFileOutput.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.ExportFileOutput.ShortText",
                    LongText: ["Nodes.ExportFileOutput.LongText"],
                },
                IsFavourite: true,
            },

            {
                Name: "Bion.BE.DBOutput",
                NameLabel: "Database Output",
                Category: "In/Out",
                Settings: ["Nodes.DBOutput.Settings1", "Nodes.DBOutput.Settings2"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["cube", "output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DBOutput.Title",
                        ExampleText: ["Nodes.DBOutput.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.DBOutput.ShortText",
                    LongText: ["Nodes.DBOutput.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.BE.DistributeOutput",
                NameLabel: "Distribute Output",
                Category: "In/Out",
                Settings: ["Nodes.DistributeOutput.Settings"],
                ComplexityLvl: {
                    Level: 2,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["cube", "output", "basic node"],
                Examples: [
                    {
                        Title: "Nodes.DistributeOutput.Title",
                        ExampleText: ["Nodes.DistributeOutput.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText:
                        "Nodes.DistributeOutput.ShortText",
                    LongText: ["Nodes.DistributeOutput.LongText"
                    ],
                },
                IsFavourite: false,
            },

            {
                Name: "Bion.DataWarehouse.ExcelOutTest",
                NameLabel: "Excel Export",
                Category: "In/Out",
                Settings: ["Nodes.ExcelOutTest.Settings"],
                ComplexityLvl: {
                    Level: 1,
                    LevelDescription: ["This is a node", "Super Simple Stuff"],
                },
                UseCases: ["export", "output", "download as file"],
                Examples: [
                    {
                        Title: "Nodes.ExcelOutTest.Title",
                        ExampleText: ["Nodes.ExcelOutTest.ExampleText"],
                        img: "image.png",
                    },
                ],
                NodeDescription: {
                    Language: "EN",
                    ShortText: "Nodes.ExcelOutTest.ShortText",
                    LongText: ["Nodes.ExcelOutTest.LongText"
                    ],
                },
                IsFavourite: false,
            },
        ];

        return raw;
    }

    /**
     * Enriches Backend Plug-In information with GUI node information
     * @param workflowNodeInfo Backend Plug-In Info
     * @param nodeGuiInfo GUI Plug-In Info
     * @returns Enriched Plug-In Info
     */
    static getWorkflowNodeGuiInfo(
        workflowNodeInfo: WorkflowNodeInfo[],
        nodeGuiInfo: NodeGuiInfo[]
    ): WorkflowNodeGuiInfo[] {
        // Prepare maps for quick assignment
        const nodeInfoMap = new Map<string, WorkflowNodeInfo>();
        workflowNodeInfo.forEach((v) => nodeInfoMap.set(v.Name, v));

        const nodeGuiInfoMap = new Map<string, NodeGuiInfo>();
        nodeGuiInfo.forEach((v) => nodeGuiInfoMap.set(v.Name, v));

        const result = new Array<WorkflowNodeGuiInfo>();
        for (const wfi of workflowNodeInfo) {
            const entry = new WorkflowNodeGuiInfo();

            // Add backend info
            entry.Engine = wfi.Engine;
            entry.Name = wfi.Name;
            entry.Ports = wfi.Ports;
            entry.Repository = wfi.Repository;

            // If available, add gui info
            const targetGuiInfo = nodeGuiInfoMap.get(entry.Name);
            if (targetGuiInfo) {
                entry.Category = targetGuiInfo.Category;
                entry.ComplexityLvl = targetGuiInfo.ComplexityLvl;
                entry.Examples = targetGuiInfo.Examples;
                entry.NameLabel = targetGuiInfo.NameLabel;
                entry.NodeDescription = targetGuiInfo.NodeDescription;
                entry.Settings = targetGuiInfo.Settings;
                entry.UseCases = targetGuiInfo.UseCases;
                entry.IsFavourite = targetGuiInfo.IsFavourite;
                entry.ImageUrls = targetGuiInfo.ImageUrls;
                entry.VideoUrls = targetGuiInfo.VideoUrls;
                result.push(entry);
            }
        }

        return result;
    }
}
