import { Component, OnInit } from '@angular/core';
import { SourceDefinition } from 'src/app/services/airbyte-staging.service';
import  dummySourceDefinitions from './source-definitions.json';
import { Observable, of } from 'rxjs';
import { ConfigService } from './config.service';
import { ConfigItem } from './config-item';


/**
 * Erstellt eine neue Airbyte Source. Sie ist zugleich die Host-Komponente für die Config-Views, die von
 * CreateAbSourceConfigComponent verwaltet werden.
 *
 * ACHTUNG: Das hier entspricht NICHT (!!) der Ad Banner Component. Die hier liefert nur die ConfigItems, die von
 * CreateAbSourceConfigComponent benutzt werden.
 */
@Component({
  selector: 'app-create-ab-source',
  templateUrl: './create-ab-source.component.html',
  styleUrls: ['./create-ab-source.component.scss'],
  providers: [ConfigService]
})
export class CreateAbSourceComponent implements OnInit {

  //connectorViews:Map<string,string>;            // Config View Mappings

  sourceDefinitions:SourceDefinition[] = [];    // Airbyte Source / Connector Definitions
  sourceDefinitionSelected?:SourceDefinition;
  configs:ConfigItem[] = [];

  sourceName:string = "";                       // Source Parameters

  // https://angular.io/guide/dynamic-component-loader

  constructor(private configService:ConfigService) {
    //this.connectorViews = this.getConnetorViews();
  }

  ngOnInit(): void {

    this.sourceDefinitions = this.getSourceDefinitionsDummy();
    this.configs = this.configService.getViewItems();

    // -- This component contains:
    // 1. Connector Selector
    // 2. Set Name
    // 3. Config

    // -- Initialize components


  }

  /**
   * Erzeugt die Airbyte Source
   */
  onCreate() {

    try {
          // control check
          if(this.sourceDefinitionSelected) {
            this.createSourceDummy(this.sourceDefinitionSelected.sourceDefinitionId, null);
          } else {
            throw new Error("No source definition selected, please select one.");
          }

          // api call

    } catch(e) {
      this.onHandleError(e);
    }
  }

  /**
   * Creates a new Airbyte Source - DUMMY
   * @param connectorId Source Connector ID
   * @param config Config
   * @returns true or an exception
   */
  createSourceDummy<T>(connectorId:string, config:T) : Observable<boolean> {
    console.log("API CALL DUMMY");
    console.log("Connector", connectorId);
    console.log("Config", config);

    return of(true);
  }

  onHandleError(e:Error) {
    // handle error here... maybe enhance this :-)
    console.log(e);
  }

  // /**
  //  * Liefert die Config Views für die Airbyte Sources.
  //  * Die ID wird von Airbyte festgelegt, eine übersicht aller IDs gibt es im Service mit der Funktion 'getSourceDefinitions'.
  //  * Alternativ kann man die eigene Airbyte Instanz nutzen oder deren API direkt anfunken.
  //  * @returns L
  //  */
  // getConnetorViews() : Map<string,string> {

  //   const m = new Map<string,string>();

  //   // create the view mapping here:
  //   m.set('aaaa-bbbb-cccc-dddd-eeee', 'app-ab-conn-google-sheets');

  //   return m;
  // }

  /**
   * Source definitions from a JSON file, later replaced by API call.
   * @returns
   */
  getSourceDefinitionsDummy() : SourceDefinition[] {
    const data:SourceDefinition[] = <SourceDefinition[]>dummySourceDefinitions.sourceDefinitions;
    return data;
  }
}
