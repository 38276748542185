import { Component, Input, OnInit } from '@angular/core';

import { TableStats } from 'src/app/models/designer.models';

@Component({
  selector: 'app-column-stats',
  templateUrl: './column-stats.component.html',
  styleUrls: ['./column-stats.component.scss']
})
export class ColumnStatsComponent implements OnInit {
  @Input() col: any;
  @Input() tableStats?: TableStats;

  columnData: any[] = [];
  columnHeaders: string[] = [];

  uniquePercentageValue: number = 100;
  validPercentageValue: number = 100;

  constructor() { }

  ngOnInit(): void {
    this.createStats();

  }


  createStats() {

    let colName = this.col.header;
    //console.log(colName);

    if (this.tableStats !== undefined) {
      let columnStats = this.tableStats.ColumnStats;
      let tableStats = this.tableStats.TableStats;
      //console.log(tableStats);

      if (tableStats.Data === undefined) return;

      let tableStatsFiltered = tableStats.Data.filter((row) => {
        return row[0] === colName
      });
      //console.log(tableStatsFiltered);

      this.uniquePercentageValue = parseInt((parseInt(tableStatsFiltered[0][3]) / parseInt(tableStatsFiltered[0][1]) * 100).toFixed(2));
      //console.log(this.uniquePercentageValue);

      let validEntries = (parseInt(tableStatsFiltered[0][1]) - parseInt(tableStatsFiltered[0][2]));
      this.validPercentageValue = parseInt((validEntries / parseInt(tableStatsFiltered[0][1]) * 100).toFixed(2));
      //console.log(validEntries);

      //console.log(this.validPercentageValue);



      let columnStatsFiltered = columnStats.Data.filter((row) => {

        return row[0] === colName
      });

      this.columnData = columnStatsFiltered[0].slice(1).slice(0, -1);

      let columnFieldInfos = columnStats.MetaData.FieldsInfo;
      let columnHeaders = columnFieldInfos.map((entry) => {
        return entry.Name;
      })
      this.columnHeaders = columnHeaders.slice(1).slice(0, -1);

      //console.log(this.columnData);


    }

  }
}
