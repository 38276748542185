import { Component, Input, OnInit } from "@angular/core";
import { ExceptionInfo } from "src/app/models/designer.models";

@Component({
	selector: "app-nodes-errors",
	templateUrl: "./nodes-errors.component.html",
	styleUrls: ["./nodes-errors.component.scss"],
})
export class NodesErrorsComponent implements OnInit {
	chartData: any;
	chartOptions: any;
	@Input() toggleView: boolean = false;
	@Input() errorLogResult: [string, ExceptionInfo] = ["", new ExceptionInfo()];
	@Input() updateSettingsStatus: boolean;
	@Input() runWorkflowStatus: boolean;

	errorStatesCols: any = [];
	constructor() {}

	ngOnInit(): void {
		this.errorStatesCols = [
			{ field: "NodeID", header: "Node", width: "20%" },
			{ field: "Message", header: "Message", width: "80%" },
		];
	}

	initChart(errors?: any[]) {
		let data = errors;
		let typesArray = [];

		let dataArray: number[] = [];

		if (data !== undefined) {
			let typesArray = data.map((a) => {
				return a.Extension;
			});
			let typesArraySet = new Set(typesArray);
			let size = typesArraySet.size;
			let typesArrayUnique = typesArraySet.values();


			data.map((value) => {
				// if (value. === "Succeeded") {
				// 	succeedNumber = succeedNumber + 1;
				// }
				// if (value.Message === "Failed") {
				// 	failedNumber = failedNumber + 1;
				// }
			});
		}

		//dataArray = [failedNumber, succeedNumber];

		let chartData = {
			labels: typesArray,
			datasets: [
				{
					data: dataArray,
					backgroundColor: ["#FF6384", "#36A2EB"],
					hoverBackgroundColor: ["#FF6384", "#36A2EB"],
				},
			],
		};
		this.chartData = chartData;
		this.chartOptions = {
			plugins: {
				legend: {
					labels: {
						fontColor: "#A0A7B5",
					},
					display: false,
				},
			},
		};
	}
}
