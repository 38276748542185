// import { Accessible, PermissionBase, PlayPermission, RightHolder } from "./authorization.model";
import { Accessible } from "./api/models/authorization/Accessible";
import { PermissionBase } from "./api/models/authorization/PermissionBase";
import { PlayPermission } from "./api/models/authorization/PermissionLike";
import { RightHolder } from "./api/models/authorization/RightHolder";
import { DynamicItemInfo } from "./com-bion-json";

export class SourceSystem {
	id: number;
	name: string;
}

// export class DataSource {
// 	id: number;
// 	name: string;
// 	sourceSystem: number;
// 	age: boolean;
// 	usePsa: boolean;
// 	psatablename?: string;
// }

export class DataSource {
	id: number;
	name: string;
	sourceSystem: number;
	age: boolean;
	usePsa: boolean;
	description?: string;
	constructor(id: number, name: string, sourceSystem: number, age: boolean, usePsa: boolean, description?: string) {
		if(id === undefined) throw new Error( "Class 'DataSource': Required value 'id' is undefined" );
		if(name === undefined) throw new Error( "Class 'DataSource': Required value 'name' is undefined" );
		if(sourceSystem === undefined) throw new Error( "Class 'DataSource': Required value 'sourceSystem' is undefined" );
		if(age === undefined) throw new Error( "Class 'DataSource': Required value 'age' is undefined" );
		if(usePsa === undefined) throw new Error( "Class 'DataSource': Required value 'usePsa' is undefined" );
		this.id = id;
		this.name = name;
		this.sourceSystem = sourceSystem;
		this.age = age;
		this.usePsa = usePsa;
		this.description = description;
	}
}

// export class DataSourceConnector {
//     dataSource: number;
//     connector: string;
//     settings: Array<number>;
// }

export class CreateDataSourceArg {
	name: string;
	sourceSystem: number;
	isRealTime: boolean;
	usePsa: boolean;
	psaTableAutoName: boolean;
	psaTable: string;
	psaWriteMode: string;
	Columns: FieldInfo[];
	ConnectorInfo: DynamicItemInfo;
	FieldMapping?: FieldMapping;
}

export class CreateDataSourceResult {
	DataSource: DataSource;
	Fields: DataSourceField[];
	ConnectorInfo: DynamicItemInfo;
}

export class PushDataSourceArg {
	DataSourceID: number;
	Base64FileData: string;
}

export class createDataSourceFields {
	name: String;
	dataSource: number;
	position: number;
	dataType: String;
	length: number;
	precision: number;
	isKey: Boolean;
}
export class CreateDataSourceFieldsArg {
	DataSource: number;
	Fields: Array<FieldInfo>;
}

export interface PsaBaseField {
	Position: number;
}

export class PsaDataSourceField implements PsaBaseField {
	DataSourceField: number;
	Name: string;
	DataType: DataType;
	IsKey: boolean;
	Position: number;
}

export class PsaMetaInfoField implements PsaBaseField {
	PsaInfoField: number;
	Name: string;
	DataType: DataType;
	IsKey: boolean;
	Position: number;
	Role: string;
}

export class PSA {
	ID: number;
	Name: string;
	WriteMode: string;
	Table: string;
	constructor(ID: number, Name: string, WriteMode: string, Table: string) {
		if(ID === undefined) throw new Error( "Class 'PSA': Required value 'ID' is undefined" );
		if(Name === undefined) throw new Error( "Class 'PSA': Required value 'Name' is undefined" );
		if(WriteMode === undefined) throw new Error( "Class 'PSA': Required value 'WriteMode' is undefined" );
		if(Table === undefined) throw new Error( "Class 'PSA': Required value 'Table' is undefined" );
		this.ID = ID;
		this.Name = Name;
		this.WriteMode = WriteMode;
		this.Table = Table;
	}
}

export class CreatePsaArg {
	DataSource: number;
	PsaTableAutoName: boolean;
	PsaTable: string;
	PsaWriteMode: string;
}

export class CreatePsaResult {
	PSA: PersistentStagingArea;
	Table: string;
	Fields: Array<PsaBaseField>;
	DataSourceFields: Array<PsaDsField>;
	InfoFields: Array<PsaInfoFieldsRow>;
}

export class CreateDataSourceConnectorArg {
	Info: DynamicItemInfo;
	DataSource: number;
}

/**
 * Top level argument for a business API call containing the target function and its argument
 * @param Context Function context (not in use yet)
 * @param Name Function name
 * @param Argument Function argument
 */
export class FunctionCall {
	Context: string;
	Name: string;
	Argument: any;
}

/**
 * Push Arguments
 * @param Connector Connector ID
 * @param Base64FileData Base64 encoded file data
 * @param ConnectorSettings Individual connector parameters
 * @param LoadSettings Data fetch options
 * @param DynamicItemInfo Data fetch optionsApiBackendService
 */
export class PushDataArg {
	Connector: string;
	Base64FileData: string;
	ConnectorSettings: any;
	LoadSettings: LoadSettings;
}

// export class ExtractFromConnectorArg {
// 	ConnectorInfo: DynamicItemInfo;
// 	LoadSettings: LoadSettings;
// 	Base64FileData?: string;
// 	FieldSelection?: Array<FieldInfo>;
// }

export class FieldInfoSelect implements FieldInfoLike {
	Name: string;
	DataType: DataType;
	IsKey: boolean;
	NewName?: string; 
}



export class ExtractFromConnectorArg {
	ConnectorInfo: DynamicItemInfo;
	LoadSettings: LoadSettings;
	Base64FileData?: string;
	FieldSelection?: Array<FieldInfoSelect>;
	
	constructor(ConnectorInfo: DynamicItemInfo, LoadSettings: LoadSettings, Base64FileData?: string, FieldSelection?: Array<FieldInfoSelect>) {
		if(ConnectorInfo === undefined) throw new Error( "Class 'ExtractFromConnectorArg': Required value 'ConnectorInfo' is undefined" );
		if(LoadSettings === undefined) throw new Error( "Class 'ExtractFromConnectorArg': Required value 'LoadSettings' is undefined" );
		this.ConnectorInfo = ConnectorInfo;
		this.LoadSettings = LoadSettings;
		this.Base64FileData = Base64FileData;
		this.FieldSelection = FieldSelection;
	}
}

// step 1 ExtractFromConnector(FieldSelection = null) -> InitialFields
// step 2 ExtractFromConnector(FieldSelection = selection)

// ExtractFromConnectorArg(ConnectorInfo:DynamicItemInfo, LoadSettings: LoadSettings, Base64FileData:Option[String])

// == META Extraction

// export class ExtractMetaFromConnectorArg {
//     ConnectorMetaInfo: DynamicItemInfo;
//     Base64FileData?: string;
// }

export class ExtractMetaFromConnectorResult {
	MetaData: any;
}

// /**
//  * Excel Sheet Information
//  */
// export class ExcelMetaAccess {
// 	Info: string[];
// }

export class SheetInfo {
	Index: number;
	Name: string;
	IsHidden: boolean;
}

// /**
//  * Excel Data Source Meta Information Result
//  */
// export class ExcelMetaInfo {
// 	Sheets: SheetInfo[];
// }

// == META Extraction - END

/**
 * Source contains named tables
 */
interface TableNavigation {
	Table: string;
}

interface ColumnDetectionSettings {
	DetectColumnTypes: boolean;
	UseDetectLimit: boolean;
	DetectLimit: number;
}

// /**
//  * Excel connector settings
// */
// export class ExcelConnectorSettings implements TableNavigation, ColumnDetectionSettings {
//     Table: string;
//     DetectColumnTypes: boolean;
//     UseDetectLimit: boolean;
//     DetectLimit: number;
//     // DetectColumnTypes:Boolean, UseDetectLimit:Boolean, DetectLimit:Int
// }

// /**
//  * CSV connector settings
// */
// export class CsvMetaInfo { }
// export class CsvPushSettings implements ColumnDetectionSettings {
//     Separator: string;
//     // DetectColumnTypes:Boolean, UseDetectLimit:Boolean, DetectLimit:Int
//     DetectColumnTypes: boolean;
//     UseDetectLimit: boolean;
//     DetectLimit: number;
// }

// // case class JdbcAccess(URL:String, User:String, Password:String, Table:String) extends TableNavigation
// export class JdbcAccess implements TableNavigation {
//     URL: string;
//     User: string;
//     Password: string;
//     Table: string;
// }

// /**
//  * JSON connector settings
// */
// export class JsonConnectorSettings implements TableNavigation {
//     Table: string;
// }

/**
 * Settings for the load job
 * @param UseLimit Use Row Limit
 * @param Limit Row Limit Count
 */
export class LoadSettings {
	constructor(useLimit:boolean, limit:number) {
		this.UseLimit = useLimit;
		this.Limit = limit;
	}
	UseLimit: boolean;
	Limit: number;
}

export class ColumnInfo {
	Name: string;
	Type: DataType;
}

export class DataPreview {
	Columns: string[];
	Rows: string[][];
}

export class DataTypePreview {
	Columns: FieldInfo[];
	Rows: string[][];
}

export class ExtractFromConnectorResult{
	Columns: FieldInfoSelect[];
	Rows: string[][];
}

export class DataType {
	Name: string;
	Length: number;
	Format: string;
	FixedSize: boolean;
}

export interface FieldInfoLike {
	Name: string;
	DataType: DataType;
	IsKey: boolean;
}

export class FieldInfo implements FieldInfoLike {
	Name: string;
	DataType: DataType;
	IsKey: boolean;
}




export class TableFieldInfo {
	Name: string;
	DataType: DataType;
	Position: number;
	IsKey: boolean;
	NameInTable: string;
}

export class Field {
	Name: string;
	FieldType: DataType;
	Value: any;
}

export class DataSourceField {
	id: number;
	name: string;
	dataSource: number;
	position: number;
	dataType: string;
	length: number;
	format: string;
	isFixedSize: boolean;
	precision: number;
	isKey: boolean;
	nameInPsa: string;
}

export class ExtractDataToPsaArg {
	DataSourceID: number;
	Base64FileData?: string;
	WriteMode?: string;
	Simulate: boolean;
	ConnectorSettings?: DynamicItemInfo;
}

export class ExtractDataToPsaResult {
	Data: DataTypePreview;
	DataPackage: number;
}

export interface ScalaEnumeration {
	enumClass: string;
	value: string;
}

export class DataSourceAdapter {
	ID: string;
	Name: string;
	Version: number;
	ConnectorType: any;
	ArgumentType: any;
	IsPullSource: boolean;
	Category: ScalaEnumeration;
}

export class DataSourceConnector<C> {
	DataSource: number;
	Connector: string;
	settings: C;
	constructor(DataSource: number, Connector: string, settings: C) {
		if (DataSource === undefined)
			throw new Error(
				"Class 'DataSourceConnector': Required value 'DataSource' is undefined"
			);
		if (Connector === undefined)
			throw new Error(
				"Class 'DataSourceConnector': Required value 'Connector' is undefined"
			);
		if (settings === undefined)
			throw new Error(
				"Class 'DataSourceConnector': Required value 'settings' is undefined"
			);
		this.DataSource = DataSource;
		this.Connector = Connector;
		this.settings = settings;
	}
}

// /**
//   * Field Mapping Argument
//   * @param Mode Mapping Mode (Index, Name)
//   * @param Entries Mapping Entries
//   */
//  case class FieldMapping(Mode: String, Entries:Seq[FieldMappingEntry])
//  /**
//    * Data Source Mapping Entry
//    * @param SourceName Extract Column Name
//    * @param TargetName Data Source Column Name
//    * @param SourceIndex Extract Column Index
//    * @param TargetIndex Data Source Column Index
//    */

export class FieldMapping {
	Mode: string;
	Entries: FieldMappingEntry[];
}

export class FieldMappingEntry {
	SourceName: string;
	TargetName: string;
	SourceIndex: number;
	TargetIndex: number;
}

// Data Market Connector

// export class DataMarketAccess {
//     ServiceID: number;
//     Table: number;
//     Query: string;
//     IsPreview: boolean;
//     PreviewRows: number;
// }

// export class DataMarketMetaAccess {
// }

// export class DataMarketMetaInfo {
//     Services: DataMarketTableInfo[]
// }

// case class DataMarketServiceInfo(ID:String)
export class DataMarketServiceInfo {
	ID: string;
}

export class DataMarketTableInfo {
	source_id: number;
	table_id: number;
	category: string;
	name: string;
}

// Data Package Protocol

// export class DataPackageProtocol {
// 	startTime: number;
// 	endTime?: number;
// 	user: string;
// 	loadScheduleJob?: number;
// 	dataSource: number;
// 	psa: string;
// 	writeMode: string;
// 	loadSuccessful: boolean;
// 	recordCount?: number;
// 	comment?: string;
// 	dataPackage?: number;
// }

// export class DataPackageProtocol {
// 	startTime: string;
// 	endTime?: string;
// 	user: string;
// 	writeMode?: string;
// 	loadSuccessful: boolean;
// 	recordCount?: number;
// 	comment?: string;
// 	dataPackage?: number;
// 	loadMode: string;
// 	psa: number;
// 	dataSource?: number;
// 	constructor(startTime: string, user: string, loadSuccessful: boolean, loadMode: string, psa: number, endTime?: string, writeMode?: string, recordCount?: number, comment?: string, dataPackage?: number, dataSource?: number) {
// 		if(startTime === undefined) throw new Error( "Class 'DataPackageProtocol': Required value 'startTime' is undefined" );
// 		if(user === undefined) throw new Error( "Class 'DataPackageProtocol': Required value 'user' is undefined" );
// 		if(loadSuccessful === undefined) throw new Error( "Class 'DataPackageProtocol': Required value 'loadSuccessful' is undefined" );
// 		if(loadMode === undefined) throw new Error( "Class 'DataPackageProtocol': Required value 'loadMode' is undefined" );
// 		if(psa === undefined) throw new Error( "Class 'DataPackageProtocol': Required value 'psa' is undefined" );
// 		this.startTime = startTime;
// 		this.endTime = endTime;
// 		this.user = user;
// 		this.writeMode = writeMode;
// 		this.loadSuccessful = loadSuccessful;
// 		this.recordCount = recordCount;
// 		this.comment = comment;
// 		this.dataPackage = dataPackage;
// 		this.loadMode = loadMode;
// 		this.psa = psa;
// 		this.dataSource = dataSource;
// 	}
// }

export class DataPackageProtocolEntry {
	id: number;
	action: string;
	objectType: string;
	objectId: string;
	attribute?: string;
	oldValue?: string;
	newValue?: string;
	user: string;
	userId: number;
	start: string;
	end: string;
	writeMode?: string;
	loadSuccessful: boolean;
	recordCount?: number;
	comment?: string;
	dataPackage?: number;
	psa: number;
	dataSource?: number;
	constructor(id: number, action: string, objectType: string, objectId: string, user: string, userId: number, start: string, end: string, loadSuccessful: boolean, psa: number, attribute?: string, oldValue?: string, newValue?: string, writeMode?: string, recordCount?: number, comment?: string, dataPackage?: number, dataSource?: number) {
		if(id === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'id' is undefined" );
		if(action === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'action' is undefined" );
		if(objectType === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'objectType' is undefined" );
		if(objectId === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'objectId' is undefined" );
		if(user === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'user' is undefined" );
		if(userId === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'userId' is undefined" );
		if(start === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'start' is undefined" );
		if(end === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'end' is undefined" );
		if(loadSuccessful === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'loadSuccessful' is undefined" );
		if(psa === undefined) throw new Error( "Class 'DataPackageProtocolEntry': Required value 'psa' is undefined" );
		this.id = id;
		this.action = action;
		this.objectType = objectType;
		this.objectId = objectId;
		this.attribute = attribute;
		this.oldValue = oldValue;
		this.newValue = newValue;
		this.user = user;
		this.userId = userId;
		this.start = start;
		this.end = end;
		this.writeMode = writeMode;
		this.loadSuccessful = loadSuccessful;
		this.recordCount = recordCount;
		this.comment = comment;
		this.dataPackage = dataPackage;
		this.psa = psa;
		this.dataSource = dataSource;
	}
}

export class PersistentStagingArea {
	name: string;
	writeMode: string;
	dataSource: number;
}
export class DataPackage {
	id: number;
	dataSource: number;
	loadTime: string;
	description?: string;
}

export class PsaInfoFieldsRow {
	psa: string;
	name: string;
	role: string;
}

export class PsaDsField {
	psa: string;
	name: string;
	dataSourceField: number;
}

export class DataTypeInfo {
	Name: string;
	Length: number;
	Format: string;
	FixedSize: boolean;
	Precision: number;
}

export class DataSourceFieldsMapping {
	dataSource: number;
	mode: string;
}

export class DataSourceFieldsMappingEntry {
	dataSource: number;
	sourceName: string;
	targetName: string;
	sourceIndex: number;
	targetIndex: number;
}

export class DataSourceAccessible implements Accessible{
	ID: number;
	constructor(ID: number) {
		if(ID === undefined) throw new Error( "Class 'DataSourceAccessible': Required value 'ID' is undefined" );
		this.ID = ID;
	}
	AccessibleType: string = "";
	Value: string = "";
}

export class DataSourcePermission implements PermissionBase<DataSourceAccessible> {
	ID: number;
	Role: number;
	IsOwner: boolean;
	Accessible: DataSourceAccessible;
	Rights: string[];
	constructor(ID: number, Role: number, IsOwner: boolean, Accessible: DataSourceAccessible, Rights: string[]) {
		if(ID === undefined) throw new Error( "Class 'DataSourcePermission': Required value 'ID' is undefined" );
		if(Role === undefined) throw new Error( "Class 'DataSourcePermission': Required value 'Role' is undefined" );
		if(IsOwner === undefined) throw new Error( "Class 'DataSourcePermission': Required value 'IsOwner' is undefined" );
		if(Accessible === undefined) throw new Error( "Class 'DataSourcePermission': Required value 'Accessible' is undefined" );
		if(Rights === undefined) throw new Error( "Class 'DataSourcePermission': Required value 'Rights' is undefined" );
		this.ID = ID;
		this.Role = Role;
		this.IsOwner = IsOwner;
		this.Accessible = Accessible;
		this.Rights = Rights;
	}
}

export class PlayDataSourcePermission
	implements PlayPermission<DataSourceAccessible>
{
	ID: number;
	RightHolder: RightHolder;
	PermissionLevel: string;
	IsOwner: boolean;
	Accessible: DataSourceAccessible;
	Rights: string[];
	constructor(
		ID: number,
		RightHolder: RightHolder,
		PermissionLevel: string,
		IsOwner: boolean,
		Accessible: DataSourceAccessible,
		Rights: string[]
	) {
		if (ID === undefined)
			throw new Error(
				"Class 'PlayDataSourcePermission': Required value 'ID' is undefined"
			);
		if (RightHolder === undefined)
			throw new Error(
				"Class 'PlayDataSourcePermission': Required value 'RightHolder' is undefined"
			);
		if (PermissionLevel === undefined)
			throw new Error(
				"Class 'PlayDataSourcePermission': Required value 'PermissionLevel' is undefined"
			);
		if (IsOwner === undefined)
			throw new Error(
				"Class 'PlayDataSourcePermission': Required value 'IsOwner' is undefined"
			);
		if (Accessible === undefined)
			throw new Error(
				"Class 'PlayDataSourcePermission': Required value 'Accessible' is undefined"
			);
		if (Rights === undefined)
			throw new Error(
				"Class 'PlayDataSourcePermission': Required value 'Rights' is undefined"
			);
		this.ID = ID;
		this.RightHolder = RightHolder;
		this.PermissionLevel = PermissionLevel;
		this.IsOwner = IsOwner;
		this.Accessible = Accessible;
		this.Rights = Rights;
	}
}


export class ObjectProtocolRow {
	id: number;
	action: string;
	objectType: string;
	objectId: string;
	attribute?: string;
	oldValue?: string;
	newValue?: string;
	user: string;
	userId: number;
	start: string;
	end: string;
	constructor(id: number, action: string, objectType: string, objectId: string, user: string, userId: number, start: string, end: string, attribute?: string, oldValue?: string, newValue?: string) {
		if(id === undefined) throw new Error( "Class 'ObjectProtocol': Required value 'id' is undefined" );
		if(action === undefined) throw new Error( "Class 'ObjectProtocol': Required value 'action' is undefined" );
		if(objectType === undefined) throw new Error( "Class 'ObjectProtocol': Required value 'objectType' is undefined" );
		if(objectId === undefined) throw new Error( "Class 'ObjectProtocol': Required value 'objectId' is undefined" );
		if(user === undefined) throw new Error( "Class 'ObjectProtocol': Required value 'user' is undefined" );
		if(userId === undefined) throw new Error( "Class 'ObjectProtocol': Required value 'userId' is undefined" );
		if(start === undefined) throw new Error( "Class 'ObjectProtocol': Required value 'start' is undefined" );
		if(end === undefined) throw new Error( "Class 'ObjectProtocol': Required value 'end' is undefined" );
		this.id = id;
		this.action = action;
		this.objectType = objectType;
		this.objectId = objectId;
		this.attribute = attribute;
		this.oldValue = oldValue;
		this.newValue = newValue;
		this.user = user;
		this.userId = userId;
		this.start = start;
		this.end = end;
	}
}

export class DataSourceProtocolEntry {
	id: number;
	action: string;
	objectType: string;
	objectId: string;
	attribute?: string;
	oldValue?: string;
	newValue?: string;
	user: string;
	userId: number;
	start: string;
	end: string;
	dataSource: number;
	constructor(id: number, action: string, objectType: string, objectId: string, user: string, userId: number, start: string, end: string, dataSource: number, attribute?: string, oldValue?: string, newValue?: string) {
		if(id === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'id' is undefined" );
		if(action === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'action' is undefined" );
		if(objectType === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'objectType' is undefined" );
		if(objectId === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'objectId' is undefined" );
		if(user === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'user' is undefined" );
		if(userId === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'userId' is undefined" );
		if(start === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'start' is undefined" );
		if(end === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'end' is undefined" );
		if(dataSource === undefined) throw new Error( "Class 'DataSourceProtocolEntry': Required value 'dataSource' is undefined" );
		this.id = id;
		this.action = action;
		this.objectType = objectType;
		this.objectId = objectId;
		this.attribute = attribute;
		this.oldValue = oldValue;
		this.newValue = newValue;
		this.user = user;
		this.userId = userId;
		this.start = start;
		this.end = end;
		this.dataSource = dataSource;
	}
}