import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { DataSource } from 'src/app/models/datasource.model';
import { DataStore, DataStoreField } from 'src/app/models/datastore.model';
import { ScheduleActionPlan } from 'src/app/models/schedule.model';
import { UserDetailsRow } from 'src/app/models/user.model';
// import { WorkflowRepositoryEntry } from 'src/app/models/workflow.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { CubesService } from 'src/app/services/cubes.service';
import { DestinationService } from 'src/app/services/destinationService';
import { DummyDestinationService } from 'src/app/services/dummy-destination.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { SubSink } from 'subsink';
import { WorkflowGraphComponent } from '../../designer/components/workflow-graph/workflow-graph.component';
import { GeneralObjectViewComponent } from '../../objectManagement/general-object-view/general-object-view.component';
import { DestinationRecord, DestinationRecordView, DestinationViewRecord } from '../../objectManagement/general-object-view/provider-data-store';
import { GeneralSearchComponent, DestinationSearch } from '../../objectManagement/general-search/general-search.component';
import { GenericBottomToolbarComponent } from '../../objectManagement/generic-bottom-toolbar/generic-bottom-toolbar.component';
import { DataStoreToolbarResult, DestinationBottomToolbar, DestinationRichBottomToolbar } from '../../objectManagement/generic-bottom-toolbar/provider-data-store';
import { DestinationFieldInfoProvider, DestinationRichFieldInfoProvider } from '../../objectManagement/generic-field-infos/field-info-data-store';
import { GenericFieldInfosComponent } from '../../objectManagement/generic-field-infos/generic-field-infos.component';
import { GenericLatestActivitiesComponent } from '../../objectManagement/generic-latest-activities/generic-latest-activities.component';
import { DestinationActivity, DestinationRichActivity } from '../../objectManagement/generic-latest-activities/provider-data-store';
import { GenericTopToolbarComponent } from '../../objectManagement/generic-top-toolbar/generic-top-toolbar.component';
import { GenericWidgetsComponent } from '../../objectManagement/generic-widgets/generic-widgets.component';
import { ExperimentalApi } from 'src/app/services/experimental-api.service';
import { DestinationToolbar } from '../../objectManagement/generic-top-toolbar/provider-data-store';
import { GenericObjectTasksComponent } from '../../objectManagement/generic-object-tasks/generic-object-tasks.component';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
  selector: 'app-destinations-view',
  templateUrl: './destinations-view.component.html',
  styleUrls: ['./destinations-view.component.scss'],
  providers: [MessageService],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),]

})
export class DestinationsViewRelayoutComponent implements OnInit {
  // Gui Elements
  //navMenuItem: MenuItem[] = [];
  private subs = new SubSink();
  loading: boolean = false;
  displaySearchPanel: boolean = true;
  displayWidgets: boolean = true;

  // WF specific classes
  destinations: DataStore[] = [];
  fieldInfos: DataStoreField[] = [];
  displayPsa: boolean = false;
  selectedDestination?: DataStore;

  userDetails: UserDetailsRow[] = [];

  // WF specific TypeClass
  destinationToolbarTypeClass: DestinationToolbar = new DestinationToolbar(this.dataStoreService, this.userService, this.datasourcesService, this.destService);
  destinationRecordTypeClass: DestinationRecord = new DestinationRecord(this.bionApi, this.userService, this.dataStoreService, this.objectSearchService, this.systemLogService);
  destinationSearchTypeClass: DestinationSearch = new DestinationSearch(this.bionApi, this.userService, this.utilService, this.dataStoreService, this.objectSearchService);
  destinationActivityTypeClass = new DestinationActivity(this.bionApi, this.dataStoreService, this.userDetails, this.utilService);
  destinationBottomToolbarTypeClass = new DestinationBottomToolbar(this.bionApi, this.dataStoreService);
  destinationFieldInfoTypeClass = new DestinationFieldInfoProvider(this.bionApi, this.dataStoreService);


  // -- NEW TYPE CLASSES - Using rich API
  // -- object, field infos, activities, bottom
  // DestinationViewRecord
  destinationViewRecordTypeClass: DestinationViewRecord = new DestinationViewRecord(this.bionApi, this.userService, this.dataStoreService, this.objectSearchService, this.systemLogService, [],[], this.utilService);
  destinationViewFieldInfoTypeClass = new DestinationRichFieldInfoProvider([], [], this.dataStoreService);
  destinationViewActivityTypeClass = new DestinationRichActivity(this.dataStoreService, [],this.userDetails, this.utilService);
  destinationViewBottomToolbarTypeClass = new DestinationRichBottomToolbar(this.bionApi, this.dataStoreService, []);


  @ViewChild("destinationObjectToolbar") destinationObjectToolbar!: GenericTopToolbarComponent<DataStore>;
  @ViewChild("destinationObjectView") destinationObjectView!: GeneralObjectViewComponent<DataStore, DestinationRecordView, any, any>;
  @ViewChild("destinationObjectSearch") destinationObjectSearch!: GeneralSearchComponent<[DataStore[], DataStoreField[], UserDetailsRow[], ScheduleActionPlan[], WorkflowRepositoryEntry[], DataSource[]], any>;
  @ViewChild("destinationObjectWidgets") destinationObjectWidgets!: GenericWidgetsComponent<DataStore>;
  //@ViewChild('calendarObjectWidget') calendarObjectWidget: GenericCalendarWidgetComponent<DataStore>;
  @ViewChild('destinationLatestActivities') destinationLatestActivities!: GenericLatestActivitiesComponent<DataStore, any>;
  //@ViewChild('destinationBottomToolbar') destinationBottomToolbar!: GenericBottomToolbarComponent<DataStore, DataStore, DataStoreToolbarResult, any>;
  @ViewChild('destinationFieldInfos') destinationFieldInfos!: GenericFieldInfosComponent<DataStore, DataStoreField>;
  //@ViewChild('destinationCreateObject') destinationCreateObject: CreateDestinationDialogComponent<DataStore, Destination<any>>;
  @ViewChild('destinationObjectTasks') destinationObjectTasks!: GenericObjectTasksComponent;


  @ViewChild("workflowGraph") graph!: WorkflowGraphComponent;

  constructor(
    private bionApi: ApiBackendService,
    public appMain: AppMainComponent,
    private datasourcesService: DatasourcesService,
    private dummyService: DummyDestinationService,
    private dataStoreService: CubesService,
    private schedulesService: SchedulesService,
    private systemLogService: SystemMessageLogService,
    private userService: UserService,
    private destService: DestinationService,
    private objectSearchService: ObjectSearchService,
    private utilService: UtilFunctionsService,
    public translate: TranslateService,
    public experimentalApi: ExperimentalApi,
    public router: Router
  ) { }

  ngOnInit(): void {

    const use_new_approach = true;

    if (use_new_approach) {

      this.subs.sink = this.initViewNew().subscribe(() => {
        this.loading = false;
      }, err => {
        this.systemLogService.handleError(err);
      });

      this.subs.sink = this.dataStoreService.selectedDataStoreEmitter.subscribe(
        (wf: DataStore) => {
          this.selectedDestination = wf;
                      
          if(this.selectedDestination === undefined) {
            this.appMain.onRightPanelClick(false);

        } else {
            this.appMain.onRightPanelClick(true);

        }
        }
      );


      // ACHTUNG: Dieses Event wird auch von den entsprechenden Typ-Klassen aboniert, daher
      // => Teste, ob dieser block hier unten überhaupt noch gebraucht wird.

      this.subs.sink = this.dataStoreService.dataStoresChangedEmitter.subscribe(() => {
        this.subs.sink = this.initViewNew(this.selectedDestination).subscribe(() => {
          this.loading = false;
        }, err => {
          this.systemLogService.handleError(err);
        });
      },
        (err) => {
          this.systemLogService.handleError(err);
        });

    } else {

      this.subs.sink = this.initView().subscribe(() => {
        this.loading = false;
      }, err => {
        this.systemLogService.handleError(err);
      });

      this.subs.sink = this.dataStoreService.selectedDataStoreEmitter.subscribe(
        (wf: DataStore) => {
          this.selectedDestination = wf;
        }
      );

      this.subs.sink = this.dataStoreService.dataStoresChangedEmitter.subscribe(() => {
        this.subs.sink = this.initView(this.selectedDestination).subscribe(() => {
          this.loading = false;
        }, err => {
          this.systemLogService.handleError(err);
        });
      },
        (err) => {
          this.systemLogService.handleError(err);
        });

    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Diese Version von InitView überschreibt die Typklassen für die neue Rich API.
   * @param selected Possible selected Data Store
   * @returns
   */
  initViewNew(selected?: DataStore): Observable<boolean> {
    this.loading = true;

    const userDetailsObs = this.userService.getUserDetailsRow();
    const destObs = this.experimentalApi.getDataStoreView();
    const dTypesObs = this.bionApi.getDataTypes();

    const obs = forkJoin(destObs,userDetailsObs,dTypesObs);

    const finalObs = obs.pipe(concatMap(rich_data => {
        const data_stores = rich_data[0].map(d => d.DataStore);
        console.log(rich_data);
        this.userDetails = rich_data[1];

        // top, search, object, fields, activity, bottom

        const top_obs = this.destinationObjectToolbar.setToolbar();
        const search_obs = this.destinationObjectSearch.setSearchModel(this.destinationSearchTypeClass, this.destinations);

        this.destinationViewRecordTypeClass = new DestinationViewRecord(this.bionApi, this.userService, this.dataStoreService, this.objectSearchService, this.systemLogService, rich_data[0],this.userDetails, this.utilService);
        const object_obs = this.destinationObjectView.setObjects(data_stores, this.destinationViewRecordTypeClass)

        this.destinationViewFieldInfoTypeClass = new DestinationRichFieldInfoProvider(rich_data[0],rich_data[2], this.dataStoreService);
        const fields_obs = this.destinationFieldInfos.setObject(this.destinationViewFieldInfoTypeClass);

        this.destinationViewActivityTypeClass = new DestinationRichActivity(this.dataStoreService, rich_data[0],this.userDetails, this.utilService);
        const activity_obs = this.destinationLatestActivities.setObject(this.destinationViewActivityTypeClass);

        this.destinationViewBottomToolbarTypeClass = new DestinationRichBottomToolbar(this.bionApi, this.dataStoreService, rich_data[0]);
        const bot_obs = this.destinationObjectTasks.setObject();

        return forkJoin(new Array(top_obs, search_obs, object_obs, fields_obs, activity_obs, bot_obs));
    }))
    return finalObs.pipe(map(() => true));

    // const obs = this.experimentalApi.getDataStoreView().pipe(concatMap(rich_data => {

    //   const data_stores = rich_data.map(d => d.DataStore);
    //   console.log(rich_data);

    //   // top, search, object, fields, activity, bottom

    //   const top_obs = this.destinationObjectToolbar.setToolbar();
    //   const search_obs = this.destinationObjectSearch.setSearchModel(this.destinationSearchTypeClass, this.destinations);

    //   this.destinationViewRecordTypeClass = new DestinationViewRecord(this.bionApi, this.userService, this.dataStoreService, this.objectSearchService, this.systemLogService, rich_data);
    //   const object_obs = this.destinationObjectView.setObjects(data_stores, this.destinationViewRecordTypeClass)

    //   this.destinationViewFieldInfoTypeClass = new DestinationRichFieldInfoProvider(rich_data, this.dataStoreService);
    //   const fields_obs = this.destinationFieldInfos.setObject(this.destinationViewFieldInfoTypeClass);

    //   this.destinationViewActivityTypeClass = new DestinationRichActivity(this.dataStoreService, rich_data);
    //   const activity_obs = this.destinationLatestActivities.setObject(this.destinationViewActivityTypeClass);

    //   this.destinationViewBottomToolbarTypeClass = new DestinationRichBottomToolbar(this.bionApi, this.dataStoreService, rich_data);
    //   const bot_obs = this.destinationObjectTasks.setObject();

    //   return forkJoin(new Array(top_obs, search_obs, object_obs, fields_obs, activity_obs, bot_obs));
    // }));

    // return obs.pipe(map(() => true));
  }

  initView(wf?: DataStore): Observable<[boolean, boolean, boolean, boolean, boolean, boolean]> {
    this.loading = true;
    const dsObs = this.dataStoreService.getDataStoreObjectList();
    const dsFieldsObs = this.dataStoreService.getDataStoreField();

    let destResultObs = forkJoin(dsObs, dsFieldsObs).pipe(concatMap((dsResults) => {
      this.destinations = dsResults[0];
      this.fieldInfos = dsResults[1];

      const toolBarObs = this.destinationObjectToolbar.setToolbar();
      const destObs = this.destinationObjectView.setObjects(this.destinations, this.destinationRecordTypeClass);
      //console.log(this.destinationObjectView.records);
      const searchObs = this.destinationObjectSearch.setSearchModel(this.destinationSearchTypeClass, this.destinations);
      //let calendarObs = this.calendarObjectWidget.setObjects(this.calendarTypeClass, this.destinations);
      const activitiesObs = this.destinationLatestActivities.setObject(this.destinationActivityTypeClass);
      const bottomBarObs = this.destinationObjectTasks.setObject();
      const fieldsObs = this.destinationFieldInfos.setObject(this.destinationFieldInfoTypeClass);
      //let createDestObs = this.destinationCreateObject.setObject(this.destinationCreateTypeClass);

      let initCompObs = forkJoin(toolBarObs, destObs, searchObs, activitiesObs, bottomBarObs, fieldsObs)

      return initCompObs
    }))

    return destResultObs

  }
  
  onSendRequest(sender: string) {

    let subject: any;
    let bodyText: any;

    if(sender === "Api") {
        subject = this.translate.instant("I want to request a API");
        bodyText = this.translate.instant("Hi Bion support, i want to request a API. Here is the description:");
    }
    if(sender === "Filetype") {
        subject = this.translate.instant("I want to request a file type");
        bodyText = this.translate.instant("Hi Bion support, i want to request a new file type. Here is the description:"); 
    }

const mailText = "mailto:support@bion-analytics.com?subject="+subject+"&body="+bodyText; // add the links to body
window.location.href = mailText;
}

  toggleWidgetMenu() {
    this.displayWidgets = !this.displayWidgets;
  }
  toggleSearchMenu() {
    this.displaySearchPanel = !this.displaySearchPanel;
  }

}
