import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api/menuitem";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { UserService } from "src/app/services/user.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { ToolbarProvider } from "./toolbar-providers";
import { WorkflowActionEvent, WorkflowDialogActionType } from "src/app/models/dialog-actions.model";

export class WorkflowToolbar implements ToolbarProvider<WorkflowRepositoryEntry> {
    constructor(private workflowsService: WorkflowsService, private userService: UserService, private router: Router) { }
    subscribeToEmitters() : EventEmitter<WorkflowRepositoryEntry> {
        let selectedObs = this.workflowsService.selectedWorkflowEmitter;

        return selectedObs
    }

    initObjectMenu(): MenuItem[] {
        return [

            {
                icon: "pi pi-fw pi-download",
                styleClass: "p-button p-button-text p-mr-2 p-mb-2",
                tooltip: "Export workflows as JSON file",
                command: () => {
                    this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Export workflows",WorkflowDialogActionType.exportWorkflows,"Export"));
                    //this.workflowsService.displayCreateWorkflow.emit(true);
                }
            },
            {
                icon: "pi pi-fw pi-upload",
                styleClass: "p-button p-button-text p-mr-2 p-mb-2",
                tooltip: "Import workflows from JSON file",
                command: () => {
                    this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Import workflows",WorkflowDialogActionType.importWorkflows,"Import"));
                    //this.workflowsService.displayCreateWorkflow.emit(true);
                }
            },
            {
                label: "New",
                icon: "pi pi-fw pi-plus",
                styleClass: "p-button p-button-success p-mr-2 p-mb-2",
                tooltip: "Create new workflow",
                command: () => {
                    this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Create workflow",WorkflowDialogActionType.createWorkflow,"Create"));
                    //this.workflowsService.displayCreateWorkflow.emit(true);
                }
            },
        ]
    }
    initActionMenu(): MenuItem[] {
        return [
            // {
            //     label: "OpenDesigner",
            //     icon: "pi pi-fw pi-arrow-right",
            //     command: () => { this.routeToUrl('/designer') }
            // },
        ]
    }
    routeToUrl(url: string) {
        this.router.navigateByUrl(url);
      }
}


