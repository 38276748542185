<p-toast position="top-center" baseZIndex="99999999999"
    [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>






    <div *ngIf="extensionUrl">
        <div style="margin:20px 0">
            <h5>
                {{'API' | translate }}<app-info-tooltip></app-info-tooltip>
            </h5>
        </div>
        <div class="card">
            <div class="card-header">
                <div class="card-title">
                    <h5>{{'ODataEndpoint' | translate}}</h5>
                </div>
                <!-- <p class="subtitle"> <button style="float: right" pButton pRipple type="button" icon="pi pi-plus" label="Create"
                        class="p-button-rounded p-mr-2 p-mb-2" pTooltip="Create new cube"
                        (click)="displayNewDataStoreDialog(true)"></button></p> -->
            </div>
            <p class="subtitle">
                {{'ODataDescription'|translate}}
            </p>
            <div class="card-content p-formgrid">
                    <div class="p-inputgroup" style="margin-top:30px">
                        <span class="p-float-label">

                                <input [disabled]=true  type="text" id="finalUrlInputgroup" pInputText [(ngModel)]="finalUrl">
                                <button type="button" icon="pi pi-copy" pButton pRipple  label="{{'Copy' | translate}}" tooltipPosition="bottom" pTooltip="{{'CopyUrlToClipboard' | translate}}" (click)="textToClipboard(finalUrl)"></button>

                            <label for="finalUrlInputgroup">Full Url</label>
                        </span>
                    </div>
            </div>
            <p-divider></p-divider>
            <div class="card-footer">
                <p style="font-weight: bold">{{'SupportedByTools'|translate}}</p> 
                <span>
                    <p-avatarGroup styleClass="mb-3">
                        <p-avatar *ngFor="let sup of oDataSupports" image="assets/layout/images/connectors/{{sup.label}}.png" size="large" shape="circle"></p-avatar>
                        <!-- <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png" size="large" shape="circle"></p-avatar> -->
                    </p-avatarGroup>
                </span>

            </div>


        </div>


    </div>
    <div *ngIf="!extensionUrl">
        <div style="margin:20px 0">
            <h5>
                {{'Download' | translate }} <app-info-tooltip></app-info-tooltip>
            </h5>
        </div>
        <div class="card">
            <p-table *ngIf="currentSelectedDataStore || currentSelectedDataSource" #dtc [value]="exportFormatInfos" selectionMode="single" styleClass="p-datatable-customers p-datatable-sm"
            [filterDelay]="0"
           [globalFilterFields]="['action','objectType','objectId','user','end']">
           <ng-template pTemplate="header" let-activity>
               <tr>
                   <th  pSortableColumn="end" style="width: 30px;">
   
                   </th>
                   <th  pSortableColumn="end" style="width: 80%;">
                       <div class="p-d-flex p-jc-between p-ai-center">
                           {{'Filetype' | translate}}
                           <p-sortIcon field="end"></p-sortIcon>
                       </div>
                   </th>
                   <th style="width: 4rem"></th>
               </tr>
           </ng-template>
           <ng-template pTemplate="body" let-format>
               <tr>
                   <td style="width: 30px;">
                       <img src="assets/layout/images/connectors/{{format.Name}} Adapter.png" width="30"
                       height="30" alt="freya-layout" />
                   </td>
                   <td style="width: 80%;">
                       <span class="p-column-title">{{'Exporttype' | translate}}</span>
                       <div style="display:flex;align-items: center;">
                           {{format.Name}}
                       </div>
                   </td>
   
                   <td style="width: 4rem">
                       <div class="ui-table-buttongroup-right">
                           <button pButton pRipple [disabled]="isDownloading" [icon]="isDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-save'" label="{{'Download' | translate}}"
                           class=" p-button p-mr-2 p-mb-2" pTooltip="{{'DownladAsFile' | translate}}"
                           tooltipPosition="bottom" (click)="onDownloadFile(format)"></button>
                       </div>
                   </td>
   
   
               </tr>
           </ng-template>
           <ng-template pTemplate="emptymessage">
               <tr>
                   <td colspan="4">{{'NoEntryFound' | translate}}</td>
               </tr>
           </ng-template>
       </p-table>
        </div>


        <!-- <div class="device-status">
            <div >
                <div class="card-header">
                    <div class="card-title" *ngIf="!(currentSelectedDataStore || currentSelectedDataSource)">
                        <h6>{{'Select an entry first' | translate}}</h6>
                    </div>
                    <div class="card-title" *ngIf="currentSelectedDataStore || currentSelectedDataSource">
                        <h6>{{'Choose a file type' | translate}}</h6>
                    </div>
                </div>
                <div class="card-content content">
                    <div class="rightpanel-section chat">
                        <p-tabView [(activeIndex)]="index" (onChange)="onSelectFormat($event)">
                            <p-tabPanel [disabled]="!(currentSelectedDataStore || currentSelectedDataSource)" *ngFor="let format of exportFormatInfos; let i=index" [selected]="i == 0">
                                <ng-template pTemplate="header">
                                    <img src="assets/layout/images/connectors/{{format.Name}} Adapter.png" width="50"
                                        height="50" alt="freya-layout" />
                                    <span class="p-badge">{{format.Name}}</span>
                                </ng-template>
                            </p-tabPanel>

                        </p-tabView>
                    </div>


                </div>
                <div class="card-footer" *ngIf="currentSelectedDataStore || currentSelectedDataSource">
                    <button pButton pRipple type="button" [disabled]="isDownloading" [icon]="isDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-save'" label="{{'Download' | translate}}"
                        class=" p-button p-mr-2 p-mb-2" pTooltip="{{'DownladAsFile' | translate}}"
                        tooltipPosition="bottom" (click)="onDownloadFile(selectedExportFormatInfo)"></button>
                </div>
            </div>
        </div> -->
    </div>

    <!-- <div class="p-col-6">
        <div class="device-status">
            <div class="card item p-shadow-2">
                <div class="card-header">
                    <div class="card-title">
                        <h6>{{'SendMail' | translate}}</h6>
                    </div>
                </div>
                <div class="card-content p-formgrid content">
                    <div class="field col-12 md:col-4" style="margin-top: 15px;">
                        <span class="p-float-label">
                            <p-autoComplete inputId="autocomplete" [style]="{width: '100%'}" [multiple]="true" [(ngModel)]="emails" [suggestions]="filteredUsers" (completeMethod)="filterUser($event)" field="Username" [dropdown]="true">
                                <ng-template let-user pTemplate="item">
                                    <div class="country-item">
                                        <div><span style="font-weight: bold">{{user.Username}} </span>({{user.EMail}})</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                            <label for="autocomplete">{{'Recipients' | translate}}</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-4" style="margin-top: 20px;">
                        <span class="p-float-label">
                            <textarea inputId="textarea" [rows]="3" [cols]="30" [style]="{width: '100%'}" [disabled]=false pInputTextarea [(ngModel)]="emailText"></textarea>
                            <label for="textarea">{{'Message' | translate}}</label>
                        </span>
                    </div>

                    <div>
                        <div style="float: left;margin-top: 10px;margin-bottom: 10px;margin-right: 10%"><p-inputSwitch [(ngModel)]="includeUrl"></p-inputSwitch>{{'IncludeEndpointUrl' | translate}}</div>
                        <div *ngIf="currentSelectedDataSource || currentSelectedDataStore" style="float: left;margin-top: 10px;margin-bottom: 10px"><p-inputSwitch [(ngModel)]="includeFile"></p-inputSwitch>{{'IncludeFile' | translate}}</div>
                    </div>
                </div>
                <div class="card-footer footer">
                    <button pButton pRipple type="button" icon="pi pi-file-o" label="{{'Send' | translate}}"  tooltipPosition="bottom"
                            class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="{{'SendViaMail' | translate}}"
                            (click)="sendMail()"></button>
                </div>
            </div>
        </div>
    </div> -->



